import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
import config from '../../config';

import css from './EditListingLocationPanel.css';
import { getTimeZoneFromGoogle } from '../../util/googleCalendar';

class EditListingLocationPanel extends Component {
  constructor (props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, title, description } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const parkingType = publicData && publicData.parkingType;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, city, state, unitNumber, suburb, streetNumber, postalCode } = location;

    return {
      title,
      description,
      city,
      postalCode,
      unitNumber,
      streetNumber,
      suburb,
      state,
      parkingType,
      location: locationFieldsPresent
        ? {
          search: address,
          selectedPlace: { address, streetNumber, origin: geolocation },
        }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      submitButtonId,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);

    const userName =
      this.props.currentUser && !!this.props.currentUser.id
        ? this.props.currentUser.attributes.profile.firstName
        : 'Hi';

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage
        id="EditListingLocationPanel.createListingTitle"
        values={{ firstName: userName }}
      />
    );
    const panelDescription = <FormattedMessage id="EditListingLocationPanel.titleDescription" />;

    return (
      <div className={classes}>
        <div className={css.titleSection}>
          <h1 className={css.title}>Location</h1>
          <p>{panelDescription}</p>
        </div>
        {/* <h1 className={css.title}>{panelTitle}</h1> */}
        <EditListingLocationForm
          className={css.form}
          initialValues={this.state.initialValues}
          onSubmit={async values => {
            const {
              title,
              description,
              city = '',
              unitNumber = '',
              streetNumber = '',
              location,
              postalCode,
              suburb,
              state = '',
              parkingType = '',
            } = values;
            console.log('values------->', values);
            const {
              selectedPlace: { address, origin, timezone },
            } = location;
            const googleTimeZone = timezone;
            const listingAvailabilityPlan = currentListing.attributes.availabilityPlan;
            console.log("GEO location data", origin);
            let updateValues = {
              title: title || config.custom.defaultTitle,
              description: description || config.custom.defaultDescription,
              geolocation: origin,
              publicData: {
                location: {
                  address,
                  city,
                  unitNumber,
                  streetNumber,
                  suburb,
                  postalCode,
                  state,
                  timezone: googleTimeZone,
                },
                parkingType,
                listingTimezone: googleTimeZone,
              },
            };
            if (listingAvailabilityPlan) {
              updateValues.availabilityPlan = {
                ...listingAvailabilityPlan,
                timezone: googleTimeZone,
              };
            }
            this.setState({
              initialValues: {
                city,
                postalCode,
                unitNumber,
                streetNumber,
                suburb,
                location: { search: address, selectedPlace: { address, streetNumber, origin } },
                state,
                parkingType,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          submitButtonId={submitButtonId}
        />
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
  submitButtonId: string,
};

export default EditListingLocationPanel;
