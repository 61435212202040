import React, { Component } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { locationRadiusBounds } from '../../util/googleMaps';
import {
  Button,
  KeywordFilter,
  ModalInMobile,
  PriceFilter,
  RangeSlider,
  SelectMultipleFilter,
  SelectSingleFilter,
  SortBy,
  ToggleSwitchFilter,
} from '../../components';
import { propTypes } from '../../util/types';
import BSearchFiltersMobileCss from './BSearchFiltersMobile.css';
import { SecondaryButton } from '../Button/Button';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const RADIX = 10;
// Distance for home delivery in meters
const LIMIT_DISTANCE = 5200;
class BSearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersOpenOnMobile: false,
      initialQueryParams: null,
      currentQueryParams: props.urlQueryParams,
      minCarAge:2000,maxCarAge:2030,minSeats:1,maxSeats:20
    };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleNumberOfPeople = this.handleNumberOfPeople.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
    this.initialNumberPeopleRangeValue = this.initialNumberPeopleRangeValue.bind(this);
    this.canApply = this.canApply.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.initialPriceRangeValue = this.initialPriceRangeValue.bind(this);
    this.initialDateRangeValue = this.initialDateRangeValue.bind(this);
    this.handlePriceFilterRange = this.handlePriceFilterRange.bind(this);
    this.handleToogle = this.handleToogle.bind(this);
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  canApply() {
    const { urlQueryParams } = this.props;
    return !!urlQueryParams.dates;
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, viewport } = this.props;
    let isMobileLayout;
    try {
      if (viewport) {
        isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
      }
    } catch (error) {
      console.log('Error', error);
    }

    if (!isMobileLayout) {
      history.push(
        createResourceLocatorString(
          'SearchPage',
          routeConfiguration(),
          {},
          this.state.currentQueryParams
        )
      );
    }
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history } = this.props;

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleToogle(urlParam, option) {
    const { urlQueryParams, history } = this.props;

    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleDateRange(urlParam, dateRange) {
    const { urlQueryParams, history } = this.props;
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? { ...urlQueryParams, [urlParam]: `${start},${end}` }
        : omit(urlQueryParams, urlParam);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handlePriceFilterRange(dateRange) {
    let diffHoursFlag = true;
    const hasDates = dateRange && dateRange.dates;
    const hasHours = dateRange && !isEmpty(dateRange.times);
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const { pickupTime, dropOffTime } = hasHours ? dateRange.times : {};
    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;
    let startDateTimeMaybe, endDateTimeMaybe, diffHours;
    if (hasDates && hasHours && start && end && pickupTime && dropOffTime) {
      startDateTimeMaybe = new Date(moment(`${start} ${pickupTime}`).format('YYYY-MM-DD hh:mm a'));

      endDateTimeMaybe = new Date(moment(`${end} ${dropOffTime}`).format('YYYY-MM-DD hh:mm a'));
    }
    if (startDateTimeMaybe && endDateTimeMaybe) {
      diffHours = moment(endDateTimeMaybe).diff(moment(startDateTimeMaybe), 'hours', true);
    }
    // if (diffHours && diffHours < 12) {
    //   diffHoursFlag = false;
    // }
    return diffHoursFlag;
  }

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history } = this.props;

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handlePrice(urlParam, range) {
    const { urlQueryParams, history } = this.props;
    const queryParams = range
      ? { ...urlQueryParams, [urlParam]: range }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleNumberOfPeople(urlParam, range) {
    const { urlQueryParams, history } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${maxPrice},` }
        : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleDateRange(urlParam, dateRange) {
    const { urlQueryParams, history } = this.props;
    const hasDates = dateRange && dateRange.dates;
    const hasHours = dateRange && !isEmpty(dateRange.times);
    const { pickupTime, dropOffTime } = hasHours ? dateRange.times : {};
    const { startDate, endDate } = hasDates ? dateRange.dates : {};
    const pickup = pickupTime ? moment(pickupTime, 'hh:mm a').format('HH:mm') : '10:00';
    const dropoff = dropOffTime ? moment(dropOffTime, 'hh:mm a').format('HH:mm') : '10:00';
    const diff = startDate && endDate && moment(endDate).diff(moment(startDate), 'days', true);
    const longTermRentalMaybe = diff && diff > 60 ? { pub_longTermRental: true } : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? {
            ...urlQueryParams,
            [urlParam]: `${start},${end}`,
            hours: `${pickup},${dropoff}`,
            ...longTermRentalMaybe,
          }
        : omit(urlQueryParams, [urlParam, 'hours']);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleKeyword(urlParam, keywords) {
    const { urlQueryParams, history } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [urlParam]: keywords }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleSortBy(urlParam, sort) {
    const { urlQueryParams, history } = this.props;
    const queryParams = urlParam
      ? { ...urlQueryParams, [urlParam]: sort }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterParamNames } = this.props;

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[paramName];
  }

  initialBooleanValue = paramName => {
    return !!this.props.urlQueryParams[paramName];
  };

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    return !!urlQueryParams[paramName] ? urlQueryParams[paramName].split(',') : [];
  }

  initialPriceRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
          price,
        }
      : null;
  }

  initialDateRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const dates = urlQueryParams[paramName];
    const rawValuesFromParams = !!dates ? dates.split(',') : [];
    const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    const initialValues =
      !!dates && valuesFromParams.length === 2
        ? {
            dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
          }
        : { dates: null };

    return initialValues;
  }

  initialValueToggleSwitch(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    const getQueryParam = queryParam => {
      return typeof queryParam === 'undefined' ? null : queryParam;
    };

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const hasCurrentQueryParam = typeof currentQueryParams[paramName] !== 'undefined';
    return hasCurrentQueryParam
      ? getQueryParam(urlQueryParams[paramName])
      : getQueryParam(urlQueryParams[paramName]);
  }

  handleToggleSwitch(urlParam, choice) {
    const { urlQueryParams, history, currentUser } = this.props;
    this.setState(prevState => {
      const prevQueryParams = prevState.currentQueryParams;
      const mergedQueryParams = { ...urlQueryParams , ...prevQueryParams};

      // query parameters after selecting options
      // if no option is passed, clear the selection from state.currentQueryParams
      const searchStep = window.innerWidth < MAX_MOBILE_SCREEN_WIDTH ? 'searchedResults' : null;
      const currentQueryParams = choice
        ? { ...mergedQueryParams, [urlParam]: choice, searchStep }
        : { ...mergedQueryParams, [urlParam]: null };

      if (urlParam === 'pub_delivery' && choice) {
        const userLocation = get(currentUser, 'attributes.profile.protectedData.location.selectedPlace.origin');
        const newBounds = userLocation && locationRadiusBounds(userLocation, LIMIT_DISTANCE)
        newBounds && (currentQueryParams.bounds = newBounds)
      }

      history.push(
        createResourceLocatorString('SearchPage', routeConfiguration(), {}, currentQueryParams)
      );
      return { currentQueryParams };
    });
  }

  initialNumberPeopleRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price
      ? [1].concat(price.split(',').map(v => Number.parseInt(v, RADIX))[0])
      : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
        }
      : null;
  }

  initialDateRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const dates = urlQueryParams[paramName];
    const hours = urlQueryParams['hours'];
    const rawHoursValues = hours ? hours.split(',') : [];
    const rawValuesFromParams = !!dates ? dates.split(',') : [];
    const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    let initialValues =
      !!dates && valuesFromParams.length === 2
        ? {
            dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
          }
        : { dates: null };
    if (hours && rawHoursValues.length === 2) {
      initialValues.times = {
        pickupTime: moment(rawHoursValues[0], 'HH:mm').format('hh:mm a'),
        dropOffTime: moment(rawHoursValues[1], 'HH:mm').format('hh:mm a'),
      };
    } else {
      initialValues.times = {
        pickupTime: '10:00 am',
        dropOffTime: '10:00 am',
      };
    }

    return initialValues;
  }

  render() {
    const {
      rootClassName,
      className,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      categoryFilter,
      reviewsFilter,
      brandNamesFilter,
      featuresFilter,
      transmissionsFilter,
      canDriveToMalaysiaFilter,
      typeOfFuelFilter,
      distanceFilter,
      priceFilter,
      numberPassengerFilter,
      dateRangeFilter,
      keywordFilter,
      intl,
      isPHVFilter,
      instantBookingFilter,
      drivelahGoFilter,
      superHostFilter,
      homeDeliveryFilter,
      newCarFilter,
      disinfectedFilter,
      sort,
      longTermFilter,
      isPetFriendlyFilter
    } = this.props;

    const initialDrivelagGoFilter = drivelahGoFilter
      ? this.initialValueToggleSwitch(drivelahGoFilter.paramName)
      : null;

    const initialSuperHostFilter = superHostFilter
      ? this.initialValueToggleSwitch(superHostFilter.paramName)
      : null;

    const initialHomeDeliveryFilter = homeDeliveryFilter
      ? this.initialValueToggleSwitch(homeDeliveryFilter.paramName)
      : null;

    const drivelahGoLabelHelperText = intl.formatMessage({
      id: 'SearchFilterMobile.drivelahGoLabelHelperText',
      });

    // const initialSuperHostFilter = superHostFilter
    //   ? this.initialValueToggleSwitch(superHostFilter.paramName)
    //   : null;
    // const superHostLabelHelperText = intl.formatMessage({
    //   id: 'SearchFilterMobile.superHostLabelHelperText',
    // });

    const homeDeliveryHelperText = intl.formatMessage({
      id: 'SearchFilterMobile.homeDeliveryLabelHelperText',
    });

    // const superHostFilterElement = superHostFilter ? (
    //   <ToggleSwitchFilter
    //     id={'SearchFilters.superHostFilter'}
    //     name="superHostFilter"
    //     urlParam={superHostFilter.paramName}
    //     label={intl.formatMessage({
    //       id: 'SearchFilters.superHostFilter',
    //     })}
    //     liveEdit
    //     useLiveEdit={true}
    //     onSubmit={this.handleToggleSwitch}
    //     initialValues={initialSuperHostFilter}
    //     clearButtonClass={BSearchFiltersMobileCss.clearbutton}
    //     intl={intl}
    //     hintText={intl.formatMessage({ id: 'SearchFilters.superHostFilter.hintText' })}
    //     labelClassName={classNames(
    //       BSearchFiltersMobileCss.labelClassName,
    //       BSearchFiltersMobileCss.labelClassNameWithLimit
    //     )}
    //     className={BSearchFiltersMobileCss.filterElement}
    //     plainClassName={BSearchFiltersMobileCss.plainClassName}
    //     customSliderClassName={BSearchFiltersMobileCss.customSlider}
    //     toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
    //     labelHelperText={superHostLabelHelperText}
    //   />
    // ) : null;

    const homeDeliveryFilterElement = homeDeliveryFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.homeDeliveryFilter'}
        name="homeDeliveryFilter"
        urlParam={homeDeliveryFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.homeDeliveryFilter',
        })}
        liveEdit
        useLiveEdit={true}
        onSubmit={this.handleToggleSwitch}
        initialValues={initialHomeDeliveryFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        hintText={intl.formatMessage({ id: 'SearchFilters.homeDeliveryFilter.hintText' })}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        className={BSearchFiltersMobileCss.filterElement}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
        labelHelperText={homeDeliveryHelperText}
      />
    ) : null;


    const drivelahGoFilterElement = drivelahGoFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.drivelahGoFilter'}
        name="drivelahGoFilter"
        urlParam={drivelahGoFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.drivelahGoFilter',
        })}
        liveEdit
        useLiveEdit={true}
        onSubmit={this.handleToggleSwitch}
        initialValues={initialDrivelagGoFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        hintText={intl.formatMessage({ id: 'SearchFilters.drivelahGoFilter.hintText' })}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        className={BSearchFiltersMobileCss.filterElement}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
        labelHelperText={drivelahGoLabelHelperText}
      />
    ) : null;

    const initialLongTermRental = longTermFilter
      ? this.initialValueToggleSwitch(longTermFilter.paramName)
      : null;

    const longTermFilterElement = longTermFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.longTermFilter'}
        name="longTermFilter"
        urlParam={longTermFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.longTermFilter',
        })}
        liveEdit
        useLiveEdit={true}
        onSubmit={this.handleToggleSwitch}
        initialValues={initialLongTermRental}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        hintText={intl.formatMessage({ id: 'SearchFilters.longTermFilter.hintText' })}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        className={BSearchFiltersMobileCss.filterElement}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
      />
    ) : null;

    const initialNewCarFilter = newCarFilter
      ? this.initialValueToggleSwitch(newCarFilter.paramName)
      : null;

    const newCarLabelHelperText = intl.formatMessage({
      id: 'SearchFilterMobile.newCarLabelHelperText',
    });

    const newCarFilterElement = newCarFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.newCarFilter'}
        name="newCarFilter"
        urlParam={newCarFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.isNewCar',
        })}
        liveEdit
        useLiveEdit={true}
        onSubmit={this.handleToggleSwitch}
        initialValues={initialNewCarFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        className={BSearchFiltersMobileCss.filterElement}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
        labelHelperText={newCarLabelHelperText}
      />
    ) : null;

    const initialDisinfectedFilter = disinfectedFilter
      ? this.initialValueToggleSwitch(disinfectedFilter.paramName)
      : null;

    const disinfectedLabelHelperText = intl.formatMessage({
      id: 'SearchFilter.disinfectedLabelHelperText',
    });
    const disinfectedFilterElement = disinfectedFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.disinfectedFilter'}
        name="disinfectedFilter"
        urlParam={disinfectedFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.disinfectedFilter',
        })}
        liveEdit
        useLiveEdit={true}
        onSubmit={this.handleToggleSwitch}
        initialValues={initialDisinfectedFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        className={BSearchFiltersMobileCss.filterElement}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
        labelHelperText={disinfectedLabelHelperText}
      />
    ) : null;

    const initialInstantBookingFilter = instantBookingFilter
      ? this.initialValueToggleSwitch(instantBookingFilter.paramName)
      : null;

    const instantBookingLabelHelperText = intl.formatMessage({
      id: 'SearchFilterMobile.instantBookingLabelHelperText',
    });

    const instantBookingFilterElement = instantBookingFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.instantBooking'}
        name="instantBooking"
        urlParam={instantBookingFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.instantBooking',
        })}
        onSubmit={this.handleToggleSwitch}
        liveEdit
        useLiveEdit={true}
        initialValues={initialInstantBookingFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        className={BSearchFiltersMobileCss.filterElement}
        contentPlacementOffSet={1000}
        intl={intl}
        labelHelperText={instantBookingLabelHelperText}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
      />
    ) : null;

    const canDriveToMalaysiaLabel = intl.formatMessage({
      id: 'SearchFilters.canDriveToMalaysiaLabel',
    });

    const initialCanDriveToMalaysiaFilter = canDriveToMalaysiaFilter
      ? this.initialValueToggleSwitch(canDriveToMalaysiaFilter.paramName)
      : null;

    const canDriveToMalaysiaFilterElement = canDriveToMalaysiaFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.canDriveToMalaysiaFilter2'}
        name="canDriveToMalaysia"
        urlParam={canDriveToMalaysiaFilter.paramName}
        label={canDriveToMalaysiaLabel}
        onSubmit={this.handleToggleSwitch}
        liveEdit
        useLiveEdit={true}
        options={canDriveToMalaysiaFilter.options}
        initialValues={initialCanDriveToMalaysiaFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        className={BSearchFiltersMobileCss.filterElement}
        intl={intl}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
      />
    ) : null;

    const initialIsPetFriendlyFilter = isPetFriendlyFilter
    ? this.initialValueToggleSwitch(isPetFriendlyFilter.paramName)
    : null;

    const isPetFriendlyLabelHelperText = intl.formatMessage({
      id: 'SearchFilterMobile.isPetFriendlyLabelHelperText',
    });

    const isPetFriendlyFilterElement = isPetFriendlyFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.isPetFriendly2'}
        name="isPetFriendly"
        urlParam={isPetFriendlyFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.isPetFriendlyLabel',
        })}
        onSubmit={this.handleToggleSwitch}
        liveEdit
        useLiveEdit={true}
        options={isPetFriendlyFilter.options}
        initialValues={initialIsPetFriendlyFilter}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        className={BSearchFiltersMobileCss.filterElement}
        contentPlacementOffSet={1000}
        intl={intl}
        labelHelperText={isPetFriendlyLabelHelperText}
        plainClassName={BSearchFiltersMobileCss.plainClassName}
        labelClassName={classNames(
          BSearchFiltersMobileCss.labelClassName,
          BSearchFiltersMobileCss.labelClassNameWithLimit
        )}
        customSliderClassName={BSearchFiltersMobileCss.customSlider}
        toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
      />
    ) : null;

    const classes = classNames(rootClassName || BSearchFiltersMobileCss.root, className);

    const resultsFound = (
      <FormattedMessage id="SearchFilters.foundResults" values={{ count: resultsCount }} />
    );
    const noResults = <FormattedMessage id="SearchFilters.noResultsMobile" />;
    const loadingResults = <FormattedMessage id="SearchFilters.loadingResultsMobile" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: resultsCount }
    );
    const filtersButton =
      selectedFiltersCount > 0 ? (
        <Button
          className={classNames(
            BSearchFiltersMobileCss.filtersButton,
            selectedFiltersCount > 0 ? BSearchFiltersMobileCss.filtersSelected : ''
          )}
          onClick={this.openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <defs></defs>
            <path
              class="a"
              fill="#026786"
              d="M11.437,0H.563a.563.563,0,0,0-.4.96L4.5,5.3v4.83a.563.563,0,0,0,.24.461L6.615,11.9a.563.563,0,0,0,.885-.461V5.3L11.835.96A.563.563,0,0,0,11.437,0Z"
              transform="translate(0)"
            />
          </svg>
          <FormattedMessage
            id="SearchFilters.filtersButtonLabel"
            className={BSearchFiltersMobileCss.mapIconText}
          />
          <span className={BSearchFiltersMobileCss.filtersAppliedNumbers}>
            ({selectedFiltersCount})
          </span>
        </Button>
      ) : (
        <SecondaryButton
          className={BSearchFiltersMobileCss.filtersButton}
          onClick={this.openFilters}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            style={{ marginRight: 5, minWidth: 12 }}
          >
            <path
              class="a"
              fill="#026786"
              d="M11.437,0H.563a.563.563,0,0,0-.4.96L4.5,5.3v4.83a.563.563,0,0,0,.24.461L6.615,11.9a.563.563,0,0,0,.885-.461V5.3L11.835.96A.563.563,0,0,0,11.437,0Z"
              transform="translate(0)"
            />
          </svg>
          <FormattedMessage
            id="SearchFilters.filtersButtonLabel"
            className={BSearchFiltersMobileCss.mapIconText}
          />
        </SecondaryButton>
      );

    const categoryLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.categoryLabel',
    });
    const initialCategory = categoryFilter ? this.initialValues(categoryFilter.paramName) : null;

    const categoryFilterElement = categoryFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.categoryFilter"
        name="category"
        urlParam={categoryFilter.paramName}
        label={categoryLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={categoryFilter.options}
        initialValues={initialCategory}
        intl={intl}
        className={BSearchFiltersMobileCss.filterCategoryElement}
        labelClassName={BSearchFiltersMobileCss.labelClassName}
      />
    ) : null;

    const reviewsLabel = intl.formatMessage({
      id: 'SearchFilters.reviewsLabel',
    });

    const initialReviews = reviewsFilter ? this.initialValues(reviewsFilter.paramName) : null;

    const reviewsFilterElement = reviewsFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.reviewsFilter'}
        name="reviews"
        urlParam={reviewsFilter.paramName}
        label={reviewsLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={reviewsFilter.options}
        initialValues={initialReviews}
        intl={intl}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
      />
    ) : null;

    const brandNamesLabel = intl.formatMessage({
      id: 'SearchFilters.brandNamesLabel',
    });

    const initialBrandNames = brandNamesFilter
      ? this.initialValues(brandNamesFilter.paramName)
      : null;

    const brandNamesFilterElement = brandNamesFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.brandNamesFilter'}
        name="brandNames"
        urlParam={brandNamesFilter.paramName}
        label={brandNamesLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={brandNamesFilter.options}
        initialValues={initialBrandNames}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        twoColums={true}
        labelClassName={BSearchFiltersMobileCss.labelClassName}
      />
    ) : null;

    const featuresLabel = intl.formatMessage({
      id: 'SearchFilters.featuresLabel',
    });

    const initialFeatures = featuresFilter ? this.initialValues(featuresFilter.paramName) : null;

    const featuresFilterElement = featuresFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.featuresFilter'}
        name="features"
        urlParam={featuresFilter.paramName}
        label={featuresLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={featuresFilter.options}
        initialValues={initialFeatures}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        intl={intl}
        labelClassName={BSearchFiltersMobileCss.labelClassName}
      />
    ) : null;

    const transmissionsLabel = intl.formatMessage({
      id: 'SearchFilters.transmissionsLabel',
    });

    const typeOfFuelLabel = intl.formatMessage({
      id: 'SearchFilters.typeOfFuelLabel',
    });

    const initialTransmission = transmissionsFilter
      ? this.initialValue(transmissionsFilter.paramName)
      : null;

    const initialTypeOfFuel = typeOfFuelFilter
      ? this.initialValue(typeOfFuelFilter.paramName)
      : null;

    const typeOfFuelFilterElement = typeOfFuelFilter ? (
      <SelectSingleFilter
        id={'SearchFilters.typeOfFuelFilter2'}
        name="typeOfFuel"
        urlParam={typeOfFuelFilter.paramName}
        label={typeOfFuelLabel}
        onSelect={this.handleSelectSingle}
        options={typeOfFuelFilter.options}
        initialValue={initialTypeOfFuel}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
        labelClassName={BSearchFiltersMobileCss.labelClassName}
      />
    ) : null;

    // const initialDistanceRange = this.initialPriceRangeValue(distanceFilter.paramName);

    // const distanceFilterElement = distanceFilter ? (
    //   <PriceFilter
    //     id="SearchFiltersMobile.distanceFilter"
    //     urlParam={distanceFilter.paramName}
    //     onSubmit={this.handlePrice}
    //     liveEdit
    //     labelProps="SearchFilters.distanceFilterLabel"
    //     unitProps="kms"
    //     {...distanceFilter.config}
    //     initialValues={initialDistanceRange}
    //   />
    // ) : null;

    const initialPriceRange = priceFilter
      ? this.initialPriceRangeValue(priceFilter.paramName)
      : null;

    const initialNumberPassengerRange = this.initialNumberPeopleRangeValue(
      numberPassengerFilter.paramName
    );

    const numberPassengerFilterElement = numberPassengerFilter ? (
      <PriceFilter
        id="SearchFiltersMobile.numberPassengerFilter"
        urlParam={numberPassengerFilter.paramName}
        onSubmit={this.handleNumberOfPeople}
        liveEdit
        labelProps="SearchFilters.numberPassengerFilterLabel"
        unitProps=" "
        {...numberPassengerFilter.config}
        initialValues={initialNumberPassengerRange}
        clearButtonClass={BSearchFiltersMobileCss.clearbutton}
      />
    ) : null;

    // const initialDateRange = this.initialDateRangeValue(dateRangeFilter.paramName);

    // const dateRangeFilterElement =
    //   dateRangeFilter && dateRangeFilter.config.active ? (
    //     <BookingDateRangeFilter
    //       id="SearchFilters.dateRangeFilter"
    //       urlParam={dateRangeFilter.paramName}
    //       onSubmit={this.handleDateRange}
    //       liveEdit
    //       showAsPopup={false}
    //       initialValues={initialDateRange}
    //     />
    //   ) : null;

    const priceFilterElement = priceFilter ? (
      <PriceFilter
        id="SearchFiltersMobile.priceFilter"
        urlParam={priceFilter.paramName}
        onSubmit={this.handlePrice}
        liveEdit
        unitProps="$"
        {...priceFilter.config}
        initialValues={initialPriceRange}
      />
    ) : null;

    const initialKeyword = this.initialValue(keywordFilter.paramName);
    const keywordLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.keywordLabel',
    });
    const keywordFilterElement =
      keywordFilter && keywordFilter.config.active ? (
        <KeywordFilter
          id={'SearchFiltersMobile.keywordFilter'}
          name="keyword"
          urlParam={keywordFilter.paramName}
          label={keywordLabel}
          onSubmit={this.handleKeyword}
          liveEdit
          showAsPopup={false}
          initialValues={initialKeyword}
          className={BSearchFiltersMobileCss.lastMobileFilter}
          labelClassName={BSearchFiltersMobileCss.labelClassName}
        />
      ) : null;

    // const initialPHV = this.initialValue(isPHVFilter.paramName);
    // const phvFilterLabel = intl.formatMessage({
    //   id: 'SearchFilters.phvFilterLabel',
    // });
    // const phvFilterElement = isPHVFilter ? (
    //   <SelectSingleFilter
    //     id={'SearchFilters.phvFilterElement'}
    //     name="phvFilterElement"
    //     urlParam={isPHVFilter.paramName}
    //     label={phvFilterLabel}
    //     onSelect={this.handleSelectSingle}
    //     options={isPHVFilter.options}
    //     initialValue={initialPHV}
    //     clearButtonClass={BSearchFiltersMobileCss.clearbutton}
    //     labelClassName={BSearchFiltersMobileCss.labelClassName}
    //   />
    // ) : null;


    // const allowGoodsFilter = isPHVFilter ? (
    //   <ToggleSwitchFilter
    //     id={'SearchFilters.allowGoodsFilter'}
    //     name="phvFilterElement"
    //     urlParam={isPHVFilter.paramName}
    //     label={intl.formatMessage({
    //       id: 'SearchFilters.allowGoods',
    //     })}
    //     liveEdit
    //     useLiveEdit={true}
    //     onSubmit={this.handleToggleSwitch}
    //     initialValues={initialPHV}
    //     clearButtonClass={BSearchFiltersMobileCss.clearbutton}
    //     intl={intl}
    //     labelClassName={classNames(
    //       BSearchFiltersMobileCss.labelClassName,
    //       BSearchFiltersMobileCss.labelClassNameWithLimit
    //     )}
    //     className={BSearchFiltersMobileCss.filterElement}
    //     plainClassName={BSearchFiltersMobileCss.plainClassName}
    //     customSliderClassName={BSearchFiltersMobileCss.customSlider}
    //     toggleSwitchClassName={BSearchFiltersMobileCss.toggleSwitch}
    //     labelHelperText={null}
    //   />
    // ) : null;

    const isKeywordFilterActive = !!initialKeyword;
    const sortBy = config.custom.sortConfig.active ? (
      <SortBy
        rootClassName={BSearchFiltersMobileCss.sortBy}
        menuLabelRootClassName={classNames(
          BSearchFiltersMobileCss.sortByMenuLabel,
          sort ? BSearchFiltersMobileCss.isSorted : ''
        )}
        sort={sort}
        showAsPopup
        isKeywordFilterActive={isKeywordFilterActive}
        onSelect={this.handleSortBy}
      />
    ) : null;

    // const initialInstantBookingFilter = instantBookingFilter ? this.initialBooleanValue(instantBookingFilter.paramName) : null;

    // const instantBookingEl = instantBookingFilter ? (
    //   <ToggleSwitchFilter
    //     id={'SearchFilters.instantBooking'}
    //     name="instantBooking"
    //     urlParam={instantBookingFilter.paramName}
    //     label={intl.formatMessage({
    //       id: 'SearchFilters.instantBooking',
    //     })}
    //     onSubmit={this.handleToogle}
    //     liveEdit
    //     initialValue={initialInstantBookingFilter}
    //   />
    // ) : null;
    return (
      <div className={classes}>
        <div className={BSearchFiltersMobileCss.buttons}>
          {filtersButton}
          {sortBy}
          <div className={BSearchFiltersMobileCss.mapIcon} onClick={onMapIconClick}>
            <FormattedMessage
              id="SearchFilters.openMapView"
              className={BSearchFiltersMobileCss.mapIconText}
            />
          </div>
        </div>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={BSearchFiltersMobileCss.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
        >
          <div className={BSearchFiltersMobileCss.modalHeadingWrapper}>
            <span className={BSearchFiltersMobileCss.modalHeading}>{filtersHeading}</span>
            <button
              className={BSearchFiltersMobileCss.resetAllButton}
              onClick={e => this.resetAll(e)}
            >
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </button>
          </div>

          {this.state.isFiltersOpenOnMobile ? (
            <div className={BSearchFiltersMobileCss.filtersWrapper}>
              {this.canApply() && (
                <>
                  {/* {superHostFilterElement} */}
                  {homeDeliveryFilterElement}
                  {instantBookingFilterElement}
                  {drivelahGoFilterElement}
                  {isPetFriendlyFilterElement}
                  {/* Top offers filter */}
                  {/* {newCarFilterElement} */}
                  {longTermFilterElement}
                  {/* {allowGoodsFilter} */}
                  {/* {disinfectedFilterElement} */}
                </>
              )}
              {/* {dateRangeFilterElement} */}
              {this.canApply() && (
                <div>
                  {/* {canDriveToMalaysiaFilterElement} */}
                  {priceFilterElement}
                  {/* {phvFilterElement} */}
                  {categoryFilterElement}
                  {/*{distanceFilterElement}*/}
                  {/* {reviewsFilterElement} */}
                  {brandNamesFilterElement}
                  {featuresFilterElement}
                  {/* {numberPassengerFilterElement} */}
                  {/* {transmissionsFilterElement} */}
                  {typeOfFuelFilterElement}
                  {keywordFilterElement}
                </div>
              )}
               <label>Car Age: {`${this.state.minCarAge} , ${this.state.maxCarAge}`}</label>
          <RangeSlider
                min={2000}
                max={2030}
                step={1}
                handles={[this.state.minCarAge,this.state.maxCarAge]}
                onChange={handles => {
                                    this.setState(prevState => {
                                      return { minCarAge: handles[0],
                                              maxCarAge: handles[1],};
                                          });

                  const { urlQueryParams, history } = this.props;
                  let changeRangeToNumber = false
                  if(handles[0] === handles[1]) {
                    console.log("same filter")
                    changeRangeToNumber = true
                  }
                  const queryParams =
                  handles[0] != null && handles[1] != null
                      ? { ...urlQueryParams, ['pub_yearOfManufacture']: changeRangeToNumber ? handles[0] : `${handles[0]},${handles[1]}` }
                      : omit(urlQueryParams, 'pub_yearOfManufacture');

                  history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));

                  // const urlQueryParams = this.props.urlQueryParams;
                  // this.setState(prevState => {
                  //   const prevQueryParams = prevState.currentQueryParams;
                  //   const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

                  //   // query parameters after selecting the option
                  //   // if no option is passed, clear the selection for the filter
                  //   const currentQueryParams =  handles[0] != null && handles[1] != null
                  //     ? { ...mergedQueryParams, ['pub_ageOfCar']: `${handles[0]},${handles[1]}` }
                  //     : omit(mergedQueryParams, 'pub_ageOfCar');
                  //   console.log("<<<currentQueryParams",currentQueryParams,"mergedQueryParams",mergedQueryParams)
                  //     return { currentQueryParams , minCarAge: handles[0],
                  //       maxCarAge: handles[1],};
                  //   });
                }}
            />
            <label>No of Seats: {`${this.state.minSeats},${this.state.maxSeats}`}</label>
          <RangeSlider
                min={1}
                max={20}
                step={1}
                handles={[this.state.minSeats,this.state.maxSeats]}
                onChange={handles => {
                                    this.setState(prevState => {
                      return { minSeats: handles[0],
                        maxSeats: handles[1],};
                    });
                  const { urlQueryParams, history } = this.props;
                  const queryParams =
                  handles[0] != null && handles[1] != null
                      ? { ...urlQueryParams, ['pub_peopleNumberMax']: `${handles[0]},${handles[1]}` }
                      : omit(urlQueryParams, 'pub_peopleNumberMax');

                  history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
                  // this.setState(prevState => {
                  //   const prevQueryParams = prevState.currentQueryParams;
                  //   const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

                  //   // query parameters after selecting the option
                  //   // if no option is passed, clear the selection for the filter
                  //   const currentQueryParams =  handles[0] != null && handles[1] != null
                  //     ? { ...mergedQueryParams, ['pub_peopleNumberMax']: `${handles[0]},${handles[1]}` }
                  //     : omit(mergedQueryParams, 'pub_peopleNumberMax');
                  //     console.log("<<<currentQueryParams2",currentQueryParams,"prevQueryParams",prevQueryParams)

                  //     return { currentQueryParams , minSeats: handles[0],
                  //       maxSeats: handles[1],};
                  //   });
                }}
            />
            </div>
          ) : null}

          <div className={BSearchFiltersMobileCss.showListingsContainer}>
            <Button
              className={BSearchFiltersMobileCss.showListingsButton}
              onClick={this.closeFilters}
            >
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

BSearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchingInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  categoryFilter: null,
  reviewsFilter: null,
  brandNamesFilter: null,
  featuresFilter: null,
  transmissionsFilter: null,
  distanceFilter: null,
  priceFilter: null,
  dateRangeFilter: null,
  numberPassengerFilter: null,
};

BSearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  selectedFiltersCount: number,
  filterParamNames: array,
  categoriesFilter: propTypes.filterConfig,
  reviewsFilter: propTypes.filterConfig,
  brandNamesFilter: propTypes.filterConfig,
  featuresFilter: propTypes.filterConfig,
  transmissionsFilter: propTypes.filterConfig,
  distanceFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,
  numberPassengerFilter: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const BSearchFiltersMobile = injectIntl(withRouter(withViewport(BSearchFiltersMobileComponent)));

export default BSearchFiltersMobile;
