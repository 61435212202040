import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Modal } from '../../components';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import css from './TripExtensionModal.css';

const TripExtensionModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'TripExtensionModal.close' });

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      closeButtonMessage={' '}
    >
      <p className={css.modalTitle}>
        <FormattedMessage id="TripExtensionModal.title" />
      </p>
      <p className={css.modalMessage}>
        <FormattedMessage id="TripExtensionModal.description" />
      </p>
    </Modal>
  );
};

const { bool, string } = PropTypes;

TripExtensionModal.defaultProps = {
  className: null,
  rootClassName: null,
};

TripExtensionModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
};

export default injectIntl(TripExtensionModal);
