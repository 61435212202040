import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import css from './TripReceipt.css';
import { useSelector, connect } from 'react-redux';
import { fetchCurrentUser, updateUserData } from '../../ducks/user.duck';

const TripReceipt = props => {
  const { id } = useParams();
  const [tripData, setTripData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchTripData = async () => {

      try {
        console.log('Fetching Data...', id);
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/stripe/receipt/${id}`
        );
        if (!response.ok) {
          throw new Error('Failed to fetch trip data');
        }
        const data = await response.json();
        setTripData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

      fetchTripData();
  }, [id]);

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-GB').replace(/\//g, '.');
  console.log('Formatted Date:', formattedDate);

  const handlePrint = () => {
    const userFirstName = userName || 'User';
    const tripNumber = tripData?.tripReferenceCode || 'Trip';

    const suggestedFilename = `${userFirstName} ${tripNumber} Trip Receipt - ${formattedDate}`;
    document.title = suggestedFilename;

    window.print();
  };

  if (loading) return <p>Loading receipt...</p>;
  if (error) return <p>Error: {error}</p>;

  const {
    userName = 'User',
    listingTitle = 'Unknown Listing',
    tripReferenceCode = 'N/A',
    licensePlateNumber = 'N/A',
    startDateTime = null,
    endDateTime = null,
    bookedOn = null,
    durationLineItems = [],
    distanceLineItems = [],
    otherCostLineItems = [],
    durationPaymentDetails = [],
    distancePaymentDetails = [],
    totalDurationCharge = 0,
    totalDistanceCharge = 0,
    totalOtherCosts = 0,
    grandTotal = 0,
  } = tripData ?? {};

  // Helper function to format payment date
  const formatPaymentDate = timestamp => {
    return new Date(timestamp * 1000).toLocaleDateString();
  };

  // Helper function to format dates in the desired format
  const formatDisplayDate = dateString => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const hours = date.getHours() % 12 || 12;
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';

    const getOrdinalSuffix = day => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    return `${day}${getOrdinalSuffix(day)} ${month} ${hours}:${minutes} ${ampm}`;
  };

  // Helper function to render payment details
  const renderPaymentDetails = (paymentDetails) => {
    if (paymentDetails.length === 0) {
      return (
        <div className={`${css.paymentBox} ${css.noPayment}`}>
          <div className={css.paymentHeader}>You paid</div>
          <div className={css.amount}>$0.00</div>
        </div>
      );
    }

    return paymentDetails.map((payment, index) => {
      const hasCardDetails = payment?.card && payment?.card?.brand && payment?.card?.last4;

      return (
        <div
          key={index}
          className={`${css.paymentBox} ${!hasCardDetails ? css.noPayment : ''}`}
        >
          <div className={css.paymentHeader}>You paid</div>
          {hasCardDetails ? (
            <div className={css.paymentContent}>
              <div className={css.cardDetails}>
                {payment.card.brand.toUpperCase()} **** **** **** {payment.card.last4} on{' '}
                {formatPaymentDate(payment.paymentDate)}
              </div>
              <div className={css.amount}>${payment.amount.value.toFixed(2)}</div>
            </div>
          ) : (
            <div className={css.amount}>${payment.amount.value.toFixed(2)}</div>
          )}
        </div>
      );
    });
  };


  return (
    <div className={`${css.tripreceipt} printable-content`}>
      {/* Header Section */}
      <div className={css.header}>
        <div className={css.headerLeft}>
          <div className={css.logoSection}>
            <img
              src="https://dv0eqz2t0y9gj.cloudfront.net/drivemate/assets/logos/drive-mate-footer-logo.png"
              alt="Drive Mate Logo"
              className={css.logo}
            />
          </div>
          <div className={css.contentSection}>
            <h1 className={css.title}>Trip receipt</h1>
            <span className={css.trip}>Trip #{tripReferenceCode}</span>
          </div>
        </div>
        <button onClick={handlePrint} className={css.printButton}>
          Print
        </button>
      </div>
      <hr className={css.divider} />

      {/* Vehicle Info Section */}
      <div className={css.vehicleInfo}>
        <h2>
          <span className={css.listingTitle}>{listingTitle}</span>
          <span className={css.licensePlate}>({licensePlateNumber})</span>
        </h2>
        <span className={css.dateInfo}>
          {formatDisplayDate(startDateTime)} to {formatDisplayDate(endDateTime)}
        </span>
        <span className={css.bookedOn}>Booked on {formatDisplayDate(bookedOn)}</span>
      </div>

      <hr className={css.divider} />

      {/* Hire Cost Section */}
      {durationLineItems.length > 0 && (
        <div className={css.chargesSection}>
          <h3>Hire Costs</h3>
          {durationLineItems.map((item, index) => (
            <div key={index} className={css.chargeItem}>
              <span className={css.itemName} style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}>{item.description}</span>
              <span className={css.dashedLine}></span>
              <span
                className={css.itemAmount}
                style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}
              >
                {item.amount < 0 ? `-$${Math.abs(item.amount).toFixed(2)}` : `$${item.amount}`}
              </span>
            </div>
          ))}
          <div className={css.total}>
            <span>Total duration charge</span>
            <span className={css.dashedLine}></span>
            <span style={{ color: totalDurationCharge < 0 ? '#0AA3AD' : '#4A4A4A' }}>
              {totalDurationCharge < 0
                ? `-$${Math.abs(totalDurationCharge)}`
                : `$${totalDurationCharge}`}
            </span>
          </div>
          <p className={css.gstNote}>GST included</p>
          {renderPaymentDetails(durationPaymentDetails)}
        </div>
      )}
      <hr className={css.divider} />

      {/* Driving Costs Section */}
      {distanceLineItems.length > 0 && (
        <div className={css.chargesSection}>
          <h3>Driving Costs</h3>
          {distanceLineItems.map((item, index) => (
            <div key={index} className={css.chargeItem}>
              <span className={css.itemName} style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}>{item.description}</span>
              <span className={css.dashedLine}></span>
              <span
                className={css.itemAmount}
                style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}
              >
                {item.amount < 0 ? `-$${Math.abs(item.amount).toFixed(2)}` : `$${item.amount}`}
              </span>
            </div>
          ))}
          <div className={css.total}>
            <span>Total trip end charge</span>
            <span className={css.dashedLine}></span>
            <span style={{ color: totalDistanceCharge < 0 ? '#0AA3AD' : '#4A4A4A' }}>
              {totalDistanceCharge < 0
                ? `-$${Math.abs(totalDistanceCharge)}`
                : `$${totalDistanceCharge}`}
            </span>
          </div>
          <p className={css.gstNote}>GST included</p>
          {renderPaymentDetails(distancePaymentDetails)}
        </div>
      )}

      {/* Other Costs Section */}
      {otherCostLineItems.length > 0 && (
        <div className={css.chargesSection}>
          <h3>Other Costs</h3>
          {otherCostLineItems.map((item, index) => (
            <div key={index} className={css.chargeItem}>
              <span className={css.itemName} style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}>{item.description}</span>
              <span className={css.dashedLine}></span>
              <span
                className={css.itemAmount}
                style={{ color: item.amount < 0 ? '#0AA3AD' : '#4A4A4A' }}
              >
                {item.amount < 0 ? `-$${Math.abs(item.amount).toFixed(2)}` : `$${item.amount}`}
              </span>
            </div>
          ))}
          <div className={css.total}>
            <span>Total other costs</span>
            <span className={css.dashedLine}></span>
            <span style={{ color: totalOtherCosts < 0 ? '#0AA3AD' : '#4A4A4A' }}>
              {totalOtherCosts < 0 ? `-$${Math.abs(totalOtherCosts)}` : `$${totalOtherCosts}`}
            </span>
          </div>
          <p className={css.gstNote}>GST included</p>
        </div>
      )}

      {/* <hr className={css.divider} /> */}

      {/* Final Total Section */}
      <div className={css.finalTotal}>
        <h2>Total</h2>
        <p>${grandTotal}</p>
      </div>
      <hr className={css.divider} />

      {/* Footer Section */}
      <div className={css.footer}>
        <span>
          Amounts listed are valid as of {formattedDate} and may change during or after the trip due
          to additional charges or refunds based on usage.
        </span>
        <hr className={css.divider} />
        <div className={css.companyInfo}>
          <span>Drive mate</span>
        </div>

        <address>
          Drive lah Australia Pty Ltd
          <br />
          ABN number: 19640421496
          <br />
          Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
        </address>
      </div>
    </div>
  );
};


const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: () => dispatch(fetchCurrentUser()),
  onUpdateUserData: (data) => dispatch(updateUserData(data)),
  // fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});


export default connect(mapStateToProps, mapDispatchToProps)(TripReceipt);
