import React, { Component, createRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration, { getRouteName } from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import { isMobile, isSafari } from 'react-device-detect';
import {
  Avatar,
  Button,
  ErrorBoundary,
  IconArrowDown,
  LimitedAccessBanner,
  Logo,
  MobileAppBanner,
  Modal,
  ModalMissingInformation,
  ModalMissingPostalCode,
  NamedLink,
  SearchPageTopbarDesktop,
  StripeOnboardingAlert,
  TopbarDesktop,
  TopbarMobileMenu,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import TopbarSearchModal from './TopbarSearchModal';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_LOGGEDOUT,
  EVENT_BROWSE_OWNPROFILE,
  EVENT_BROWSE_PHONEICON,
} from '../../util/gtm/gtmConstants';
import SearchIcon from './SearchIcon';
import css from './Topbar.css';
import { WarningAlert } from '../WarningAlert/WarningAlert';

const MAX_MOBILE_SCREEN_WIDTH = 768;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

export const GenericError = props => {
  const { show, error } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          {error
            ? error
            : <FormattedMessage id="Topbar.genericError" />
          }
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.setIntercomSettings = false;
    this.previousLoggedState = false;
    this.topbar = createRef();
    this.topbarContainer = createRef();
    this.contactUs = createRef();

    this.state = {
      showBanner: false,
      isModalVisible: false,
      isSubscriptionPage: false,
    };
  }

  handleShowBanner = () => {
    const { isAuthenticated, currentUser, currentPage } = this.props;
    if (currentPage !== 'LandingPage') return;
    this.id = !isAuthenticated
      ? 'showMobileAppBanner'
      : isAuthenticated && currentUser && currentUser.id
      ? `${currentUser.id.uuid}_showMobileAppBanner`
      : null;

    if (this.id && isMobile && isSafari) {
      const showBannerValue = window.localStorage.getItem(this.id);
      if ((this.state.showBanner !== showBannerValue) !== 'hide')
        this.setState({ showBanner: showBannerValue !== 'hide' });
    }
  };

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#00a3ad';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#00a3ad';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { history } = this.props;
    const isSubscriptionPage = history && history.location && history.location.pathname && history.location.pathname.includes('/subscription');
    if (prevState.isSubscriptionPage !== this.state.isSubscriptionPage) {
      this.setState({ isSubscriptionPage: isSubscriptionPage });
      // if (isSubscriptionPage) {
      //   IntercomNotVisibility(true);
      // } else {
      //   IntercomNotVisibility(false);
      // }
    }
  }

  componentDidMount() {
    this.handleShowBanner();
    if (this.props.transparent) {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = 'transparent';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = 'transparent';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'none';
      }
      document.addEventListener('scroll', this.handleScroll);
    } else {
      if (this.topbar.current) {
        this.topbar.current.style.backgroundColor = '#00a3ad';
      }
      if (this.topbar.current) {
        this.topbarContainer.current.style.backgroundColor = '#00a3ad';
      }
      if (this.contactUs.current) {
        this.contactUs.current.style.display = 'flex';
        this.contactUs.current.style.flexDirection = 'row';
        this.contactUs.current.style.justifyContent = 'space-evenly';
      }
    }

    const { history } = this.props;
    const isSubscriptionPage = history && history.location && history.location.pathname && history.location.pathname.includes('/subscription');
    if (isSubscriptionPage) {
      this.setState({ isSubscriptionPage: isSubscriptionPage });
    }
  }

  componentWillUnmount() {
    if (this.props.transparent) {
      document.removeEventListener('scroll', this.handleScroll);
    }
  }

  componentWillReceiveProps(props) {
    this.handleShowBanner();
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      timezone: selectedPlace.timezone,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());
      pushGTMBrowseEvent({
        props: this.props,
        event: EVENT_BROWSE_LOGGEDOUT,
        eventCallback: () => {
          // In production we ensure that data is really lost,
          // but in development mode we use stored values for debugging
          if (config.dev) {
            history.push(path);
          } else if (typeof window !== 'undefined') {
            window.location = path;
          }

          console.log('logged out'); // eslint-disable-line
        },
      });
    });
  }

  handleStipeOnboarding = () => {
    const {  history } = this.props;
    history.push('/account/payments')
  }

  onViewOwnProfile = () => {
    const { currentUser } = this.props;
    if (currentUser) {
      pushGTMBrowseEvent({
        props: this.props,
        event: EVENT_BROWSE_OWNPROFILE,
      });
    }
  };



  closeHourleyBanner = () => {
    this.setState({ showBanner: false });
    if (this.id) {
      window.localStorage.setItem(this.id, 'hide');
    }
  };

  pushGTMBrowse = (eventGTM, eventAttribute) => {
    let eventCallback;
    if (eventAttribute) {
      eventAttribute.preventDefault();
      const href = eventAttribute.currentTarget.href;
      eventCallback = () => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      };
    }
    pushGTMBrowseEvent({
      props: this.props,
      event: eventGTM,
      eventCallback,
    });
  };




  render() {
    const {
      className,
      rootClassName,
      notApplySearch,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      location,
      history,
      onManageDisableScrolling,
      showGenericError,
      modalMissingInfomationProps,
      waitingForUploadAva,
      onSetupWaitingForUploadAvaThenVerifyGuest,
      contactUsClassName,
      searchSelectedPrediction,
      onLoginOrSignupClick,
      currentUserStripeAccountRestricted,
      onCloseAlert,
      logoutInProgress,
      onGetStripeConnectAccountLink,
      setIsSearchedFocused
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, timezone, bounds, code } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const rootURL = config.canonicalRootURL;
    const successURL = `${rootURL}${'/account/payments'}`;
    const failureURL = `${rootURL}${'/account/payments'}`;


    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        location={location}
        onClose={this.handleMobileMenuClose}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds, timezone },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className, {
      [css.topbarRootMobileWithBanner]: this.state.showBanner,
    });

    const openChat = () => {
      window.Intercom('show');
    };

    const  handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
      getLinkFn({ type, ...commonParams })
        .then(url => {
          window.location.href = url;
        })
        .catch(err => console.error(err));
    };

    const accountId = currentUser && currentUser.stripeAccount && currentUser.stripeAccount.attributes && currentUser.stripeAccount.attributes.stripeAccountId;


    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

   
    const hasShowMissingInformationModal = !logoutInProgress && get(currentUser, "attributes.profile.protectedData.phoneNumber");
    // const isSubscriptionPage = history && history.location && history.location.pathname && history.location.pathname.includes('/subscription');
    console.log("User Restriction", currentUserStripeAccountRestricted)
    return (
      <div className={classes} id="topbar" ref={this.topbar}>
        {currentUserStripeAccountRestricted && <StripeOnboardingAlert
              currentUserStripeAccountRestricted={currentUserStripeAccountRestricted}
              isAuthenticated={isAuthenticated}
              onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                'custom_account_verification'
              )}
              currentUser={currentUser}
              handleStipeOnboarding={this.handleStipeOnboarding}
              currentPage={currentPage}
            />}
        <MobileAppBanner
          closeHourleyBanner={this.closeHourleyBanner}
          showBanner={this.state.showBanner}
          className={classNames({ [css.bannerInMobile]: this.state.showBanner })}
        />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        <div
          ref={this.topbarContainer}
          className={classNames(mobileRootClassName || css.container, mobileClassName, {
            [css.mobileWithBanner]: this.state.showBanner,
          }, {
            [css.mobileWithStripeBanner]: currentUserStripeAccountRestricted,
          })}
        >
          <NamedLink name={`${getRouteName(history)}`}>
            <Logo format="mobile" />
          </NamedLink>

          <div className={css.avatarTopbarWrapper} onClick={this.handleMobileMenuOpen}>
            <Avatar user={currentUser} />
            <IconArrowDown className={css.arrowDown} />
          </div>
          {notApplySearch ? null : (
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              {!notApplySearch && <SearchIcon className={css.searchMenuIcon} />}
            </Button>
          )}
        </div>
        <div className={css.desktop}>
          {!isMobileLayout && currentPage === 'SearchPage' ? (
            <SearchPageTopbarDesktop
              currentUserStripeAccountRestricted={currentUserStripeAccountRestricted}
              className={desktopClassName}
              notApplySearch={notApplySearch}
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              currentPage={currentPage}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              location={location}
              history={history}
              searchSelectedPrediction={searchSelectedPrediction}
              onLoginOrSignupClick={onLoginOrSignupClick}
            />
          ) : (
            <TopbarDesktop
               currentUserStripeAccountRestricted={currentUserStripeAccountRestricted}
              className={desktopClassName}
              notApplySearch={notApplySearch}
              currentUserHasListings={currentUserHasListings}
              currentUser={currentUser}
              currentPage={currentPage}
              initialSearchFormValues={initialSearchFormValues}
              intl={intl}
              isAuthenticated={isAuthenticated}
              notificationCount={notificationCount}
              onLogout={this.handleLogout}
              onSearchSubmit={this.handleSubmit}
              location={location}
              history={history}
              onLoginOrSignupClick={onLoginOrSignupClick}
              setIsSearchedFocused={setIsSearchedFocused}
            />
          )}
        </div>
        <Modal
          closeButtonClassName={css.closeButtonClassName}
          closeTextClassName={css.closeTextClassName}
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.mobileMenuContainer}
          closeIconClassName={css.closeIconClassName}
          scrollLayerClassName={css.mobileMenuScrollLayer}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        {!notApplySearch && (
          <Modal
            id="TopbarMobileSearch"
            containerClassName={css.modalContainer}
            isOpen={isMobileSearchOpen}
            onClose={this.handleMobileSearchClose}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.searchContainer}>
              <TopbarSearchForm
                formId="TopbarSearchForm"
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
              />
              <p className={css.mobileHelp}>
                <FormattedMessage id="Topbar.mobileSearchHelp" />
              </p>
            </div>
          </Modal>
        )}
        <ErrorBoundary>
          <ModalMissingPostalCode
            id="ModalMissingPostalCode"
            currentUser={currentUser}
            location={location}
            history={history}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.missingPostalCodeModal}
            modalServicesProps={modalMissingInfomationProps}
          />
        </ErrorBoundary>
        {hasShowMissingInformationModal &&
          <ModalMissingInformation
            id="MissingInformationReminder"
            containerClassName={css.missingInformationModal}
            currentUser={currentUser}
            currentUserHasListings={currentUserHasListings}
            currentUserHasOrders={currentUserHasOrders}
            location={location}
            history={history}
            onManageDisableScrolling={onManageDisableScrolling}
            modalMissingInfomationProps={modalMissingInfomationProps}
            waitingForUploadAva={waitingForUploadAva}
            onSetupWaitingForUploadAvaThenVerifyGuest={onSetupWaitingForUploadAvaThenVerifyGuest}
            onMissingInformationModalToggle={(isVisible) => { this.setState({ isModalVisible: isVisible ? true : false }); }}
          />
        }
        <GenericError show={showGenericError} />
        {/* <WarningAlert
          show={currentUserStripeAccountRestricted}
          onClose={onCloseAlert}>
          <FormattedMessage id="Topbar.restrictedStripeAccountAlert" />
        </WarningAlert> */}
        {isMobileLayout && currentPage === 'SearchPage' && (
          <TopbarSearchModal
            currentPage={currentPage}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        )}
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
  notApplySearch: true,
  transparent: false,
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  showGenericError: bool.isRequired,
  onLoginOrSignupClick: func.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
