/**
 * SearchFiltersPanel can be used to add extra filters to togglable panel in SearchPage.
 * Using this component will need you to enable it in SearchPage and passing needed props to
 * SearchFilters component (which is the default place for SearchFilters).
 *
 *
 * An example how to render MultiSelectFilter for a filter that has it's config passed in
 * the props as newFilter:
 *
 * initialValue for a filter can be resolved with the initialValue and initialValues
 * methods.
 *
 * const initialNewFilterValues = this.initialValues(newFilter.paramName);
 *
 * const newFilterElement = newFilter ? (
 * <SelectMultipleFilter
 *     id="SearchFiltersPanel.newFilter"
 *     name="newFilter"
 *     urlParam={newFilter.paramName}
 *     label={this.props.intl.formatMessage({ id: 'SearchFiltersPanel.newFilterLabel' })}
 *     onSubmit={this.handleSelectMultiple}
 *     liveEdit
 *     options={multiSelectFilterXFromProps}
 *     initialValues={initialNewFilterValues}
 *  />
 * ) : null;
 */

import React, { Component } from 'react';
import { array, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  InlineTextButton,
  PriceFilter,
  RangeSlider,
  SelectMultipleFilter,
  SelectSingleFilter,
  ToggleSwitchFilter,
} from '../../components';
import css from './SearchFiltersPanel.css';

const RADIX = 10;

class SearchFiltersPanelComponent extends Component {
  constructor (props) {
    super(props);
    this.state = { currentQueryParams: props.urlQueryParams, minCarAge: 2000, maxCarAge: 2030, minSeats: 1, maxSeats: 20 };

    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.handleToggleSwitch = this.handleToggleSwitch.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.initialPriceRangeValue = this.initialPriceRangeValue.bind(this);
    this.initialValueToggleSwitch = this.initialValueToggleSwitch.bind(this);
    this.handleNumberOfPeople = this.handleNumberOfPeople.bind(this);
    this.initialNumberPeopleRangeValue = this.initialNumberPeopleRangeValue.bind(this);
  }


  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    console.log("Filter Applying ", this.props, this.state.currentQueryParams);
    const { history, urlQueryParams, onClosePanel } = this.props;
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        { ...this.state.currentQueryParams }
      )
    );
    console.log(this.state.currentQueryParams, "this.state.currentQueryParams", history, "history")

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
    this.props.onClosePanel();
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, onClosePanel, filterParamNames } = this.props;

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));

    // Ensure that panel closes (if now changes have been made)
    onClosePanel();

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  handleSelectSingle(urlParam, option) {
    const urlQueryParams = this.props.urlQueryParams;
    this.setState(prevState => {
      const prevQueryParams = prevState.currentQueryParams;
      let mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };
      // query parameters after selecting the option
      // if no option is passed, clear the selection for the filter
      const currentQueryParams = option
        ? { ...mergedQueryParams, [urlParam]: option }
        : omit(mergedQueryParams, urlParam);

      return { currentQueryParams };
    });
  }

  handleSelectMultiple(urlParam, options) {
    const urlQueryParams = this.props.urlQueryParams;
    this.setState(prevState => {
      const prevQueryParams = prevState.currentQueryParams;
      const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

      // query parameters after selecting options
      // if no option is passed, clear the selection from state.currentQueryParams
      const currentQueryParams =
        options && options.length > 0
          ? { ...mergedQueryParams, [urlParam]: options.join(',') }
          : { ...mergedQueryParams, [urlParam]: null };

      return { currentQueryParams };
    });
  }

  handleToggleSwitch(urlParam, choice) {
    const urlQueryParams = this.props.urlQueryParams;
    this.setState(prevState => {
      const prevQueryParams = prevState.currentQueryParams;
      const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

      // query parameters after selecting options
      // if no option is passed, clear the selection from state.currentQueryParams
      const currentQueryParams = choice
        ? { ...mergedQueryParams, [urlParam]: true }
        : { ...mergedQueryParams, [urlParam]: null };

      return { currentQueryParams };
    });
  }

  handlePrice(urlParam, range) {
    const { urlQueryParams, history } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    this.setState({ currentQueryParams: queryParams });

    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  handleNumberOfPeople(urlParam, range) {
    const { urlQueryParams, history } = this.props;
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${maxPrice},` }
        : omit(urlQueryParams, urlParam);

    this.setState({ currentQueryParams: queryParams });

    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const currentQueryParam = currentQueryParams[paramName];

    return typeof currentQueryParam !== 'undefined' ? currentQueryParam : urlQueryParams[paramName];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    const splitQueryParam = queryParam => (queryParam ? queryParam.split(',') : []);

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const hasCurrentQueryParam = typeof currentQueryParams[paramName] !== 'undefined';

    return hasCurrentQueryParam
      ? splitQueryParam(currentQueryParams[paramName])
      : splitQueryParam(urlQueryParams[paramName]);
  }

  // resolve initial values for a multi value filter
  initialValueToggleSwitch(paramName) {
    const currentQueryParams = this.state.currentQueryParams;
    const urlQueryParams = this.props.urlQueryParams;

    // initialValue for a select should come either from state.currentQueryParam or urlQueryParam
    const currentQueryParam = currentQueryParams[paramName];
    return typeof currentQueryParam !== 'undefined' ? currentQueryParam : urlQueryParams[paramName];
  }

  initialPriceRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price ? price.split(',').map(v => Number.parseInt(v, RADIX)) : [];

    return !!price && valuesFromParams.length === 2
      ? {
        minPrice: valuesFromParams[0],
        maxPrice: valuesFromParams[1],
      }
      : null;
  }

  initialNumberPeopleRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price
      ? [1].concat(price.split(',').map(v => Number.parseInt(v, RADIX))[0])
      : [];

    return !!price && valuesFromParams.length === 2
      ? {
        minPrice: valuesFromParams[0],
        maxPrice: valuesFromParams[1],
      }
      : null;
  }

  render() {
    const {
      rootClassName,
      className,
      urlQueryParams,
      intl,
      categoryFilter,
      reviewsFilter,
      brandNamesFilter,
      featuresFilter,
      transmissionsFilter,
      instantBookingFilter,
      canDriveToMalaysiaFilter,
      typeOfFuelFilter,
      distanceFilter,
      priceFilter,
      numberPassengerFilter,
      isPHVFilter,
      drivelahGoFilter,
      isPetFriendlyFilter
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    const canApply = !!urlQueryParams.dates;

    const categoryLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.categoryLabel',
    });
    const initialCategory = categoryFilter ? this.initialValues(categoryFilter.paramName) : null;

    const categoryFilterElement = categoryFilter ? (
      <SelectMultipleFilter
        id="SearchFiltersMobile.categoryFilter2"
        name="category"
        urlParam={categoryFilter.paramName}
        label={categoryLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={categoryFilter.options}
        initialValues={initialCategory}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const reviewsLabel = intl.formatMessage({
      id: 'SearchFilters.reviewsLabel',
    });

    const initialReviews = reviewsFilter ? this.initialValues(reviewsFilter.paramName) : null;

    const reviewsFilterElement = reviewsFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.reviewsFilter2'}
        name="reviews"
        urlParam={reviewsFilter.paramName}
        label={reviewsLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={reviewsFilter.options}
        initialValues={initialReviews}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const brandNamesLabel = intl.formatMessage({
      id: 'SearchFilters.brandNamesLabel',
    });

    const initialBrandNames = brandNamesFilter
      ? this.initialValues(brandNamesFilter.paramName)
      : null;

    const brandNamesFilterElement = brandNamesFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.brandNamesFilter2'}
        name="brandNames"
        urlParam={brandNamesFilter.paramName}
        label={brandNamesLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={brandNamesFilter.options}
        initialValues={initialBrandNames}
        clearButtonClass={css.clearbutton}
        threeColumns={true}
        className={css.fullHeight}
        intl={intl}
      // twoColumns={true}
      />
    ) : null;

    const featuresLabel = intl.formatMessage({
      id: 'SearchFilters.featuresLabel',
    });

    const initialFeatures = featuresFilter ? this.initialValues(featuresFilter.paramName) : null;

    const featuresFilterElement = featuresFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.featuresFilter2'}
        name="features"
        urlParam={featuresFilter.paramName}
        label={featuresLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={featuresFilter.options}
        initialValues={initialFeatures}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const transmissionsLabel = intl.formatMessage({
      id: 'SearchFilters.transmissionsLabel',
    });

    const typeOfFuelLabel = intl.formatMessage({
      id: 'SearchFilters.typeOfFuelLabel',
    });

    const canDriveToMalaysiaLabel = intl.formatMessage({
      id: 'SearchFilters.canDriveToMalaysiaLabel',
    });

    const initialTransmission = transmissionsFilter
      ? this.initialValue(transmissionsFilter.paramName)
      : null;

    const transmissionsFilterElement = transmissionsFilter ? (
      <SelectSingleFilter
        id={'SearchFilters.transmissionsFilter2'}
        name="transmissions"
        urlParam={transmissionsFilter.paramName}
        label={transmissionsLabel}
        onSelect={this.handleSelectSingle}
        options={transmissionsFilter.options}
        initialValue={initialTransmission}
        clearButtonClass={css.clearbutton}
      />
    ) : null;

    const initialCanDriveToMalaysia = canDriveToMalaysiaFilter
      ? this.initialValues(canDriveToMalaysiaFilter.paramName)
      : null;

    const canDriveToMalaysiaFilterElement = canDriveToMalaysiaFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.canDriveToMalaysiaFilter2'}
        name="canDriveToMalaysia"
        urlParam={canDriveToMalaysiaFilter.paramName}
        label={canDriveToMalaysiaLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={canDriveToMalaysiaFilter.options}
        initialValues={initialCanDriveToMalaysia}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const initialInstantBookingFilter = instantBookingFilter
      ? !!this.initialValue(instantBookingFilter.paramName)
      : null;

    const instantBookingFilterElement = instantBookingFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.instantBooking'}
        name="instantBooking"
        urlParam={instantBookingFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.instantBooking',
        })}
        onSubmit={this.handleToggleSwitch}
        liveEdit
        initialValue={initialInstantBookingFilter}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const initialDrivelagGoFilter = drivelahGoFilter
      ? !!this.initialValue(drivelahGoFilter.paramName)
      : null;

    const drivelahGoFilterElement = drivelahGoFilter ? (
      <ToggleSwitchFilter
        id={'SearchFilters.drivelahGoFilter'}
        name="drivelahGoFilter"
        urlParam={drivelahGoFilter.paramName}
        label={intl.formatMessage({
          id: 'SearchFilters.drivelahGoFilter',
        })}
        onSubmit={this.handleToggleSwitch}
        liveEdit
        initialValue={initialDrivelagGoFilter}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    const initialTypeOfFuel = typeOfFuelFilter
      ? this.initialValue(typeOfFuelFilter.paramName)
      : null;

    const typeOfFuelFilterElement = typeOfFuelFilter ? (
      <SelectSingleFilter
        id={'SearchFilters.typeOfFuelFilter2'}
        name="typeOfFuel"
        urlParam={typeOfFuelFilter.paramName}
        label={typeOfFuelLabel}
        onSelect={this.handleSelectSingle}
        options={typeOfFuelFilter.options}
        initialValue={initialTypeOfFuel}
        clearButtonClass={css.clearbutton}
      />
    ) : null;

    const pvhLabel = intl.formatMessage({ id: 'SearchFilters.phvFilterLabel' });
    const intialPHV = isPHVFilter ? this.initialValue(isPHVFilter.paramName) : null;
    const phvFilterElement = isPHVFilter ? (
      <SelectSingleFilter
        id="SearchFilters.phvFilterLabel"
        name="isPHV"
        urlParam={isPHVFilter.paramName}
        label={pvhLabel}
        options={isPHVFilter.options}
        onSelect={this.handleSelectSingle}
        initialValue={intialPHV}
        clearButtonClass={css.clearbutton}
      />
    ) : null;

    // const initialDistanceRange = this.initialPriceRangeValue(distanceFilter.paramName);

    // const distanceFilterElement = distanceFilter ? (
    //   <PriceFilter
    //     id="SearchFiltersMobile.distanceFilter2"
    //     urlParam={distanceFilter.paramName}
    //     onSubmit={this.handlePrice}
    //     liveEdit
    //     labelProps="SearchFilters.distanceFilterLabel"
    //     unitProps="kms"
    //     {...distanceFilter.config}
    //     initialValues={initialDistanceRange}
    //     clearButtonClass={css.clearbutton}
    //   />
    // ) : null;

    const initialPriceRange = priceFilter
      ? this.initialPriceRangeValue(priceFilter.paramName)
      : null;

    const priceFilterElement = priceFilter ? (
      <PriceFilter
        id="SearchFiltersMobile.priceFilter2"
        className={css.priceFilter}
        urlParam={priceFilter.paramName}
        onSubmit={this.handlePrice}
        liveEdit
        unitProps="$"
        {...priceFilter.config}
        initialValues={initialPriceRange}
        clearButtonClass={css.clearbutton}
      />
    ) : null;

    const initialNumberPassengerRange = this.initialNumberPeopleRangeValue(
      numberPassengerFilter.paramName
    );

    const numberPassengerFilterElement = numberPassengerFilter ? (
      <PriceFilter
        id="SearchFiltersMobile.numberPassengerFilter2"
        urlParam={numberPassengerFilter.paramName}
        onSubmit={this.handleNumberOfPeople}
        liveEdit
        labelProps="SearchFilters.numberPassengerFilterLabel"
        unitProps=" "
        {...numberPassengerFilter.config}
        initialValues={initialNumberPassengerRange}
        clearButtonClass={css.clearbutton}
      />
    ) : null;

    const isPetFriendlyLabel = intl.formatMessage({
      id: 'SearchFilters.isPetFriendlyLabel',
    });

    const initialIsPetFriendly = isPetFriendlyFilter
      ? this.initialValues(isPetFriendlyFilter.paramName)
      : null;

    const isPetFriendlyFilterElement = isPetFriendlyFilter ? (
      <SelectMultipleFilter
        id={'SearchFilters.isPetFriendly2'}
        name="isPetFriendly"
        urlParam={isPetFriendlyFilter.paramName}
        label={isPetFriendlyLabel}
        onSubmit={this.handleSelectMultiple}
        liveEdit
        options={isPetFriendlyFilter.options}
        initialValues={initialIsPetFriendly}
        clearButtonClass={css.clearbutton}
        className={css.fullHeight}
        intl={intl}
      />
    ) : null;

    return (
      <div className={classes}>
        <div className={css.filtersWrapper}>
          <div className={css.filterWrapper}>{categoryFilterElement}</div>
          <div className={css.filterWrapper}>{transmissionsFilterElement}</div>
          {/* <div className={css.filterWrapper}>{priceFilterElement}</div> */}
        </div>
        <div className={css.filtersWrapper}>
          <div className={css.filterWrapperFullWidth}>{brandNamesFilterElement}</div>
        </div>
        <div className={css.filtersWrapper}>
          <div className={css.filterWrapper}>{featuresFilterElement}</div>
          <div className={css.filterWrapper}>
            <div className={css.filterWrapper}>
              {typeOfFuelFilterElement}
            </div>
            <div className={css.filterWrapper}>
              {isPetFriendlyFilterElement}
            </div>
          </div>
          <div className={css.filterWrapper}>
            <label>Car Age: {`${this.state.minCarAge} , ${this.state.maxCarAge}`}</label>
            <RangeSlider
              min={2000}
              max={2030}
              step={1}
              handles={[this.state.minCarAge, this.state.maxCarAge]}
              onChange={handles => {
                const urlQueryParams = this.props.urlQueryParams;
                this.setState(prevState => {
                  const prevQueryParams = prevState.currentQueryParams;
                  const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };

                  // query parameters after selecting the option
                  // if no option is passed, clear the selection for the filter
                  //Check if year 1 and 2 are same then pass single digit
                  let changeRangeToNumber = false
                  if(handles[0] === handles[1]) {
                    console.log("same filter")
                    changeRangeToNumber = true
                  }
                  const currentQueryParams = handles[0] != null && handles[1] != null
                    ? { ...mergedQueryParams, ['pub_yearOfManufacture']: changeRangeToNumber ? handles[0] : `${handles[0]},${handles[1]}` }
                    : omit(mergedQueryParams, 'pub_yearOfManufacture');
                  console.log("<<<currentQueryParams", currentQueryParams, "mergedQueryParams", mergedQueryParams)
                  return {
                    currentQueryParams, minCarAge: handles[0],
                    maxCarAge: handles[1],
                  };
                });
              }}
            />
          </div>
          <div className={css.filterWrapper}>
            <label>No of Seats: {`${this.state.minSeats},${this.state.maxSeats}`}</label>
            <RangeSlider
              min={1}
              max={20}
              step={1}
              handles={[this.state.minSeats, this.state.maxSeats]}
              onChange={handles => {
                const urlQueryParams = this.props.urlQueryParams;
                this.setState(prevState => {
                  const prevQueryParams = prevState.currentQueryParams;
                  const mergedQueryParams = { ...urlQueryParams, ...prevQueryParams };
                  const mergeFilter = handles[0] == handles[1] ? handles[0] : `${handles[0]},${handles[1] + 1}`

                  // query parameters after selecting the option
                  // if no option is passed, clear the selection for the filter
                  const currentQueryParams = handles[0] != null && handles[1] != null
                    ? { ...mergedQueryParams, ['pub_peopleNumberMax']: mergeFilter }
                    : omit(mergedQueryParams, 'pub_peopleNumberMax');
                  console.log("<<<currentQueryParams2", currentQueryParams, "prevQueryParams", prevQueryParams)

                  return {
                    currentQueryParams, minSeats: handles[0],
                    maxSeats: handles[1],
                  };
                });
              }}
            />
          </div>
        </div>

        <div className={css.filtersWrapper}>
          <div className={css.filterWrapper}>{phvFilterElement}</div>
          <div className={css.filterWrapper}>{canDriveToMalaysiaFilterElement}</div>
        </div>

        <div className={css.footer}>
          <InlineTextButton rootClassName={css.resetAllButton} onClick={this.resetAll}>
            <FormattedMessage id={'SearchFiltersPanel.resetAll'} />
          </InlineTextButton>
          <InlineTextButton rootClassName={css.cancelButton} onClick={this.cancelFilters}>
            <FormattedMessage id={'SearchFiltersPanel.cancel'} />
          </InlineTextButton>
          <InlineTextButton
            className={css.applyButton}
            onClick={this.applyFilters}
            disabled={canApply === false}
          >
            <FormattedMessage id={'SearchFiltersPanel.apply'} />
          </InlineTextButton>
        </div>
        {canApply === false && (
          <div className={css.submitWarning}>
            <FormattedMessage id="enter_date_first" />
          </div>
        )}
      </div>
    );
  }
}

SearchFiltersPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  filterParamNames: [],
};

SearchFiltersPanelComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  onClosePanel: func.isRequired,
  filterParamNames: array,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersPanel = injectIntl(withRouter(SearchFiltersPanelComponent));

export default SearchFiltersPanel;
