import React from 'react';
import classNames from 'classnames';
import {
  getAddressDetailsByLatLong,
  getLatLngWithPlaceId,
  getPlaceDetails,
  getPlacePredictions,
  locationBounds,
} from '../../util/googleMaps';
import { types as sdkTypes } from '../../util/sdkLoader';
import { userLocation } from '../../util/maps';
import config from '../../config';
import css from './LocationAutocompleteInput.css';
import { getTimeZoneFromGoogle } from '../../util/googleCalendar';
import { EVENT_SEARCH_EXPERIMENT_A } from '../../util/gtm/gtmConstants';
import { LatLng } from 'sharetribe-flex-sdk/src/types';
import { getCachedPlaceDetails } from '../../util/locationCache';

export const CURRENT_LOCATION_ID = 'current-location';

const { LatLng: SDKLatLng, LatLngBounds: SDKLatLngBounds } = sdkTypes;

// When displaying data from the Google Maps Places API, and
// attribution is required next to the results.
// See: https://developers.google.com/places/web-service/policies#powered
export const GeocoderAttribution = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.poweredByGoogle, className);
  return <div className={classes} />;
};

/**
 * A forward geocoding (place name -> coordinates) implementation
 * using the Google Maps Places API.
 */
class GeocoderGoogleMaps {
  constructor() {
    this.sessionToken = null;
  }
  getSessionToken() {
    this.sessionToken =
      this.sessionToken || new window.google.maps.places.AutocompleteSessionToken();
    return this.sessionToken;
  }

  // Public API
  //

  /**
   * Search places with the given name.
   *
   * @param {String} search query for place names
   *
   * @return {Promise<{ search: String, predictions: Array<Object>}>}
   * results of the geocoding, should have the original search query
   * and an array of predictions. The format of the predictions is
   * only relevant for the `getPlaceDetails` function below.
   */
  getPlacePredictions(search) {
    const limitCountriesMaybe = config.maps.search.countryLimit
      ? {
          componentRestrictions: {
            country: config.maps.search.countryLimit,
          },
        }
      : {};

    return getPlacePredictions(search, this.getSessionToken(), limitCountriesMaybe).then(
      results => {
        return {
          search,
          predictions: results.predictions,
        };
      }
    );
  }

  /**
   * Get the ID of the given prediction.
   */
  getPredictionId(prediction) {
    if (prediction.predictionPlace) {
      // default prediction defined above
      return prediction.id;
    }
    // prediction from Google Maps Places API
    return prediction.place_id;
  }

  /**
   * Get the address text of the given prediction.
   */
  getPredictionAddress(prediction) {
    if (prediction.predictionPlace) {
      // default prediction defined above
      return prediction.predictionPlace.address;
    }
    // prediction from Google Maps Places API
    return prediction.description;
  }

  /**
   * Fetch or read place details from the selected prediction.
   *
   * @param {Object} prediction selected prediction object
   *
   * @return {Promise<util.propTypes.place>} a place object
   */
  /**
 * Fetch or read place details from the selected prediction.
 *
 * @param {Object} prediction selected prediction object
 * @param {boolean} isSearch flag to indicate if the call is from a search query
 *
 * @return {Promise<util.propTypes.place>} a place object
 */
getPlaceDetails = async (prediction, isSearch = false) => {
  // ✅ 1. Check if user selected "Current Location"
  console.log("Check if user selected Current Location", prediction, isSearch)
  if (prediction.id === CURRENT_LOCATION_ID) {
      return userLocation()
          .then(async position => {
              console.log('pos', position);
              const latlng = new LatLng(position.coords.latitude, position.coords.longitude);
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;

              // ✅ 2. Avoid unnecessary API call: Only fetch details if needed
              const result = lat && lng && (await getAddressDetailsByLatLong(lat, lng));
              return {
                  address: 'Current location',
                  origin: latlng,
                  bounds: locationBounds(latlng, config.maps.search.currentLocationBoundsDistance),
              };
          })
          .catch(err => {
              throw err;
          });
  }

  // ✅ 7. If prediction already has place details, return it directly
  if (prediction.predictionPlace) {
      return Promise.resolve(prediction.predictionPlace);
  }

  // // ✅ 3. Use Cached Place Details (Prevents repeated API calls)
  const cachedDetails = await getCachedPlaceDetails(prediction.place_id);
  console.log("cached placed data and for prediction id", prediction, cachedDetails);
  if (cachedDetails) {
      console.log(`✅ Cache hit for place_id: ${prediction.place_id}`);
       // ✅ Convert origin to LatLng instance
       if (cachedDetails.origin && !(cachedDetails.origin instanceof SDKLatLng)) {
        cachedDetails.origin = new SDKLatLng(cachedDetails.origin.lat, cachedDetails.origin.lng);
       }
      return cachedDetails;
  }

  // ✅ 4. Try to extract Lat/Lng first before making API call
  const latLngData = prediction.place_id ? await getLatLngWithPlaceId(prediction.place_id) : null;
  const lat = prediction.origin ? prediction.origin.lat : latLngData?.lat;
  const lng = prediction.origin ? prediction.origin.lng : latLngData?.lng;

  console.log('Post:', prediction, lat, lng);

  if (!lat || !lng) {
      console.warn(`⚠️ Missing lat/lng for place_id: ${prediction.place_id}`);
      return null;
  }

  // ✅ 5. Fetch Address Details (Cache This Response for Future Queries)
  const addressDetails = await getAddressDetailsByLatLong(lat, lng);
  console.log("Getting cached details from lat long")
  if (!addressDetails) {
      console.error(`❌ Failed to fetch address details for lat/lng: ${lat}, ${lng}`);
      return null;
  }

  // ✅ 6. Determine distance threshold (Based on state)
  const distance = addressDetails.state === 'New South Wales'
      ? 15000
      : addressDetails.state === 'Victoria'
      ? 20000
      : 25000;


  // ✅ 8. Fetch Details from Google API (Only if necessary)
  return await getPlaceDetails(prediction.place_id, this.getSessionToken(), isSearch).then(
      async place => {
          console.log("PLace and timezone ", place);
          const timezone = await getTimeZoneFromGoogle(place.origin);
          const finalPlace = { ...place, timezone };
          this.sessionToken = null;
          console.log("without cached placed data and", finalPlace);
          // ✅ 9. Store Fetched Place Details in Cache
          // setCachedPlaceDetails(prediction.place_id, finalPlace);
          return finalPlace;
      }
  );
};

}

export default GeocoderGoogleMaps;
