/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import BLandingCss from './LandingSearchBar.css';
import isEqual from 'lodash/isEqual';
// import bannerImage from '../../assets/b-landing/2024/banner-image.png';
// import bannerImage2x from '../../assets/b-landing/2024/banner-image@2x.png';
// import bannerImageMobile from '../../assets/b-landing/2024/banner-mobile-image.jpg';
// import bannerImageMobile2x from '../../assets/b-landing/2024/banner-mobile-image@2x.jpg';
import { createResourceLocatorString } from '../../util/routes';
import { emitter, Experiment, Variant } from '@marvelapp/react-ab-test';
import routeConfiguration from '../../routeConfiguration';
import {
  getPUSHTELLSearchExperimentPage,
  getSearchedPlaces,
  saveSearchedPlacesMobile,
} from '../../util/emailNotify';
import { encodeLatLngBounds, parse } from '../../util/urlHelpers';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { LocationAutocompleteInput } from '../../components';
import { Field, Form } from 'react-final-form';
import GeocoderGoogleMaps, {
  CURRENT_LOCATION_ID,
} from '../../components/LocationAutocompleteInput/GeocoderGoogleMaps';
import SearchIconNew from '../../components/Topbar/SearchIconNew';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import {
  initiateEventFromExpertPage,
  initiateEventFromListing,
  initiateExperimentEventFromListing,
} from '../../util/gtm/gtmHelpers';
import {
  EVENT_SEARCH_EXPERIMENT_A,
  EVENT_SEARCH_EXPERIMENT_B,
  EVENT_SEARCH_PERFORMED,
} from '../../util/gtm/gtmConstants';
import { useDispatch, useSelector } from 'react-redux';
import { gSend, GTAG_ACTIONS } from '../../util/gtag';
import AlertBox from '../../components/AlertBox/AlertBox';

const DEFAULT_BOUNDS = {
  _sdkType: 'LatLngBounds',
  ne: {
    _sdkType: 'LatLng',
    lat: -33.5781409,
    lng: 151.3430209,
  },
  sw: {
    _sdkType: 'LatLng',
    lat: -34.118347,
    lng: 150.5209286,
  },
};
export const defaultPredictions = (config.maps.search.suggestCurrentLocation
  ? [{ id: CURRENT_LOCATION_ID, predictionPlace: {} }]
  : []
).concat(config.maps.search.defaults);

export const defaultPredictionsExcludingCurrentLocation = config.maps.search.defaults;
export const defaultPredictionsWithoutCurrentLocation = [
  { id: CURRENT_LOCATION_ID, predictionPlace: {} },
];
const ImageComponent = ({ src1x, src2x, children, alt }) => {
  return (
    <div className={BLandingCss.bannerSlideItem}>
      <img
        alt="87"
        srcSet={`${src1x} 1x, ${src2x} 2x`}
        className={BLandingCss.imageComponentImageBLanding}
      />
      <div className={BLandingCss.imageComponentContentBLanding}>{children}</div>
    </div>
  );
};

const SectionSearchBanner = memo(
  ({ isMobileLayout, onSelectedPrediction, onSelectionPostalAddress, isSearchedFocused, ...props }) => {
    const sliderConfig = {
      autoplay: true,
      dots: false,
      arrows: false,
      infinite: true,
      autoplaySpeed: 5000,
    };
    const geocoder = useRef(new GeocoderGoogleMaps()).current;
    let searchDropdown = useRef(null);
    const [predictions, setPredictions] = useState([]);
    const [isUserSearching, setIsUserSearching] = useState(false);
    const [locationLoader, setLocationLoader] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [isHighlighted, setIsHighlighted] = useState(false);
    let userSearchHistory = getSearchedPlaces();
    const currentSearchParams = useSelector(state => state.UI.searchParams);
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch(null);
    let EXPERIMENT_DATA = {};

    const [alert, setAlert] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
      document.addEventListener('click', handleOutsideClick, true);
      if (isSearchedFocused) {
        setIsFocused(true);
        
        setIsHighlighted(false);
        
        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          setIsHighlighted(true);
          
          const timer = setTimeout(() => {
            setIsHighlighted(false);
          }, 1000);
          return () => clearTimeout(timer);
        }, 0);
      }
    }, [isSearchedFocused]);

    if (userSearchHistory && userSearchHistory.length) {
      userSearchHistory = userSearchHistory.filter(searchPlace => {
        return searchPlace['description'] && geocoder.getPredictionId(searchPlace);
      });
    }

    const handleShowMenu = () => {
      if (isMobileLayout) {
        const { search } = location;
        const url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
          address: 'Sydney',
          bounds: '-33.5781409%2C151.3430209%2C-34.118347%2C150.5209286',
          searchStep: 'location',
          mapSearch: false,
          timezone: location && location.timezone,
          origin: location && location.origin,
          ...parse(search),
        });
        history.push(url);
      }
    };
    // const handleExperiment = () => {
    //   try {
    //     let exp = getPUSHTELLSearchExperimentPage();
    //     if (!exp) {
    //       emitter.defineVariants(
    //         'Search Experiment',
    //         [EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B],
    //         [50, 50]
    //       );
    //       emitter.addPlayListener((experimentName, variantName) => {
    //         console.log('Experiment', experimentName, variantName);
    //         EXPERIMENT_DATA.experimentName = experimentName;
    //         EXPERIMENT_DATA.variantName = variantName;
    //       });
    //     }

    //     if (exp) {
    //       EXPERIMENT_DATA = {
    //         experimentName: 'Search Experiment',
    //         variantName: exp,
    //       };
    //     }
    //     if (EXPERIMENT_DATA) {
    //       initiateExperimentEventFromListing({
    //         props: props,
    //         event: 'dl_' + 'search_experiment' + exp,
    //         experimentData: EXPERIMENT_DATA,
    //       });
    //     }
    //     // if (typeof localStorage !== 'undefined') {
    //     //   let exp = localStorage.getItem('searchExperiment');
    //     //   if (!exp) {
    //     //     const items = [EVENT_SEARCH_EXPERIMENT_A, EVENT_SEARCH_EXPERIMENT_B];
    //     //     const item = items[Math.floor(Math.random() * items.length)];
    //     //     let expObject = {
    //     //       name: 'Search Experiment',
    //     //       variant: item,
    //     //     };
    //     //     localStorage.setItem('searchExperiment', JSON.stringify(expObject));
    //     //     initiateEventFromExpertPage({
    //     //       props: { isMobileLayout, onSelectedPrediction, onSelectionPostalAddress },
    //     //       event: expObject.variant,
    //     //       isHost: false,
    //     //     });
    //     //   } else {
    //     //     initiateEventFromExpertPage({
    //     //       props: { isMobileLayout, onSelectedPrediction, onSelectionPostalAddress },
    //     //       event: JSON.parse(exp).variant,
    //     //       isHost: false,
    //     //     });
    //     //   }
    //     // } else {
    //     //   initiateEventFromExpertPage({
    //     //     props: { isMobileLayout, onSelectedPrediction, onSelectionPostalAddress },
    //     //     event: EVENT_SEARCH_EXPERIMENT_B,
    //     //     isHost: false,
    //     //   });
    //     // }
    //   } catch (error) {
    //     console.log('LocalStorageError', error);
    //   }
    // };

    // Touch devices need to be able to distinguish touches for scrolling and touches to tap

    const handleSelectPrediction = async prediction => {
      console.log('PREDICTIONSSSSSSSSSS', prediction);
      const DEFAULT_TIME_AND_DATES = {
        location: {},
        selectedDates: {
          startDate: moment(),
          endDate: moment(),
        },
        selectedTimes: {
          startTime: moment(),
          endTime: moment(),
        },
        isDefault: true,
      };
      if (!isMobileLayout) {
        setLocationLoader(true);
        if (prediction && prediction.id && prediction.id === CURRENT_LOCATION_ID) {
          await navigator.permissions
            .query({
              name: 'geolocation',
            })
            .then(function(result) {
              if (result.state == 'granted') {
                setAlert(false);
                console.log(result.state);
              } else if (result.state == 'prompt') {
                console.log(result.state);
              } else if (result.state == 'denied') {
                console.log('result', result);
                setLocationLoader(false);
                setAlert(true);
              }
              result.onchange = function() {
                console.log('onchange', result.state);
              };
            });
        }
        geocoder
          .getPlaceDetails(
            prediction.predictionPlace
              ? Object.keys(prediction.predictionPlace).length > 0
                ? prediction.predictionPlace
                : prediction
              : prediction,
            true
          )
          .then(location => {
            console.log('Locationssss', location);
            let url = '';
            const { startDate, endDate } = DEFAULT_TIME_AND_DATES.selectedDates;
            const { startTime, endTime } = DEFAULT_TIME_AND_DATES.selectedTimes;
            let updatedStartDate, updatedEndDate, updatedStartTime, updatedEndTime;
            updatedStartDate = startDate.add(1, 'day').set({ hour: 9, minute: 0 });
            updatedEndDate = endDate.add(4, 'day').set({ hour: 9, minute: 0 });
            updatedStartTime = startTime.set({ hour: 9, minute: 0 });
            updatedEndTime = endTime.set({ hour: 9, minute: 0 });
            url = createResourceLocatorString('SearchPage', routeConfiguration(), null, {
              bounds: encodeLatLngBounds(
                location && location.bounds ? location.bounds : DEFAULT_BOUNDS
              ),
              dates: `${updatedStartDate.format('YYYY-MM-DD')},${updatedEndDate.format(
                'YYYY-MM-DD'
              )}`,
              hours: `${updatedStartTime.format('HH:mm')},${updatedEndTime.format('HH:mm')}`,
              searchType: 'daily',
              searchStep: 'preview',
              origin: location && location.origin,
              address: location && location.address,
              timezone: location && location.timezone,
              mapSearch: false,
            });

            onSelectedPrediction(location);
            onSelectionPostalAddress && onSelectionPostalAddress(location);
            initiateEventFromListing({
              props: {
                searchParams: {
                  ...currentSearchParams,
                  bounds: location && location.bounds ? location.bounds : DEFAULT_BOUNDS,
                },
              },
              event: EVENT_SEARCH_PERFORMED,
            });
            gSend(GTAG_ACTIONS.ACTION_SEARCH, {
              search_location: location && location.address,
              search_pickupdate: `${startDate.format('YYYY-MM-DD')}`,
              search_pickuptime: `${startTime.format('HH:mm')}`,
              search_dropoffdate: `${endDate.format('YYYY-MM-DD')}`,
              search_dropofftime: `${endTime.format('HH:mm')}`,
            });
            if (prediction && prediction.predictionPlace) {
              saveSearchedPlacesMobile(prediction.predictionPlace);
            } else {
              saveSearchedPlacesMobile(prediction);
            }
            setLocationLoader(false);
            history.push(url);
          })
          .catch(err => {
            if (err.code === 1 || err.code === 2) {
              setAlert(true);
              setLocationLoader(false);
            }
          });
      }
    };

    const handleOutsideClick = e => {
      if (searchDropdown && searchDropdown.current && !searchDropdown.current.contains(e.target)) {
        setIsFocused(false);
      } else {
        setIsFocused(true);
        document.removeEventListener('click', handleOutsideClick, false);
      }
    };

    const _handleSearchType = p => {
      if (p && p.value !== '') {
        setIsUserSearching(true);
      } else {
        setIsUserSearching(false);
      }
    };

    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const item = (prediction, index) => {
      const isHighlighted = index === highlightedIndex;
      const predictionId = geocoder.getPredictionId(prediction);
      return (
        <li
          className={classNames(
            isHighlighted ? BLandingCss.highlighted : null,
            BLandingCss.prediction
          )}
          key={predictionId}
          onClick={() => handleSelectPrediction(prediction)}
        >
          {predictionId === CURRENT_LOCATION_ID ? (
            <span className={BLandingCss.currentLocation}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <defs></defs>
                <path
                  fill="#026786"
                  className="a"
                  d="M13.9.111.9,6.108a1.515,1.515,0,0,0,.6,2.9H7v5.5a1.515,1.515,0,0,0,2.9.6l6-12.993a1.559,1.559,0,0,0-2-2Z"
                  transform="translate(-0.007 -0.001)"
                />
              </svg>
              <FormattedMessage id="LocationAutocompleteInput.currentLocation" />
            </span>
          ) : (
            geocoder.getPredictionAddress(prediction)
          )}
        </li>
      );
    };
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    // let exp = getPUSHTELLSearchExperimentPage();

    return (
      <>
        {alert ? (
          <AlertBox
            title="Location error"
            message="You have not given location access on the browser so we cannot fetch your currecnt location. Either provide location permission or enter your location manually."
            type="error"
          />
        ) : null}
        <div className={BLandingCss.searchBarOuter} ref={searchDropdown} onClick={handleShowMenu}>
          {/* {!exp ? 
               <Experiment name="Search Experiment">
                  <Variant name={EVENT_SEARCH_EXPERIMENT_A}>
                   
                  </Variant>
                  <Variant name={EVENT_SEARCH_EXPERIMENT_B}>
                   
                  </Variant>
              </Experiment>: ''} */}
          <Form
            onSubmit={() => {}}
            render={() => {
              return (
                <Field name="location">
                  {props => (
                    <LocationAutocompleteInput
                      {...props}
                      autoFocus={false}
                      // focusInput={handleExperiment}
                      className={BLandingCss.fieldLocationBLanding}
                      iconClassName={BLandingCss.hideSearchIconBLanding}
                      inputClassName={BLandingCss.inputClassNameBLanding}
                      predictionsClassName={BLandingCss.searchPredictionsBLanding}
                      onPredictionChange={setPredictions}
                      placeholder="Enter your location to view cars"
                      inputRef={(el) => {
                        inputRef.current = el;
                        _handleSearchType(el);
                      }}
                    >
                      <div className={BLandingCss.searchIconWrapper}>
                        <SearchIconNew rootClassName={BLandingCss.searchIcon} />
                      </div>
                    </LocationAutocompleteInput>
                  )}
                </Field>
              );
            }}
          />
          <div className={BLandingCss.searchIconWrapper}>
            {locationLoader ? (
              <div className={BLandingCss.locationLoader}>
                <span></span>
              </div>
            ) : (
              <SearchIconNew rootClassName={BLandingCss.searchIcon} />
            )}
          </div>
          <div
            className={classNames(
              BLandingCss.predictionsDropDown,
              (isFocused || isSearchedFocused) && !isMobileLayout && BLandingCss.isVisible,
              isHighlighted && BLandingCss.vibrate
            )}
          >
            <ul className={BLandingCss.predictionsCurrentLocations}>
              {defaultPredictionsWithoutCurrentLocation &&
              predictions.length > 0 &&
              predictions[0]['description']
                ? predictions
                    .filter(item => {
                      const desc = (item['description'] || '').toLowerCase();
                      return desc.includes('australia');
                    })
                    .map(item)
                : defaultPredictionsWithoutCurrentLocation.map((value, index) =>
                    item(value, index)
                  )}
            </ul>
            {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
              <div className={BLandingCss.searchLabels}>
                <FormattedMessage id="LocationAutocompleteInput.recentSearches" />
              </div>
            ) : null}
            {!isUserSearching && userSearchHistory && userSearchHistory.length ? (
              <ul className={BLandingCss.predictions}>
                {userSearchHistory &&
                userSearchHistory.length > 0 &&
                userSearchHistory[0]['description']
                  ? userSearchHistory.map((value, index) => item(value, index))
                  : userSearchHistory.map((value, index) => item(value, index))}
              </ul>
            ) : null}
            {predictions && predictions.length > 0 && predictions[0]['description'] ? null : (
              <div className={BLandingCss.searchLabels}>
                <FormattedMessage id="LocationAutocompleteInput.searchSuggestion" />
              </div>
            )}

            {userSearchHistory && userSearchHistory.length ? (
              <ul className={BLandingCss.predictions}>
                {predictions && predictions.length > 0 && predictions[0]['description']
                  ? null
                  : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
              </ul>
            ) : (
              <ul className={BLandingCss.predictions}>
                {predictions && predictions.length > 0 && predictions[0]['description']
                  ? null
                  : defaultPredictionsExcludingCurrentLocation.map((value, index) =>
                      item(value, index)
                    )}
              </ul>
            )}
            {/* <GeocoderAttribution className={BLandingCss.attributionClassName} /> */}
          </div>
        </div>
      </>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default SectionSearchBanner;
