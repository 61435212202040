import React, { Fragment, Component } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import moment from 'moment';

import { FieldDateRangeController, FieldSelect } from '../../components';
import {
  dateIsAfter, getMonthStartInTimeZone,
  isInRange,
  monthIdStringInTimeZone,
  nextMonthFn,
  prevMonthFn, resetToStartOfDay,
} from '../../util/dates';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { get } from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';
import { addDays, isSameDay, isWithinInterval, startOfDay, subSeconds } from 'date-fns';
import css from '../BookingTimeForm/BookingTimeForm.css';

const MAX_TIME_SLOTS_RANGE = 360;
const TODAY = new Date();
const endOfRange = (date, timeZone) => {
  return resetToStartOfDay(date, timeZone, MAX_TIME_SLOTS_RANGE - 1);
};


// Constants for calculating day width (aka table cell dimensions)
const TABLE_BORDER = 2;
const TABLE_COLUMNS = 7;
const MIN_CONTENT_WIDTH = 272;
const MIN_CELL_WIDTH = Math.floor(MIN_CONTENT_WIDTH / TABLE_COLUMNS); // 38
const MAX_CONTENT_WIDTH_DESKTOP = 603;
const MAX_CELL_WIDTH_DESKTOP = Math.floor(MAX_CONTENT_WIDTH_DESKTOP / TABLE_COLUMNS); // 108
const VIEWPORT_LARGE = 1024;

const dayWidth = (wrapperWidth, windowWith) => {
  if (windowWith >= VIEWPORT_LARGE) {
    // NOTE: viewportLarge has a layout with sidebar.
    // In that layout 30% is reserved for paddings and 282 px goes to sidebar and gutter.
    const width = windowWith * 0.7 - 282;
    return width > MAX_CONTENT_WIDTH_DESKTOP
      ? MAX_CELL_WIDTH_DESKTOP
      : Math.floor((width - TABLE_BORDER) / TABLE_COLUMNS);
  } else {
    // return wrapperWidth > MIN_CONTENT_WIDTH
    //   ? Math.floor((wrapperWidth - TABLE_BORDER) / TABLE_COLUMNS)
    //   : MIN_CELL_WIDTH;
    return (window.innerWidth - 60) / 7;
  }
};

export class BookingRangeSelectorComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      currentMonth: getMonthStartInTimeZone(TODAY, this.props.localTimeZone),
    };

    this.plainControllerRef = null;
    this.form = null;
  }

  handleDateChange = ({ values, active }) => {
    const { startDate, endDate } = values.dates || {};
    if (startDate || endDate) {
      // Call the parent's callback function, passing up the new dates
      this.props.onDatesChange({ startDate, endDate });
      this.setState({ startDate, endDate });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.initialValues.dates && !nextProps.initialValues.dates) {
      if (this.form) {
        this.form.change('times', {});
      }
      if (this.plainControllerRef && this.plainControllerRef.onReset) {
        this.plainControllerRef.onReset(null, null);
      }
    }
  }

  render() {
    const {
      className,
      rootClassName,
      initialValues: initialValuesRaw,
      id,
      contentPlacementOffset,
      onSubmit,
      urlParam,
      intl,
      onFetchTimeSlots,
      timeZone,
      localTimeZone,
      location,
      listingId,
      monthlyTimeSlots,
      transaction,
      allTimeslots,
      rawInitialValues,
      listing,
      boundsMergedTimeSlots,
      bookingRangeRef,
      updatedStartDate,
      updatedEndDate,
      updatedStartTime,
      availableStartTimes,
      availableEndTimes,
      onBookingStartTimeChange,
      onBookingEndTimeChange,
      ...rest
    } = this.props;

    const { clientWidth: width } = this.dayPickerWrapper || { clientWidth: 0 };
    const hasWindow = typeof window !== 'undefined';
    const windowWidth = hasWindow ? window.innerWidth : 0;
    const daySize = dayWidth(width, windowWidth);

    const noticeHrs = get(listing, 'attributes.publicData.paddingHours', 0);
    console.log('notice hours', noticeHrs);

    const startTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.pickUpTime' });
    const endTimeLabel = intl.formatMessage({ id: 'FieldDateTimeInput.dropoffTime' });

    const isDayBlocked =
      allTimeslots.length > 0
        ? day => {
            const dayTimeStamp = day.valueOf();
            const availableTimeSlotsOnCalendar = allTimeslots;
            const currentDate = utcToZonedTime(new Date(), timeZone);

            const bookingStartDate = this.state.startDate ? moment(this.state.startDate) : null;

            if (bookingStartDate) {
              const selectedDate = moment(bookingStartDate).format('YYYY-MM-DD');

              let availableSlot = null;

              for (const slot of availableTimeSlotsOnCalendar) {
                const startUTC = slot.attributes.start;
                const endUTC = slot.attributes.end;
                const start = moment(startUTC).format('YYYY-MM-DD');
                const end = moment(endUTC).format('YYYY-MM-DD');
                if (selectedDate === start) {
                  // console.log("Match with start date");
                  const isEndDay = moment(endUTC).format('HH:mm:ss') === '00:00:00';
                  const previousDay = moment(endUTC)
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD');
                  if (isEndDay) {
                    availableSlot = {
                      ...slot,
                      attributes: {
                        ...slot.attributes,
                        start: selectedDate,
                        end: moment(previousDay).endOf('day').toDate(),
                      },
                    };
                  }
                  else {
                    availableSlot = slot;
                  }
                  break;
                }
                // If selected date matches the end date, merge with the next slot
                if (selectedDate === end) {
                  let lastEndDate = null;
                  for (
                    let i = availableTimeSlotsOnCalendar.indexOf(slot) + 1;
                    i < availableTimeSlotsOnCalendar.length;
                    i++
                  ) {
                    const nextSlot = availableTimeSlotsOnCalendar[i];
                    const nextSlotStart = moment(nextSlot.attributes.start).format('YYYY-MM-DD');
                    if (selectedDate === nextSlotStart) {
                      lastEndDate = nextSlot.attributes.end; // Update last end date
                    } else {
                      break; // Stop loop when next slot start date is different
                    }
                  }
                  const isLastEndDay = moment(lastEndDate).format('HH:mm:ss') === '00:00:00';
                  if (lastEndDate && isLastEndDay) {
                    lastEndDate = moment(lastEndDate)
                      .subtract(1, 'days')
                      .endOf('day')
                      .toDate();
                  }
                  if (lastEndDate) {
                    availableSlot = {
                      ...slot,
                      attributes: {
                        ...slot.attributes,
                        start: new Date(selectedDate), // Ensure start is from selectedDate
                        end: lastEndDate,
                      },
                    };
                  } else {
                    availableSlot = {
                      ...slot,
                      attributes: {
                        ...slot.attributes,
                        start: new Date(selectedDate), // Ensure start is from selectedDate
                      },
                    };
                  }
                  break;
                }
                if (moment(selectedDate).isBetween(start, end, null, '[]')) {
                  // console.log("Match with range");
                  // if end day time is 00:00:00 want true otherwise false
                  const isEndDay = moment(endUTC).format('HH:mm:ss') === '00:00:00';
                  // change previous date and time set to 23:59:59
                  const previousDay = moment(endUTC)
                    .subtract(1, 'days')
                    .format('YYYY-MM-DD');

                  console.log('Is End Day:', isEndDay);
                  if (isEndDay) {
                    availableSlot = {
                      ...slot,
                      attributes: {
                        ...slot.attributes,
                        start: selectedDate,
                        end: moment(previousDay)
                          .endOf('day')
                          .toDate(),
                      },
                    };
                  } else {
                    availableSlot = {
                      ...slot,
                      attributes: {
                        ...slot.attributes,
                        start: selectedDate, // Ensure start is from selectedDate
                      },
                    };
                  }
                  break;
                }
              }

              const arr = [];
              arr.push(availableSlot);
              if (arr.length > 0) {
                const availableTimeSlots = arr.filter(slot => {
                  const slotStart = new Date(slot.attributes.start);
                  const slotEnd = new Date(slot.attributes.end);
                  return (
                    moment(slotStart).isSame(dayTimeStamp, 'day') ||
                    (moment(slotStart).isBefore(dayTimeStamp, 'day') &&
                      moment(slotEnd).isAfter(dayTimeStamp, 'day')) ||
                    moment(slotEnd).isSame(dayTimeStamp, 'day')
                  );
                });
                return !availableTimeSlots.length;
              }
            }

            if (!availableTimeSlotsOnCalendar || availableTimeSlotsOnCalendar.length === 0) {
              return false;
            }

            const startOfDayTimestamp = startOfDay(
              utcToZonedTime(new Date(day.valueOf()), timeZone)
            );

            const endOfDayTimestamp = subSeconds(addDays(startOfDayTimestamp, 1), 1);
            // Check if any available time slot falls within or covers the entire day
            return !availableTimeSlotsOnCalendar.some(slot => {
              const slotStart = utcToZonedTime(slot.attributes.start, timeZone);
              const slotEnd = subSeconds(utcToZonedTime(slot.attributes.end, timeZone), 1);

              const is1130PMOnCurrentDay =
                isSameDay(slotStart, currentDate) &&
                slotStart.getHours() === 23 &&
                slotStart.getMinutes() === 30;

              if (is1130PMOnCurrentDay) {
                return false;
              }

              return (
                // Case 1: The slot completely covers the entire day (from start to end)
                (slotStart <= startOfDayTimestamp && slotEnd >= endOfDayTimestamp) ||
                // Case 2: The slot starts within the given day
                isWithinInterval(slotStart, {
                  start: startOfDayTimestamp,
                  end: endOfDayTimestamp,
                }) ||
                // Case 3: The slot ends within the given day
                isWithinInterval(slotEnd, {
                  start: startOfDayTimestamp,
                  end: endOfDayTimestamp,
                })
              );
            });
          }
        : () => false;

    const onMonthClick = monthFn => {
      this.setState(
        prevState => ({ currentMonth: monthFn(prevState.currentMonth, localTimeZone) }),
        () => {
          // Callback function after month has been updated.
          // react-dates component has next and previous months ready (but inivisible).
          // we try to populate those invisible months before user advances there.
          fetchMonthData(monthFn(this.state.currentMonth, timeZone));

          // If previous fetch for month data failed, try again.
          const monthId = monthIdStringInTimeZone(this.state.currentMonth, timeZone);

          const currentMonthData = this.props.monthlyTimeSlots[monthId];
          // if redirect from the serach page with preselected dates
          if (
            (currentMonthData && currentMonthData.fetchTimeSlotsError) ||
            (location && location.state)
          ) {
            fetchMonthData(this.state.currentMonth, timeZone);
          }
        }
      );
    };

    const fetchMonthData = date => {
      const endOfRangeDate = endOfRange(TODAY, timeZone);

      // Don't fetch timeSlots for past months or too far in the future
      if (isInRange(date, TODAY, endOfRangeDate)) {
        // Use "today", if the first day of given month is in the past
        const start = dateIsAfter(TODAY, date) ? TODAY : date;

        // Use endOfRangeDate, if the first day of the next month is too far in the future
        const nextMonthDate = nextMonthFn(date, timeZone);
        const end = dateIsAfter(nextMonthDate, endOfRangeDate)
          ? resetToStartOfDay(endOfRangeDate, timeZone, 0)
          : nextMonthDate;

        // Fetch time slots for given time range
        onFetchTimeSlots(listingId, start, end, timeZone);
      }
    };

    const handleClearValues = () => {
      if (this.plainControllerRef && this.plainControllerRef.onReset) {
        this.plainControllerRef.onReset(null, null);
      }
      this.setState({ startDate: null, endDate: null });
      this.props.onDatesChange({ startDate: null, endDate:null });
    };

    return (
      <div>
        <FinalForm
          onSubmit={values => {
            this.handleDateChange(values);
          }}
          render={({ form }) => (
            <form onSubmit={form.submit}>
              <Fragment>
                <FormSpy subscription={{ values: true }} onChange={this.handleDateChange} />
                <FieldDateRangeController
                  name="dates"
                  controllerRef={node => {
                    this.plainControllerRef = node;
                    if (bookingRangeRef) {
                      bookingRangeRef.current = node;
                    }
                  }}
                  isDayBlocked={isDayBlocked}
                  onPrevMonthClick={() => onMonthClick(prevMonthFn)}
                  onNextMonthClick={() => onMonthClick(nextMonthFn)}
                  onChangeByStartDate={true}
                  daySize={daySize}
                />
              </Fragment>
            </form>
          )}
        >
        </FinalForm>
        {updatedStartDate && updatedEndDate && (
          <div className={css.bookingDatesSelectors}>
            <FieldSelect
              labelClassName={css.label}
              name="bookingStartTime"
              id="bookingTimeSelect"
              className={css.fieldSelect}
              selectClassName={css.select}
              label={startTimeLabel}
              onChange={onBookingStartTimeChange}
            >
              <option value="">Select pickup time</option>
              {availableStartTimes.map((p, index) => (
                <option key={`bookingStartTime_${p.timestamp}`} value={p.timestamp}>
                  {p.timeOfDay}
                </option>
              ))}
            </FieldSelect>

            <FieldSelect
              labelClassName={css.label}
              name="bookingEndTime"
              id="bookingEndTimeSelect"
              className={css.fieldSelect}
              selectClassName={css.select}
              label={endTimeLabel}
              disabled={!updatedStartTime}
              onChange={onBookingEndTimeChange}
            >
              <option value="">Select dropoff time</option>
              {availableEndTimes.map((p, index) => (
                <option key={`bookingEndTime_${p.timestamp}`} value={p.timestamp}>
                  {p.timeOfDay}
                </option>
              ))}
            </FieldSelect>
          </div>
        )}
        <div className={css.buttonClass} onClick={handleClearValues}>
          <div className={css.resetButton}>Reset selected dates</div>
        </div>
      </div>
    );
  }
}

BookingRangeSelectorComponent.defaultProps = {
  rootClassName: null,
  className: null,
  liveEdit: false,
  initialValues: null,
  contentPlacementOffset: 0,
};

BookingRangeSelectorComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  liveEdit: bool,
  urlParam: string.isRequired,
  onSubmit: func.isRequired,
  initialValues: object,
  onDatesChange: func.isRequired,
  contentPlacementOffset: number,

  // form injectIntl
  intl: intlShape.isRequired,
};

const BookingRangeSelector = injectIntl(BookingRangeSelectorComponent);

export default BookingRangeSelector;
