import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser, ensureHostVerified } from '../../util/data';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { savePersonalDetails } from './PersonalDetailsPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { PersonalDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import css from './PersonalDetailsPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { has } from 'lodash';

const { LatLng } = sdkTypes;

export const PersonalDetailsPageComponent = props => {
  const {
    currentUser,
    scrollingDisabled,
    intl,
    onSubmitAddressDetailsForm,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    connectCalendarTabVisibility,
    fetchCalendarVisibility,
    isVerified,
    hasDateOfBirth,
    hasLocation
  } = props;

  useEffect(() => {
    fetchCalendarVisibility();
  }, []);

  let tabs = [...config.custom.tabs];
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  tabs = tabs.map(tab => ({ ...tab })).filter((tab) => {
    if (!(ensuredCurrentUser && ensureHostVerified(ensuredCurrentUser))) {
      if (tab.linkProps.name === "MonthlyStatementPage") {
        return false;
      }
    }
    if (tab.linkProps.name === "SyncCalenderPage") {
      return Boolean(connectCalendarTabVisibility);
    }
    return true;
  });
  const currentTab = tabs.find((tab) => tab.linkProps.name === "PersonalDetailsPage");
  if (currentTab) {
    currentTab.selected = true;
  }

  const protectedData = ensuredCurrentUser.attributes.profile.protectedData || {};
  const { dateOfBirth, location } = protectedData;
  const { guestIdentityVerificationStatus, hostIdentityVerificationStatus } = ensuredCurrentUser.attributes.profile.publicData || {};

  const initialValues = {
    dateOfBirth,
    location: location ? location.selectedPlace.address : null,
  };

  isVerified = guestIdentityVerificationStatus === 'confirmed' || hostIdentityVerificationStatus === 'confirmed';
  hasDateOfBirth = !!dateOfBirth;
  hasLocation = !!location;
console.log(hasLocation, hasDateOfBirth,"xbsjxbsnbxjsbxjsbxjbs");
  const addressInfoForm = ensuredCurrentUser.id ? (
    <PersonalDetailsForm
      className={css.form}
      initialValues={initialValues}
      currentUser={currentUser}
      inProgress={saveAddressDetailsInProgress}
      ready={saveAddressDetailsSuccess}
      saveAddressDetailsError={saveAddressDetailsError}
      onSubmit={values => {
        const { dateOfBirth, location } = values;
        const updatedValues = {
          dateOfBirth,
          location,
        };
        onSubmitAddressDetailsForm(updatedValues);
      }}
      isVerified={isVerified}
      hasDateOfBirth={hasDateOfBirth}
      hasLocation={hasLocation}

    />
  ) : null;

  const title = intl.formatMessage({ id: 'PersonalDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled} className={css.root}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="PersonalDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="PersonalDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={ensuredCurrentUser} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              {/* <FormattedMessage id="AddressDetailsPage.heading" /> */}
            </h1>
            
            {addressInfoForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

PersonalDetailsPageComponent.defaultProps = {
  currentUser: null,
  saveAddressDetailsSuccess: false,
  saveAddressDetailsError: false,
  isVerified: false, // Add isVerified default prop
};

const { bool } = PropTypes;

PersonalDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
  isVerified: bool.isRequired, // Add isVerified prop type
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const {
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
  } = state.AddressDetailsPage;
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitAddressDetailsForm: values => dispatch(savePersonalDetails(values)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const PersonalDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(PersonalDetailsPageComponent);

PersonalDetailsPage.loadData = () => {
  return fetchCurrentUser();
};

export default PersonalDetailsPage;