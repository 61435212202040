import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { withViewport } from '../../util/contextHelpers';
import {
  isScrollingDisabled,
  manageDisableScrolling,
  manageTurnoffPromoteBar,
} from '../../ducks/UI.duck';
import { sendPostalAddress } from '../../ducks/user.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import config from '../../config';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  TrustBoxSlider,
} from '../../components';
import { TopbarContainer } from '../../containers';
import facebookImage from '../../assets/facebook_drivelah_2.jpg';
import twitterImage from '../../assets/facebook_drivelah_2.jpg';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import { EVENT_BROWSE_HOMEPAGE } from '../../util/gtm/gtmConstants';
import { getAddressDetailsByLatLong } from '../../util/googleMaps';
import { fetchFeaturedBCar, selectedPrediction } from '../SearchPage/SearchPage.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import BLandingCss from './LandingPageNew.css';
import LandingSectionBanner from './LandingSectionBanner';
import ComparisonTable from './ComparisonTable';
import DriveMateSection from './DriveMateSection';
import SectionBrandingHelp from '../PartnerBrandingPage/SectionBrandingHelp';
import InsuranceSummaryModal from './InsuranceSummaryModal';
import SectionGrids from './SectionGrids';
import InviteSection from './InviteSection';
// import { doSearchExperiment } from '../../util/helpers';
import LandingPageTestimonials from '../../components/LandingPageTestimonials/LandingPageTestimonials';

const MAX_MOBILE_SCREEN_WIDTH = 768;

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    onManageDisableScrolling,
    viewport,
    onSelectedPrediction,
    onSendPostalAddress,
    currentUser,
    isAuthenticated,
  } = props;
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format

  const siteTitle = config.siteTitle;
  const schemaTitle = 'Rent local cars';
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoModalOpen, onVideoModalOpen] = useState(false);
  const [isPostalCodeExists, setIsPostalCodeExists] = useState(false);
  const [isSearchedFocused,setIsSearchedFocused]=useState(false)
  const history = useHistory();
  const onPushGTMEvent = (event, buttonId) => {
    pushGTMBrowseEvent({
      props,
      event,
      buttonId,
    });
  };

  useEffect(()=>{

    isSearchedFocused && setIsSearchedFocused(false)

  },[isSearchedFocused])

  useEffect(() => {
    onPushGTMEvent(EVENT_BROWSE_HOMEPAGE);
    // doSearchExperiment()
  }, []);

  useEffect(() => {
    if (currentUser) {
      const code = currentUser.attributes &&
        currentUser.attributes.profile &&
        currentUser.attributes.profile.protectedData &&
        currentUser.attributes.profile.protectedData.locationProxy &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace &&
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode ?
        currentUser.attributes.profile.protectedData.locationProxy.selectedPlace.postalCode : '';
      setIsPostalCodeExists(!!code);
    }
  }, [currentUser]);

  let isMobileLayout;
  try {
    if (viewport) {
      isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    }
  } catch (error) {
    console.log('Error', error);
  }

  const onSelectionPostalAddress = (location) => {
    if (location && location.origin && location.origin.lat && location.origin.lng && !isPostalCodeExists) {
      const latitude = location.origin.lat;
      const longitude = location.origin.lng;
      getAddressDetailsByLatLong(latitude, longitude)
        .then((address) => {
          if (
            !address ||
            !address.formattedAddress ||
            !address.formattedAddress.selectedPlace ||
            !address.formattedAddress.selectedPlace.postalCode
          ) {
            console.log('onSelectionPostalAddress: not able to find postalCode from google maps search selection result.');
            return;
          }
          onSendPostalAddress(address.formattedAddress,
            () => {
              console.log('onSelectionPostalAddress -> onSendPostalAddress: Success');
            },
            (error) => {
              console.log('onSelectionPostalAddress -> onSendPostalAddress -> Error: ', error);
            }
          );
        })
        .catch((error) => {
          console.log('onSelectionPostalAddress -> Error:', error);
        });
    }
  };

  return (
    <Page
      className={BLandingCss.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer notApplySearch={true} currentPage={'LandingPageNew'} setIsSearchedFocused={setIsSearchedFocused} />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={BLandingCss.contentWrapperBWrapper}>
          <LandingSectionBanner
            onSelectedPrediction={onSelectedPrediction}
            onSelectionPostalAddress={onSelectionPostalAddress}
            isMobileLayout={isMobileLayout}
            currentUser={currentUser}
            openModal={setIsModalOpen}
            isAuthenticated={isAuthenticated}
            isSearchedFocused={isSearchedFocused}
          />

          <ComparisonTable />
          {/* <GrabBannerDesktop
            currentUser={currentUser}
            isMobileLayout={isMobileLayout}
            onManageDisableScrolling={onManageDisableScrolling}
          /> */}
          <InviteSection currentUser={currentUser} intl={intl} history={history} isMobileLayout={isMobileLayout} />
          <DriveMateSection
            onManageDisableScrolling={onManageDisableScrolling}
            onVideoModalOpen={onVideoModalOpen}
            onPushGTMSeeVideoEvent={onPushGTMEvent}
          />
          <LandingPageTestimonials />
          <SectionGrids />
          {/* <TrustBoxSlider /> */}
          <SectionBrandingHelp />
          {/* <LandingRentalCategories /> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      {isModalOpen && (
        <InsuranceSummaryModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      )}
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;
  const { featuredCarIds, fetchFeaturedCarInProgress, fetchFeaturedCarError } = state.SearchPage;
  const featuredCarRefs = featuredCarIds.map(id => ({ type: 'listing', id }));

  const listings = getMarketplaceEntities(state, featuredCarRefs);
  return {
    currentUser,
    scrollingDisabled: isScrollingDisabled(state),
    turnoffPromoteBar: state.UI.turnoffPromoteBar,
    isAuthenticated,
    fetchFeaturedCarInProgress,
    fetchFeaturedCarError,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({
  onTurnoffPromoteBar: flagVal => dispatch(manageTurnoffPromoteBar(flagVal)),
  onSelectedPrediction: val => dispatch(selectedPrediction(val)),
  onSendPostalAddress: (postalCodeObj, sendSuccess, sendFail) => dispatch(sendPostalAddress(postalCodeObj, sendSuccess, sendFail)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPageNew = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPageNew.loadData = () => {
  return fetchFeaturedBCar();
};

export default LandingPageNew;
