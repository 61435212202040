import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { get } from 'lodash';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { getDefaultTimeZoneOnBrowser } from '../../util/dates';
import BookingBreakdownNew from '../../components/BookingBreakdown/BookingBreakdownNew';
import config from '../../config';
import {
  getUserTxRole,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT_INSTANT,
} from '../../util/transaction';
import { ensureBooking, ensureUser, restoreTransaction } from '../../util/data';
import { $bookingUpdateTxsByParentTxId } from '../EditTripPage/EditTripPage.selectors';

import {
  BookingInfoSection,
  IconSpinner,
  Logo,
  NamedLink,
  Page,
  PartnerInfo,
} from '../../components';
import { fetchTransaction, loadData, showTransaction } from './TripDetailsPage.duck';

import css from './TripDetailsPage.css';
import { formatMoney } from '../../util/currency';
import { Money } from 'sharetribe-flex-sdk/src/types';
import CustomBreakdown from '../../components/CustomBrekdown/CustomBreakdown';
import TripReceipt from '../../components/TripReceipt/TripReceipt';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const PROVIDER = 'provider';
const CUSTOMER = 'customer';
const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

// TransactionPage handles data loading for Sale and Order views to transaction pages in Inbox.
export const TripDetailsPageComponent = props => {
  const { action } = useParams();
  const {
    bookingUpdateTxs,
    scrollingDisabled,
    transaction: parentTransaction,
    transactionUpdateBooking,
    intl,
    transactionRole,
    viewport,
    currentUser,
    history,
  } = props;

  console.log('TRIP DETAILS PAGE > props >', props);
  // console.log('TRIP DETAILS PAGE > props > distanceTransaction >', distanceTransaction);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [isUsingMastercard, setIsUsingMastercard] = useState(false);
  const [distanceTransaction, setDistanceTransaction] = useState('');

  const dispatch = useDispatch();

  const currentDepositStatus = get(
    currentUser,
    'attributes.profile.metadata.depositChargeStatus',
    ''
  );
  const depositedAt = get(currentUser, 'attributes.profile.metadata.depositedAt', '');

  useEffect(() => {
    if (parentTransaction && !dataLoaded) {
      setDataLoaded(true);
    }
    if (parentTransaction && !distanceTransaction) {
      console.log('Distance transaction not empty');
      const restoredTX = restoreTransaction(parentTransaction, isUsingMastercard);
      const distanceChargingId = get(restoredTX, 'attributes.metadata.distanceChargingId', '');

      try {
        if (distanceChargingId) {
          const response = dispatch(showTransaction(distanceChargingId));
          response.then(res => {
            console.log('TRIP DETAILS PAGE > res >', res);
            setDistanceTransaction(res);
          });
        }
      } catch (error) {
        console.log('Error in trip details page', error);
      }
    }
  }, [parentTransaction, dataLoaded]);

  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
  const isTabletLayout = viewport.width < MAX_TABLET_SCREEN_WIDTH;

  const currentListing = parentTransaction && parentTransaction.listing;
  const currentProvider = parentTransaction && ensureUser(parentTransaction.provider);
  const currentCustomer = parentTransaction && ensureUser(parentTransaction.customer);
  const listingTitle = currentListing && currentListing.attributes.title;
  const title = intl.formatMessage({ id: 'TripDetailsPage.title' }, { title: listingTitle });
  const isLoading = !dataLoaded;
  const parentCreatedAt = parentTransaction && parentTransaction.attributes.createdAt;
  const parentDateCreated = parentCreatedAt && moment(parentCreatedAt).format('Do MMM, YYYY');

  const currentUserRole =
    currentUser &&
    currentUser.id &&
    parentTransaction &&
    getUserTxRole(currentUser.id, parentTransaction);
  const isProviderRole = action === 'provider';
  const isCustomerRole = action === 'customer';
  const addonstTransactions = get(parentTransaction, 'attributes.metadata.addonsTransactions');
  const tripModificationTransaction = get(
    parentTransaction,
    'attributes.protectedData.childTransaction'
  );
  console.log(isCustomerRole, isProviderRole, currentUserRole);


  if (isCustomerRole) {
    return <TripReceipt {...props} />;
  }
  const stripeReceiptUrl = get(parentTransaction, 'attributes.metadata.stripeReceiptUrl');

  const pageProps = {
    title,
    scrollingDisabled,
    className: css.root,
  };

  const topbar = (
    <div className={css.topbar}>
      <NamedLink className={css.home} name="LandingPage">
        <Logo
          className={css.logoMobile}
          title={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="mobile"
        />
        <Logo
          className={css.logoDesktop}
          alt={intl.formatMessage({
            id: 'CheckoutPage.goToLandingPage',
          })}
          format="desktop"
        />
      </NamedLink>
    </div>
  );

  if (isLoading) {
    return (
      <Page {...pageProps}>
        {topbar}
        <div className={css.loading}>
          <IconSpinner />
          <div>
            <FormattedMessage id={'TripDetailsPage.pleaseWait'} />
          </div>
        </div>
      </Page>
    );
  }

  const timeZone = getDefaultTimeZoneOnBrowser();
  const txBooking = parentTransaction && ensureBooking(parentTransaction.booking);

  const acceptedTransactions =
    bookingUpdateTxs &&
    bookingUpdateTxs.filter(tx => {
      return tx.attributes.transitions.find(
        item =>
          item.transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT ||
          item.transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_ACCEPT_BY_ADMIN ||
          item.transition === TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT_INSTANT
      );
    });
  const lastUpdateBookingTx =
    acceptedTransactions && acceptedTransactions[acceptedTransactions.length - 1];
  const payinParentTx = (parentTransaction && parentTransaction.attributes.payinTotal.amount) || 0;

  const totalAllTransactions =
    acceptedTransactions && acceptedTransactions.length
      ? acceptedTransactions.reduce((acc, curr) => {
          acc += curr.attributes.payinTotal.amount;
          return acc;
        }, payinParentTx)
      : payinParentTx;

  console.log('TRIP DETAILS PAGE > parentTransaction >', parentTransaction);

  const restoredTX = restoreTransaction(
    lastUpdateBookingTx ? lastUpdateBookingTx : parentTransaction,
    isUsingMastercard
  );

  console.log('TRIP DETAILS PAGE > restoredTX >', restoredTX);

  const isDistanceChargingTransaction = lastUpdateBookingTx
    ? get(parentTransaction, 'attributes.protectedData.isDistanceCharge', false)
    : get(restoredTX, 'attributes.protectedData.isDistanceCharge', false);

  console.log('TRIP DETAILS PAGE > isDistanceChargingTransaction >', isDistanceChargingTransaction);

  console.log('TRIP DETAILS PAGE > distanceTransaction >', distanceTransaction);

  const { childDistanceTransaction, distanceCharge, lateReturn } = get(
    parentTransaction,
    'attributes.metadata',
    {}
  );

  const breakdownOld = parentTransaction && parentTransaction.id && txBooking.id && (
    <BookingBreakdownNew
      currentUser={currentUser}
      timeZone={timeZone}
      className={css.bookingBreakdown}
      userRole={currentUserRole}
      unitType={config.bookingUnitType}
      transaction={restoredTX}
      booking={txBooking}
      shouldShowMastercardPromoLineItem={isUsingMastercard}
      isTripDetailsPage={true}
      totalAllTransactions={totalAllTransactions}
      replaceTotalText={'Total trip price'}
    />
  );

  // Temporary code for showing custom total <<<< STARTS >>>>

  // This code is for calculating custom total to be showin in booking breakdown, if needed. Alter the parameters here to change the total dynamically.

  // Extraction line items of distance transation for processing
  const lineItemsOfDistanceTransaction = get(distanceTransaction, 'attributes.lineItems', []);

  // Line Items we need to calculate for showing in distance charges / earnings box
  const guestDistanceCharges = ['line-item/distance-charge', 'line-item/processing-fee'];
  const hostDistancePayouts = ['line-item/distance-charge', 'line-item/distance-fee'];

  // Line Items we need to calculate for showing in other charges / earnings box
  const guestOtherCharges = ['line-item/late-return-charge', 'line-item/inconvenience-fee'];
  const hostOtherPayouts = ['line-item/late-return-payout', 'line-item/inconvenience-payout'];

  // Calculating total > for distance charges / earnings box
  const distanceChargesTotalForGuest = lineItemsOfDistanceTransaction
    .filter(item => guestDistanceCharges.includes(item.code))
    .reduce((total, item) => total + item.lineTotal.amount, 0);

  const distancePayoutTotalForHost = lineItemsOfDistanceTransaction
    .filter(item => hostDistancePayouts.includes(item.code))
    .reduce((total, item) => total + item.lineTotal.amount, 0);

  // Calculating total > for other charges / earnings box
  const otherChargesTotalForGuest = lineItemsOfDistanceTransaction
    .filter(item => guestOtherCharges.includes(item.code))
    .reduce((total, item) => total + item.lineTotal.amount, 0);

  const otherPayoutTotalForHost = lineItemsOfDistanceTransaction
    .filter(item => hostOtherPayouts.includes(item.code))
    .reduce((total, item) => total + item.lineTotal.amount, 0);

  // Formatting total for distance charges / earnings box
  const formattedDistanceChargesTotalForGuest = formatMoney(
    intl,
    new Money(distanceChargesTotalForGuest, 'AUD')
  );
  const formattedDistancePayoutTotalForHost = formatMoney(
    intl,
    new Money(distancePayoutTotalForHost, 'AUD')
  );

  // Formatting total for other charges / earnings box
  const formattedOtherChargesTotalForGuest = formatMoney(
    intl,
    new Money(otherChargesTotalForGuest, 'AUD')
  );
  const formattedOtherPayoutTotalForHost = formatMoney(
    intl,
    new Money(otherPayoutTotalForHost, 'AUD')
  );

  // Temporary code for showing custom total <<<< ENDS >>>>

  console.log('TDP > > 1 > >', {parentTransaction, lastUpdateBookingTx});
  

  const parentTxEarnings = lastUpdateBookingTx ? (get(parentTransaction, 'attributes.payoutTotal.amount', 0) + get(lastUpdateBookingTx, 'attributes.payoutTotal.amount', 0)) : get(parentTransaction, 'attributes.payoutTotal.amount', 0);

  const distanceTxEarnings =
    (distanceCharge?.distanceCharge -
      distanceCharge?.distanceDM +
      lateReturn?.lateReturnHostPayout +
      lateReturn?.inconveniencePayout) *
    100;

  const totalEarningsAmount = parentTxEarnings + (distanceTxEarnings ? distanceTxEarnings : 0);

  const formattedTotalEarningsAmount = `${+(totalEarningsAmount / 100).toFixed(2)}`;

  console.log('Final Amount >>>', {
    parentTxEarnings,
    distanceTxEarnings,
    totalEarningsAmount,
    formattedTotalEarningsAmount,
    currentUserRole,
  });

  const showDistanceChargingBox = distanceCharge?.distanceCharge || null;
  const showOtherChargesnBox = lateReturn?.lateReturnCharge || null;

  // Headings of both 'breakdownForDistanceBeforeTrip', 'breakdownForDistanceAfterTrip' and 'breakdownForOtherChargesOfTrip' have been temporarily commented out, in future if needed just uncomment and put the headings as required.

  const breakdownForDistanceBeforeTrip = parentTransaction && parentTransaction.id && txBooking.id && (
    <>
      {/* <div className={css.breakDownHeading}>{isCustomerRole ? '' : ''}</div> */}
      <BookingBreakdownNew
        currentUser={currentUser}
        timeZone={timeZone}
        className={css.bookingBreakdown}
        userRole={currentUserRole}
        unitType={config.bookingUnitType}
        parentTx={lastUpdateBookingTx ? parentTransaction : null}
        transaction={restoreTransaction(
          lastUpdateBookingTx ? lastUpdateBookingTx : parentTransaction,
          isUsingMastercard
        )}
        booking={txBooking}
        shouldShowMastercardPromoLineItem={isUsingMastercard}
        isTripDetailsPage={true}
        totalAllTransactions={totalAllTransactions}
        replaceTotalText={isCustomerRole ? 'Total duration charges' : 'Total duration earnings'}
      />
    </>
  );

  const breakdownForDistanceAfterTrip = parentTransaction &&
    parentTransaction.id &&
    showDistanceChargingBox && (
      <>
        {/* <div className={css.breakDownHeading}>{isCustomerRole ? '' : ''}</div> */}
        <CustomBreakdown
          childDistanceTransactionJSON={childDistanceTransaction}
          distanceChargeJSON={distanceCharge}
          lateReturnJSON={lateReturn}
          layout={isCustomerRole ? 'guestBreakdown1' : 'hostBreakdown1'}
          customTotalTitle={isCustomerRole ? 'Total distance charges' : 'Total distance earnings'}
          // onTotalChange={handleTotalDue}
        />
      </>
    );

  const breakdownForOtherChargesOfTrip = parentTransaction &&
    parentTransaction.id &&
    showOtherChargesnBox && (
      <>
        {/* <div className={css.breakDownHeading}>{isCustomerRole ? '' : ''}</div> */}
        <CustomBreakdown
          childDistanceTransactionJSON={childDistanceTransaction}
          distanceChargeJSON={distanceCharge}
          lateReturnJSON={lateReturn}
          layout={isCustomerRole ? 'guestBreakdown2' : 'hostBreakdown2'}
          customTotalTitle={isCustomerRole ? 'Total other charges' : 'Total other earnings'}
          // onTotalChange={handleTotalDue}
        />
      </>
    );

  const totalEarnings = parentTransaction && (
    <>
      <div className={css.breakDownHeading}>Your total earnings</div>
      <div className={css.lineItem}>
        <span className={css.totalLabel}>{isProviderRole ? 'Total earnings' : ''}</span>
        <span className={css.totalValue}>${formattedTotalEarningsAmount}</span>
      </div>
      <div className={css.lineItem}>
        {/* Below inline styling have been temporarily added, it needs to be removed once we get the actual GST calculation */}
        <span className={css.gstLabel} style={{ textAlign: 'end' }}>
          GST Included
        </span>
        {/* <span className={css.gstValue}>$7.02</span> */}
      </div>
      {/* <span className={css.gstNumber}>Drive mate ABN number: 1960421496</span> */}
    </>
  );

  const receiptDownloadLinks =
    acceptedTransactions &&
    acceptedTransactions.map(tx => {
      const paidDate = moment(tx.attributes.createdAt).format('DD.MM.YYYY');

      return (
        <div className={css.linkContainer}>
          <div>
            <span className={css.downloadText}>
              <FormattedMessage id={'TripDetailsPage.downloadInvoice'} />
            </span>
            <span className={css.paidDate}>Paid on: {paidDate}</span>
          </div>
          <a href={stripeReceiptUrl} className={css.downloadPdf} target="_blank"></a>
        </div>
      );
    });

  const childModificationTransaction =
    tripModificationTransaction &&
    tripModificationTransaction.transaction &&
    JSON.parse(tripModificationTransaction.transaction);
  const txBookingModificationTransaction =
    childModificationTransaction && ensureBooking(childModificationTransaction.booking);
  const createdAtModificationTransaction =
    childModificationTransaction && childModificationTransaction.attributes.createdAt;
  const dateCreatedModificationTransaction =
    createdAtModificationTransaction &&
    moment(createdAtModificationTransaction).format('Do MMM, YYYY');

  return (
    <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.contentRow}>
          <div className={css.addonsPageTitles}>
            <div className={css.addonsBreadcrumbs}>
              {parentTransaction && (
                <NamedLink
                  className={css.arrowBack}
                  name={isCustomerRole ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: parentTransaction.id.uuid }}
                >
                  <span className={css.arrowBackText}>&#8249;</span>

                  {'Back'}
                </NamedLink>
              )}
            </div>
            <div className={css.stepTitle}>
              {isCustomerRole ? 'Trip cost details' : 'Trip earnings'}
            </div>
          </div>
        </div>
        <div className={css.contentRow}>
          <div className={css.addonsSectionLeft}>
            <div
              className={
                isCustomerRole || isProviderRole
                  ? css.detailsContainerMainFirst
                  : css.detailsContainerMain
              }
            >
              {!isDistanceChargingTransaction && <>{breakdownOld}</>}

              {isCustomerRole && stripeReceiptUrl && acceptedTransactions ? (
                <div className={css.linksContainer}>{receiptDownloadLinks}</div>
              ) : null}

              {isDistanceChargingTransaction && <>{breakdownForDistanceBeforeTrip}</>}
            </div>
            {isDistanceChargingTransaction && showDistanceChargingBox && (
              <div className={css.detailsContainerMainSecond}>{breakdownForDistanceAfterTrip}</div>
            )}
            {isDistanceChargingTransaction && showOtherChargesnBox && (
              <div className={css.detailsContainerMainSecond}>{breakdownForOtherChargesOfTrip}</div>
            )}

            {/* Total Earning box will come here. */}

            {isProviderRole && isDistanceChargingTransaction && (
              <div
                className={
                  isProviderRole ? css.detailsContainerMainSecond : css.detailsContainerMain
                }
              >
                {totalEarnings}
              </div>
            )}

            {!isProviderRole &&
            currentDepositStatus &&
            (currentDepositStatus === 'confirmed' || currentDepositStatus === 'on-hold') ? (
              <div className={css.textContainer}>
                <span className={css.depositTitle}>
                  You have a deposit on hold. Click for more details.
                </span>

                <div className={classNames(css.linkContainer, css.mt15)}>
                  <div>
                    <span className={css.downloadText}>
                      <FormattedMessage id={'TripDetailsPage.depositOnHoldText'} />
                    </span>
                    {/* <span className={css.paidDate}>Paid on: 31.03.2023</span> */}
                  </div>
                  <div href={stripeReceiptUrl} className={css.depositAmount}>
                    $50
                  </div>
                </div>

                {/*<p className={css.text}>*/}
                {/*  <FormattedMessage id={'TripDetailsPage.invoiceText'} />*/}
                {/*</p>*/}
              </div>
            ) : (
              ''
            )}
            {tripModificationTransaction && transactionUpdateBooking ? (
              <div className={css.detailsContainerMain}>
                <h3 className={css.containerTitle}>
                  <FormattedMessage
                    id={'TripDetailsPage.tripModificationTitle'}
                    values={{ date: dateCreatedModificationTransaction }}
                  />
                </h3>

                <BookingBreakdownNew
                  timeZone={timeZone}
                  className={css.bookingBreakdown}
                  userRole={currentUserRole}
                  unitType={config.bookingUnitType}
                  transaction={restoreTransaction(transactionUpdateBooking, isUsingMastercard)}
                  shouldShowMastercardPromoLineItem={isUsingMastercard}
                  booking={txBookingModificationTransaction}
                  isModificationTransaction={true}
                />
              </div>
            ) : null}
            {addonstTransactions &&
              addonstTransactions.map(i => {
                const childTransaction = i.childTransaction && JSON.parse(i.childTransaction);
                const txBooking = childTransaction && ensureBooking(childTransaction.booking);
                const createdAt = childTransaction && childTransaction.attributes.createdAt;
                const dateCreated = createdAt && moment(createdAt).format('Do MMM, YYYY');
                const isDelivery = i.isDelivery;
                const isExcessReduction = i.isExcessReduction;
                const isFuelInclusion = i.isFuelInclusion;

                return childTransaction ? (
                  !isDelivery && isExcessReduction && isProviderRole ? null : (
                    <div className={css.detailsContainerMain}>
                      <h3 className={css.containerTitle}>
                        <FormattedMessage
                          id={'TripDetailsPage.addonsTitle'}
                          values={{ date: dateCreated }}
                        />
                      </h3>
                      <BookingBreakdownNew
                        timeZone={timeZone}
                        className={css.bookingBreakdown}
                        userRole={currentUserRole}
                        unitType={config.bookingUnitType}
                        transaction={restoreTransaction(childTransaction, isUsingMastercard)}
                        booking={txBooking}
                        isAddons={true}
                      />
                    </div>
                  )
                ) : null;
              })}
          </div>
          {isMobileLayout ? null : (
            <div className={css.addonsSectionRight}>
              <div>
                {parentTransaction && currentListing && (
                  <BookingInfoSection
                    transaction={parentTransaction}
                    isCustomer={isCustomerRole}
                    showAvatar={false}
                    listing={currentListing}
                    currentProvider={currentProvider}
                    intl={intl}
                  />
                )}
                <PartnerInfo
                  currentUserRole={transactionRole}
                  transaction={parentTransaction}
                  otherUser={isProviderRole ? currentCustomer : currentProvider}
                  show={true}
                  isMobileLayout={isMobileLayout}
                  userTypeText={intl.formatMessage({
                    id: isCustomerRole
                      ? 'TripDetailsPage.userTypeText'
                      : 'TripDetailsPage.userTypeTextHost',
                  })}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

TripDetailsPageComponent.defaultProps = {
  currentUser: null,
  transaction: null,
  parentTransaction: null,
};

const { bool, func, oneOf, shape, string, arrayOf, number } = PropTypes;

TripDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  transaction: propTypes.transaction,
  parentTransaction: propTypes.transaction,
  transactionRole: oneOf([PROVIDER, CUSTOMER]).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    transactionRef,
    nextLongTermTransaction,
    childLongTermTransactions,
    currentChildLongTermTransaction,
  } = state.TripDetailsPage;
  const { currentUser } = state.user;
  const { transactionUpdateBookingRef } = state.TransactionPage;

  const transactionsUpdateBooking = getMarketplaceEntities(
    state,
    transactionUpdateBookingRef ? [transactionUpdateBookingRef] : []
  );
  const transactionUpdateBooking =
    transactionsUpdateBooking.length > 0 ? transactionsUpdateBooking[0] : null;

  if (nextLongTermTransaction && transactionsUpdateBooking) {
    transactionsUpdateBooking.nextTransaction = nextLongTermTransaction;
    transactionsUpdateBooking.childTransaction = childLongTermTransactions;
    transactionsUpdateBooking.currentChildTransaction = currentChildLongTermTransaction;
  }

  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);
  const transaction = transactions.length > 0 ? transactions[0] : null;
  if (nextLongTermTransaction && transaction) {
    transaction.nextTransaction = nextLongTermTransaction;
    transaction.childTransaction = childLongTermTransactions;
    transaction.currentChildTransaction = currentChildLongTermTransaction;
  }

  const bookingTxId = transaction ? transaction.id.uuid : null;

  return {
    currentUser,
    transaction,
    transactionUpdateBooking,
    bookingUpdateTxs: $bookingUpdateTxsByParentTxId(state, bookingTxId),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
  };
};

const TripDetailsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(TripDetailsPageComponent);

TripDetailsPage.loadData = loadData;

export default TripDetailsPage;
