import React, { useEffect, useState } from 'react';
import styles from './DistanceChargeFailedPage.module.css';
import { IconSpinner, Logo, NamedLink, Page, PrimaryButton } from '../../components';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { showTransaction } from '../TripDetailsPage/TripDetailsPage.duck';
import CustomBreakdown from '../../components/CustomBrekdown/CustomBreakdown';

const DistanceChargeFailedPage = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [childDistanceTransaction, setChildDistanceTransaction] = useState({});
  const [distanceCharge, setDistanceCharge] = useState({});
  const [lateReturn, setLateReturn] = useState({});
  const [distanceChargeCardError, setDistanceChargeCardError] = useState({});
  const [totalDue, setTotalDue] = useState(null);
  const [redirectToPrevious, setRedirectToPrevious] = useState(null);
  const [paymentWindow, setPaymentWindow] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    console.log('Entered in use effect');
    const pathSegments = window.location.pathname.split('/');
    const transactionId = pathSegments[2];

    if (isEmpty(distanceCharge)) {
      dispatch(showTransaction(transactionId))
        .then(res => {
          console.log('Response on a transaction >>', res);
          const { 
            childDistanceTransaction, 
            distanceCharge, 
            lateReturn, 
            distanceChargeCardError, 
            isDistanceCharged 
          } = res?.attributes?.metadata || {};          
          setRedirectToPrevious(isDistanceCharged);
          setChildDistanceTransaction(childDistanceTransaction);
          setDistanceCharge(distanceCharge);
          setLateReturn(lateReturn);
          setDistanceChargeCardError(distanceChargeCardError);
          setIsLoading(false);
        }).catch(error => {
          console.log('Error in fetching data > ', error);
        })
    }
  }, []);

  useEffect(() => {
    if (redirectToPrevious) {
      window.history.back();
    }
  }, [redirectToPrevious]);

  const handleTotalDue = total => setTotalDue(total);


  const onPayNowButtonClick = () => {
    const invoiceLink = distanceCharge?.invoicePaymentLink;
    
    if (!invoiceLink) return;
  
    if (paymentWindow && !paymentWindow.closed) {
      paymentWindow.focus();
      return;
    }
  
    const newWindow = window.open(invoiceLink, '_blank', 'width=600,height=700');
  
    if (newWindow) {
      setButtonLoading(true);
      setPaymentWindow(newWindow);
  
      // Prevent user from interacting with the main page
      document.body.style.pointerEvents = 'none';
  
      const interval = setInterval(() => {
        if (newWindow.closed) {
          setButtonLoading(true);
          clearInterval(interval);
          setPaymentWindow(null);
          document.body.style.pointerEvents = 'auto'; // Restore interactions
          window.location.reload();
        } else {
          newWindow.focus(); // Keep bringing the pop-up to focus
        }
      }, 500);

      setButtonLoading(false);
    }
  };

  const pageProps = { title: 'Distance charge failed', scrollingDisabled: false, className: styles.root };


  return (
    <Page {...pageProps}>
      {/* Top bar Starts */}
      <div className={styles.topbar}>
        <NamedLink className={styles.home} name="LandingPage">
          <Logo
            className={styles.logoMobile}
            title='Go to landing page'
            format="mobile"
          />
          <Logo
            className={styles.logoDesktop}
            alt='Go to landing page'
            format="desktop"
          />
        </NamedLink>
      </div>
      {/* Top bar Ends */}

      {/* Loader */}
      {isLoading && (
        <div className={styles.loading}>
          <IconSpinner />
          <div>Fetching Data...</div>
        </div>
      )}

      {/* Content */}
      {!isLoading && (
        <div className={styles.outerBox}>
          <div className={styles.title}>Distance charge failed</div>
          <div className={styles.outerContainer}>
            <div className={styles.containerLeft}>
              <p>
                Drop-off successfully done, but we failed to charge you for the distance you drove during the trip. Please try again to avoid penalties.
              </p>
              <div className={styles.errorForFailedTransaction}>
                <p>{`Payment of ${totalDue} failed`}</p>
                <p>
                  <p>
                    {distanceChargeCardError?.title || ''}
                  </p>
                  <p>
                    {distanceChargeCardError?.desc || ''}
                  </p>
                </p>
              </div>
            </div>
            <div className={styles.containerRight}>
              <h3>Charge Details</h3>
              <CustomBreakdown
                childDistanceTransactionJSON={childDistanceTransaction}
                distanceChargeJSON={distanceCharge}
                lateReturnJSON={lateReturn}
                layout={'distanceChargePage'}
                onTotalChange={handleTotalDue}
              />
            </div>
          </div>
        </div>
      )}
      {!isLoading && (
      <div className={styles.submitSection}>
        <div className={styles.submitSectionInner}>
          <div className={styles.submitSectionPrice}>
            <span className={styles.totalAmount}>
              {`$${totalDue}`}
            </span>
            Total Amount
          </div>
          <div className={styles.submitSectionPrice}>
            <div className={styles.submitContainer}>
              <PrimaryButton
                id='payingInvoice'
                className={styles.submitButton}
                onClick={onPayNowButtonClick}
                inProgress={buttonLoading}
              >
                Pay now
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
      )}
    </Page>
  );
};

export default DistanceChargeFailedPage;