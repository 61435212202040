import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';
import LogoImage from '../../assets/logos/drive-mate-footer-logo.png';

const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div style={{ color: '#00a3ad' }}>
        {/*<h1>*/}
        {/*  <strong>Privacy Policy</strong>*/}
        {/*</h1>*/}

        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          margin: "50px"
        }}>
          <h1 className={css.mainHeading}>
            Drive Lah Australia Pty
            <br/>
            Ltd T/As Drive Mate
          </h1>
          <div style={{
            margin: "50px"
          }}>
            <img src={LogoImage} alt="logo"/>
          </div>
          <div style={{
            margin: "50px"
          }}>
            <h3 className={css.headingColour}>Privacy Policy Manual</h3>
          </div>
        </div>
        <div>
          <h3>Table of contents</h3>
        </div>

        <div>
          <ol style={{
            marginLeft: '20px',
          }}>
            <li>Australian Privacy Principles (APP’s)</li>
            <li>General Data Protection Regulation (“GDPR”)</li>
            <li>Types of Personal Information That Is Collected, Used, Processed & Held</li>
            <li>Procedures and Responding to Potential Breaches of Privacy</li>
            <li>Purposes for Which Information is Collected, Held, Used and Disclosed</li>
            <li>How an Individual May Access Personal Information Held and How They May Seek
              Correction of Such Information
            </li>
            <li>How an individual may request Data Deletion</li>
            <li>How an Individual May Complain About A Breach Of The APP, And How The Complaint Will
              Be Dealt With
            </li>
            <li>Will Personal Information Be Disclosed to Overseas Recipients?</li>
            <li>Availability of This Privacy Policy Manual</li>
            <li>
              Privacy Officer (Responsibilities)
              <br />
              Part 1 – Consideration of Personal Information Privacy
            </li>
            <li>
              Australian Privacy Principle 2 – Anonymity and Pseudonymity
              <br />
              Part 2 – Collection of Personal Information
            </li>
            <li>
              Australian Privacy Principle 3 – Collection of Solicited Personal Information
            </li>
            <li>
              Australian Privacy Principle 4 – Dealing with Unsolicited Personal Information
            </li>
            <li>
              Australian Privacy Principle 5 – Notification of the Collection of Personal
              Information
              <br />
              Part 3 – Dealing With Personal Information
            </li>
            <li>
              Australian Privacy Principle 6 – Use or Disclosure of Personal Information
            </li>
            <li>
              Australian Privacy Principle 7 – Direct Marketing
            </li>
            <li>
              Australian Privacy Principle 8 – Cross-Border Disclosure of Personal Information
            </li>
            <li>
              Australian Privacy Principle 9 – Adoption, Use or Disclosure of Government
              <br/>
              Related Identifiers
              <br/>
              Part 4 – Integrity Of Personal Information
            </li>
            <li>
              Australian Privacy Principle 10 – Quality of Personal Information
            </li>
            <li>
              Australian Privacy Principle 11 – Security of Personal Information
              <br/>
              Part 5 – Access To and Correction Of Personal Information
            </li>
            <li>
              Australian Privacy Principle 12 – Access to Personal Information
            </li>
            <li>
              Australian Privacy Principle 13 – Correction of Personal Information
            </li>
          </ol>
        </div>


        <div style={{
          marginTop: "50px"
        }}>
          <h3>Introduction</h3>
        </div>
        <div>
          <p>
            From 12 March 2014, the Australian Privacy Principles (APP’s) replaced the National
            Privacy Principles and Information Privacy Principles and were inserted into the Privacy
            Act 1988 (“the Act”) at schedule 1. These principles apply to private sector
            organisations who deal with information relating to individuals. This legislation is
            designed to protect personal information about individuals and sets in place a framework
            and guidelines about how to deal with this information. APP 1.3 requires an APP entity
            to have a clearly expressed and up-to-date APP privacy policy describing how it manages
            personal information. Further in February 2018, the Notifiable Data Breaches (“NDB”)
            Scheme was introduced under Part IIIC of the Act. The NDB establishes requirements and
            compliance mechanisms for entities in responding to data breaches.
          </p>
          <p>
            As at 25 May 2018, the EU General Data Protection Regulation (“GDPR”) was introduced
            providing increased transparency for data protection for all businesses transferring
            data to the Europe Union. While the GDPR and the APP share some similarities, Drive Lah
            Australia Pty Ltd trading as Drive Mateis providing robust privacy policies and
            procedures for its staff and clients. This includes ensuring that it conforms to all
            required APP’s including the provision of a clearly expressed and readily available
            Privacy Policy. This is completed by the provision of this Privacy Policy Manual.
          </p>
          <p>
            An APP privacy policy is a key tool for meeting APP 1’s requirements.
          </p>
          <p>
            To assist with this compliance, Drive Lah Australia Pty Ltd trading as Drive Mate
            ensures that all of its staff members adhere to these policies and procedures. Any
            breaches of these policies and procedures must be reported to the relevant staff
            member’s manager or supervisor immediately so that any appropriate measures can be taken
            to mitigate any issues surrounding an identified breach.
          </p>
          <p>
            Every staff member of Drive Lah Australia Pty Ltd trading as Drive Mate who handles
            personal information is required to have an understanding of the Australian Privacy
            Principles (APP’s), the Act and the GDPR, where necessary. Where a more detailed
            knowledge of Drive Lah Australia Pty Ltd trading as Drive Mate’s rights and
            responsibilities is required, the Privacy Officer will be able to provide assistance.
          </p>
          <p>
            All staff are encouraged to discuss privacy issues with the nominated Privacy Officer.
          </p>
        </div>
        <p>
          <br />
        </p>

        <div>
          <h3>Review</h3>
        </div>
        <div>
          <p>
            Formal review of this privacy policy shall be undertaken on a 6-monthly basis with the
            details of this review recorded by the Privacy Officer.
          </p>
        </div>

        <div>
          <h2>1. Australian Privacy Principles (APP’s)</h2>
        </div>
        <div>
          <p>
            The Privacy Act 1988 and the Credit Reporting Privacy Code 2014 places obligations and
            responsibilities on employers and employees to ensure that information collected from
            individuals is collected, retained and used in line with the APP’s. Drive Lah Australia
            Pty Ltd trading as Drive Mate shall abide by the following APP’s at all times:
          </p>
          <h4>APP No.</h4>
          <ul>
            <li>
              <b>
                Part 1 – Consideration of personal information privacy
              </b>
              <ul style={{
                marginLeft: '20px',
              }}>
                <li>
                  APP 1: Open and transparent management of personal information
                </li>
                <li>
                  APP 2: Anonymity and pseudonymity
                </li>
              </ul>
            </li>
            <li>
              <b>
                Part 2 – Collection of personal information
              </b>
              <ul style={{
                marginLeft: '20px',
              }}>
                <li>
                  APP 3: Collection of solicited personal information
                </li>
                <li>
                  APP 4: Dealing with unsolicited personal information
                </li>
                <li>
                  APP 5: Notification of the collection of personal information
                </li>
              </ul>
            </li>
            <li>
              <b>
                Part 3 – Dealing with personal information
              </b>
              <ul style={{
                marginLeft: '20px',
              }}>
                <li>
                  APP 6: Use or disclosure of personal information
                </li>
                <li>
                  APP 7: Direct marketing
                </li>
                <li>
                  APP 8: Cross-border disclosure of personal information
                </li>
                <li>
                  APP 9: Adoption, use or disclosure of government related identifiers
                </li>
              </ul>
            </li>
            <li>
              <b>
                Part 4 – Integrity of personal information
              </b>
              <ul style={{
                marginLeft: '20px',
              }}>
                <li>
                  APP 10: Quality of personal information
                </li>
                <li>
                  APP 11: Security of personal information
                </li>
              </ul>
            </li>
            <li>
              <b>
                Part 5 – Access to, and correction of, personal information
              </b>
              <ul style={{
                marginLeft: '20px',
              }}>
                <li>
                  APP 12: Access to personal information
                </li>
                <li>
                  APP 13: Correction of personal information
                </li>
              </ul>
            </li>
            <li>
              <p>
                Further information regarding the APP’s can be obtained from the office of the
                Australian Information Commissioner at &nbsp;
                <a href='https://www.oaic.gov.au' target='_blank'>
                  www.oaic.gov.au
                </a>.
              </p>
              <p>
                A copy of the APP’s as produced by the Office of the Australian Information
                Commissioner is attached as Appendix A. The NDB forms part of the Act as a new
                implemented scheme for companies to advise by its clients, in the event of a
                potential data breach that is likely to result in serious harm to any individuals
                whose personal information is involved in the breach. Drive Lah Australia Pty Ltd
                trading as Drive Mate’s Privacy Policy Manual provides a data breach preparation and
                response to any potential breaches to ensure compliance under the NDB and the Act.
              </p>
            </li>
          </ul>
        </div>


        <div>
          <h2>2. General Data Protection Regulation ("GDPR")</h2>
        </div>
        <div>
          <p>
            Upon the implementation of the GDPR on 25 May 2018, Drive Lah Australia Pty Ltd trading
            as Drive Mate has updated the way they use and collect personal data from residents in
            the EU. This involves, identifying Drive Lah Australia Pty Ltd trading as Drive Mate’s
            data protection officer (“Privacy Officer”), how clients can contact the Privacy Officer
            and identifying the process of transferring client’s personal information. Further, the
            implementation of cookies notices on Drive Lah Australia Pty Ltd trading as Drive Mate’s
            website has been activated to ensure Drive Lah Australia Pty Ltd trading as Drive Mate’s
            clients have adequate protection in providing consent to Drive Lah Australia Pty Ltd
            trading as Drive Mate withholding their personal data.
          </p>
        </div>


        <div>
          <h2>3. Types of Personal Information That Is Collected, Used, Processed & Held</h2>
        </div>
        <div>
          <ul>
            <li>
              <p>
                Drive Lah Australia Pty Ltd trading as Drive Mate collects personal information for
                a variety of reasons. This personal information will be collected in the normal
                course of business and will relate to Goods and/or Services that are provided by
                Drive Lah Australia Pty Ltd trading as Drive Mate to clients. This information
                collected will be done so in the course of business where the client is a customer
                of Drive Lah Australia Pty Ltd trading as Drive Mate or when the client acts as a
                guarantor for another person or company that is a client of Drive Lah Australia Pty
                Ltd trading as Drive Mate. Drive Lah Australia Pty Ltd trading as Drive Matewill not
                collect information that is not relevant or sensitive in nature unless it is
                required in the normal course of business.
              </p>
            </li>
            <li>
              <p>The personal information that is collected may include, but will not be limited to
                the following;</p>
              <ol style={{
                marginLeft: '20px',
              }}>
                <li>
                  Full name
                </li>
                <li>
                  Address
                </li>
                <li>
                  Date of birth
                </li>
                <li>
                  Credit references if applicable
                </li>
                <li>
                  Publicly available information which relate to the client’s activities in
                  Australia
                </li>
                <li>
                  Any information recorded in the National Personal Insolvency Index
                </li>
                <li>
                  The client acknowledges that provided the correct Privacy Act disclosures have
                  been made that Drive Lah Australia Pty Ltd trading as Drive Mate may conduct a
                  credit report on the client for the purposes of evaluating the credit worthiness
                  of the client.
                </li>
                <li>
                  Driver’s license details
                </li>
                <li>
                  Medical insurance details (if applicable)
                </li>
                <li>
                  Electronic contact details including email, Facebook and Twitter details
                </li>
                <li>
                  Next of kin and other contact information where applicable
                </li>
              </ol>
            </li>
            <li>
              <p>
                Drive Lah Australia Pty Ltd trading as Drive Mate ensures that all personal
                information is held in a secure manner. Where applicable and to the best of Drive
                Lah Australia Pty Ltd trading as Drive Mate’s knowledge all computers or servers
                have the required security protections in place to safeguard and protect any
                personal information that is held by Drive Lah Australia Pty Ltd trading as Drive
                Mate.
              </p>
              <p>
                We use cookies on our website. Cookies are small files which are stored on your
                computer. They are designed to hold a modest amount of data (including personal
                information) specific
                to a particular client and website and can be accessed either by the web server or
                the client’s computer. In so far as those cookies are not strictly necessary for the
                provision of Drive Lah Australia Pty Ltd trading as Drive Mate’s services, we will
                ask you to consent to our use of cookies when you first visit our website.
              </p>
              <p>
                In the event that you utilise our website for the purpose of purchases/orders, Drive
                Lah Australia Pty Ltd trading as Drive Mate agrees to display reference to cookies
                and /or similar tracking technologies, such as pixels and web beacons (if
                applicable), and requests consent for Drive Lah Australia Pty Ltd trading as Drive
                Mate collecting your personal information which may include:
                <ol className={css.alphabetListing}>
                  <li>
                    IP address, browser, email client type and other similar details;
                  </li>
                  <li>
                    Tracking website usage and traffic; and
                  </li>
                  <li>
                    Reports are available to Drive Lah Australia Pty Ltd trading as Drive Mate when
                    Drive Lah Australia Pty Ltd trading as Drive Mate sends an email to the client,
                    so Drive Lah Australia Pty Ltd trading as Drive Mate may collect and review that
                    information
                  </li>
                </ol>
              </p>
              <p>
                Our website incorporates privacy controls which affect how we will process your
                personal data. By using the privacy control, you can advise us if you would like to
                receive direct marketing communications. You can access the privacy controls
                via &nbsp;
                <a href='https://drivemate.au' target='_blank' style={{
                  color: 'rgb(0, 163, 173)',
                }}>
                  www.drivemate.au
                </a>
              </p>
              <br />
              <p>
                OR:
              </p>
              <br />
              <p>
                If you consent to Drive Lah Australia Pty Ltd trading as Drive Mate’s use of cookies
                on our website and later wish to withdraw your consent, you may manage and control
                Drive Lah Australia Pty Ltd trading as Drive Mate’s privacy controls through your
                browser, including removing cookies by deleting them from your browser history when
                you leave the site.
              </p>
              <p>
                Drive Lah Australia Pty Ltd trading as Drive Mate also regularly conducts internal
                risk management reviews to ensure that its infrastructure (to the best of its
                knowledge) is secure and any identifiable risks have been mitigated as much as they
                can be in the normal course of business.
              </p>
            </li>
          </ul>
        </div>


        <div>
          <h2>4. Procedures and Responding to Potential Breaches of Privacy</h2>
        </div>
        <div>
          <p>
            In accordance with the NDB, Drive Lah Australia Pty Ltd trading as Drive Mate is aware
            of its responsibilities to notify its clients in the event of a potential data breach
            that may cause serious harm to clients. Further, in the event the client is located in
            the Europe Union (“EU”), Drive Lah Australia Pty Ltd trading as Drive Mate acknowledges
            that any potential data breaches will be safeguarded by the provisions of the GDPR.
          </p>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate will collect and process personal
            information in the normal course of business. This personal information may be collected
            and processed (but is not limited to) by any of the following methods;
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>Credit applications forms</li>
              <li>Work Authorisation forms, quote forms or any other business documentation</li>
              <li>Publicly available databases that hold information</li>
              <li>Websites that detail information such as Sensis, Facebook, Google etc.</li>
              <li>By verbally asking you for information as part of normal business practices</li>
            </ol>
          </p>
          <p>
            Where relevant to data processing as per the GDPR, and in particular where Drive Lah
            Australia Pty Ltd trading as Drive Mate uses new technologies, and takes into account
            the nature, scope, context and purposes of processing and considers that the data
            processing is likely to result in a high risk to the rights and freedoms of natural
            persons, the Privacy Officer shall, prior to the processing of personal information,
            carry out an assessment of impact of the envisaged processing operations on the
            protection impact assessment. The data protection assessment will be required in
            instances whereby:
          </p>
          <ul className={css.alphabetListing}>
            <li>
              a systematic and extensive evaluation of personal aspects relating to natural persons
              which is based on automated processing, including profiling, and on which decisions
              are based that produce legal effects concerning the natural person or similarly
              significantly affect the natural person;
            </li>
            <li>
              processing on a large scale of special categories of data referred to in Article 9 (1)
              of the GDPR, or of personal data relating to criminal convictions and offences
              referred to in Article 10 of the GDPR; or
            </li>
            <li>
              A systematic monitoring of a publicly accessible area on a large scale
            </li>
          </ul>
          <p>
            The assessment shall be carried out in accordance with Article 35 (7) of the GDPR and
            carry out reviews of such data protection impact assessments when there is any change of
            the risk associated with the processing of personal information.
          </p>
          <p>
            As a client of Drive Lah Australia Pty Ltd trading as Drive Mate and agreeing to Drive
            Lah Australia Pty Ltd trading as Drive Mate’s Terms and Conditions of Trade, which
            comprises of Drive Lah Australia Pty Ltd trading as Drive Mate’s privacy statement you
            hereby agree and consent to the provisions of this Privacy Policy Manual, including but
            not limited to the collection, processing, use and disclosure of your personal
            information. In the event that you do not wish to agree or consent to any of the above
            use, processing collection and disclosure, then Drive Lah Australia Pty Ltd trading as
            Drive Mate warrants that any request by you to withdraw your consent or agreement shall
            be deemed as confirmation by you to cease any and/or all collection use, processing and
            disclosure of your personal information. You may make a Request to withdraw your consent
            at any time by telephone and/or by e-mail to the following contact details;
          </p>

          <br />
          <p>
            <b>The Privacy Officer</b>
          </p>
          <p>
            Gaurav Singhal
            <br />
            Drive lah Australia Pty Ltd trading as Drive Mate
            <br />
            Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
            <br />
            support@drivemate.au
            <br />
            Ph: 03 4160 2205
          </p>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate will ensure that any Information that
            is to be obtained from you is done so using Drive Lah Australia Pty Ltd trading as Drive
            Mate’s prescribed forms which;
            <br />
            Authorise Drive Lah Australia Pty Ltd trading as Drive Mate:
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>
                To collect personal information; and
              </li>
              <li>
                Inform the individual what personal information is being collected; and
              </li>
              <li>
                Inform the individual why (the purpose) the personal information is being collected;
                and
              </li>
              <li>
                Inform the individual why & when personal information will be disclosed to 3rd
                parties.
              </li>
            </ol>
          </p>
          <p>
            It is the responsibility of Drive Lah Australia Pty Ltd trading as Drive Mate to ensure
            that any personal information obtained is as accurate and up to date as possible and
            information is only collected by lawful means in accordance with the Act and relevantly,
            in accordance with the GDPR.
          </p>
        </div>


        <div>
          <h2>5. Purposes for Which Information is Collected, Held, Used andDisclosed</h2>
        </div>
        <div>
          <b>Disclosure to Third Parties</b>
          <br />
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate will not pass on your personal
            information to third parties without first obtaining your consent. In accordance with
            the Act, and relevantly the GDPR, Personal Information can only be used by Drive Lah
            Australia Pty Ltd trading as Drive Mate for the following purposes:
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>
                Access a credit reporter’s database for the following purposes:
                <ol className={css.alphabetListing}>
                  <li>
                    To assess your application for a credit account; or
                  </li>
                  <li>
                    To assess your ongoing credit facility; or
                  </li>
                  <li>
                    To notify a credit reporter of a default by you; or
                  </li>
                  <li>
                    To update your details listed on a credit reporter’s database; or
                  </li>
                </ol>
              </li>
              <li>
                Check trade references noted on the prescribed form for the following purposes:
                <ol className={css.alphabetListing}>
                  <li>
                    To assess your application for a credit account; or
                  </li>
                  <li>
                    To assess your ongoing credit facility; or
                  </li>
                  <li>
                    To notify a default.
                  </li>
                </ol>
              </li>
              <li>
                Market Drive Lah Australia Pty Ltd trading as Drive Mate’s products and services.
              </li>
              <li>
                Any other day to day business purposes such as complying with ATO requirements,
                managing accounting returns or legal matters.
              </li>
            </ol>
          </p>
          <p>
            <b>Relationship with Credit Reporter - </b> In the event that notification of a default
            has been reported to a Credit Reporter and your credit file has been updated (including
            any changes to the balance outstanding or contact details), then the Credit Reporter
            shall be notified as soon as practical of any such changes.
          </p>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate will only gather information for its
            particular purpose (primary purpose). In accordance with the Act, and relevantly the
            GDPR Drive Lah Australia Pty Ltd trading as Drive Mate will not disclose this
            information for any other purpose unless this has been agreed to by both parties.
          </p>
        </div>


        <div>
          <h2>6. How an Individual May Access Personal Information Held a nd How They May Seek
            Correction of Such Information</h2>
        </div>
        <div>
          <p>
            You shall have the right to request from Drive Lah Australia Pty Ltd trading as Drive
            Mate a copy of all the information about you that is retained by Drive Lah Australia Pty
            Ltd trading as Drive Mate. You also have the right to request (by telephone and/or by
            e-mail) that Drive Lah Australia Pty Ltd trading as Drive Mate correct any information
            that is incorrect, outdated or inaccurate.
          </p>
          <p>
            Any requests to receive your personal information or to correct personal information
            should be directed to the following contact details;
          </p>
          <p>
            <b>The Privacy Officer</b>
          </p>
          <p>
            Gaurav Singhal
            <br />
            Drive lah Australia Pty Ltd trading as Drive Mate
            <br />
            Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
            <br />
            support@drivemate.au
            <br />
            Ph: 03 4160 2205
          </p>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate will destroy personal information upon
            your request (by telephone and/or by e-mail) or when the personal information is no
            longer required. The exception to this is if the personal information is required in
            order to fulfil the purpose of Drive Lah Australia Pty Ltd trading as Drive Mate or is
            required to be maintained and/or stored in accordance with the law.
          </p>
        </div>

        <div>
          <h2>7. How an individual may request Data Deletion</h2>
        </div>
        <div>
          <p>
            We value your privacy and provide options for managing your personal data. As outlined in the sections above,
            certain data collected and held by Drive Lah Australia Pty Ltd trading as Drive Mate is mandatory for using our platform.
          </p>
          <p>
          If you wish to request the deletion of specific information,
          you can contact our Privacy Officer to determine whether the requested data can be removed:
          </p>
          <p>
            <b>Privacy Officer Contact Details:</b>
          </p>
          <p>
            Gaurav Singhal
            <br />
            Drive lah Australia Pty Ltd trading as Drive Mate
            <br />
            Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
            <br />
            support@drivemate.au
            <br />
            Ph: 03 4160 2205
          </p>
          <p>
            For data essential to platform operation, we may be unable to delete it without impacting your ability to use our services. However, if you wish to remove all associated data, you can request account deletion.
          </p>
          <p>
            <b>How to Request Account Deletion:</b>
            </p>
          <p>
          You can request to delete your account and associated data through either of the following methods:
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>
                  In-App: Navigate to <strong>App</strong> {">"} <strong>Profile</strong> {">"} <strong>Request Account Deletion</strong>
              </li>
              <li>
                Support Team: Email support@drivemate.au to request account termination.
              </li>
            </ol>
          </p>
          <p>Once an account deletion request is processed, your data will be permanently removed except where retention is required by law or for legitimate business purposes.</p>
        </div>


        <div>
          <h2>8. How an Individual May Complain About A Breach Of The APP, And How The Complaint
            Will Be Dealt With</h2>
        </div>
        <div>
          <p>
            You can make a complaint to Drive Lah Australia Pty Ltd trading as Drive Mate’s internal
            dispute resolution team (‘IDR’) regarding an interference with and/or misuse of your
            personal information by contacting Drive Lah Australia Pty Ltd trading as Drive Mate via
            telephone or e-mail.
          </p>
          <p>
            Any complaints should be directed to the following contact details in the first
            instance;
          </p>
          <p>
            <b>The Privacy Officer</b>
          </p>
          <p>
            Gaurav Singhal
            <br />
            Drive lah Australia Pty Ltd trading as Drive Mate
            <br />
            Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
            <br />
            support@drivemate.au
            <br />
            Ph: 03 4160 2205
          </p>
          <p>
            In your communication you should detail to Drive Lah Australia Pty Ltd trading as Drive
            Mate the nature of your complaint and how you would like Drive Lah Australia Pty Ltd
            trading as Drive Mate to rectify your complaint.
          </p>
          <p>
            We will respond to that complaint within 7 days of receipt and will take all reasonable
            steps to make a decision as to the complaint within 30 days of receipt of the complaint.
            We will disclose information in relation to the complaint to any relevant credit
            provider and or CRB that holds the personal information the subject of the complaint.
          </p>
          <p>
            In the event that you are not satisfied with the resolution provided, then you can make
            a complaint to the Information Commissioner on the OAIC website at &nbsp;
            <a href='https://www.oaic.gov.au' target='_blank'>www.oaic.gov.au</a>
          </p>
        </div>


        <div>
          <h2>9. Will Personal Information Be Disclosed to Overseas Recipients?</h2>
        </div>
        <div>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate does not disclose information about
            the client to third party overseas recipients unless the client has provided its
            consent. Drive Lah Australia Pty Ltd trading as Drive Mate will notify you if
            circumstances change regarding overseas disclosure and will comply with the Act and the
            GDPR in all respects.
          </p>
          <p>
            Unless otherwise agreed, Drive Lah Australia Pty Ltd trading as Drive Mate agrees not to
            disclose any personal information about the client for the purpose of direct marketing.
            You have the right to request (by telephone and/or by e-mail) that Drive Lah Australia
            Pty Ltd trading as Drive Mate does not disclose any personal information about you for
            the purpose of direct marketing.
          </p>
        </div>


        <div>
          <h2>10. Availability of This Privacy Policy Manual</h2>
        </div>
        <div>
          <p>
            This Privacy Policy manual is available to all clients of Drive Lah Australia Pty Ltd
            trading as Drive Mate. It will be made available (where applicable) on Drive Lah
            Australia Pty Ltd trading as Drive Mate’s website.
          </p>
          <p>
            This manual will also be available upon request at Drive Lah Australia Pty Ltd trading
            as Drive Mate’s business premises and is available to be sent to you if required.
          </p>
          <p>
            If you require a copy of this Privacy Policy, please make a request utilising the
            following contact information in the first instance:
          </p>
          <p>
            <b>The Privacy Officer</b>
          </p>
          <p>
            Gaurav Singhal
            <br />
            Drive lah Australia Pty Ltd trading as Drive Mate
            <br />
            Suite 5, Level 14, 350 Collins Street, Melbourne, Vic 3000
            <br />
            support@drivemate.au
            <br />
            Ph: 03 4160 2205
          </p>
        </div>


        <div>
          <h2>11. Privacy Officer (Responsibilities)</h2>
        </div>
        <div>
          <p>
            Drive Lah Australia Pty Ltd trading as Drive Mate has appointed an internal Privacy
            Officer to manage its privacy matters. The name of this officer is available by making
            contact with Drive Lah Australia Pty Ltd trading as Drive Mate. The privacy officers’
            duties include (but are not limited to) the following:
          </p>
          <p>
            The Privacy Officer needs to be familiar with the APP’s. Educational material is
            available from the office of the Privacy Commissioner which explains what Drive Lah
            Australia Pty Ltd trading as Drive Mate needs to know in order to comply with the
            Privacy Act.
          </p>
          <p>
            If a person complains to the Privacy Commissioner that Drive Lah Australia Pty Ltd
            trading as Drive Mate has breached their privacy, the Information Commissioner may
            contact the Privacy Officer to discuss the complaint, and to see whether there is any
            means of settling the matter. The Privacy Officer shall provide whatever assistance is
            necessary. The Privacy Officer may be asked to provide background information or
            identify the staff members who can do so.
          </p>
        </div>


        <div>
          <h2>Complaints</h2>
        </div>
        <div>
          <p>
            In the event that a complaint about privacy issues is received the Privacy Officer will:
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>
                Take ownership of the complaint and ensure that it is dealt with in a timely manner.
              </li>
              <li>
                Acknowledge receipt of the complaint within 24 hours and advise the complainant of
                their rights.
              </li>
              <li>
                Fully investigate the complaint.
              </li>
              <li>
                Respond, with findings, to the complainant within 30 days of receipt.
              </li>
              <li>
                Keep a record of all complaints received for ongoing review of policies and
                procedures.
              </li>
            </ol>
          </p>
          <p>
            In the event that a complaint about privacy issues is received via a credit reporter the
            Privacy Officer will:
            <ol style={{
              marginLeft: '20px',
            }}>
              <li>
                Take ownership of the complaint and ensure that it is dealt with in a timely manner.
              </li>
              <li>
                Acknowledge receipt of the complaint to the credit reporter within 24 hours.
              </li>
              <li>
                Fully investigate the complaint.
              </li>
              <li>
                Respond, with findings, to the credit reporter within 7 days of receipt.
              </li>
              <li>
                Keep a record of all complaints received for ongoing review of policies and
                procedures.
              </li>
            </ol>
          </p>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>APPENDIX A - INFORMATION PRIVACY PRINCIPLES</h2>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            Part 1 – Consideration of Personal Information Privacy
          </h2>
        </div>
        <div className={css.padLeft}>
          <ol>
            <li>
              <b>Australian Privacy Principle 1 – open and transparent management of personal
                information</b>
              <ol className={css.romanListing}>
                <li>
                  The object of this principle is to ensure that APP entities manage personal
                  information in an open and transparent way.
                </li>
                <p>
                  <b>Compliance with the Australian Privacy Principles etc</b>
                </p>
                <li>
                  An APP entity must take such steps as are reasonable in the circumstances
                  toimplement practices, procedures and systems relating to the entity’s functions
                  or activities that:
                  <ol className={css.alphabetListing}>
                    <li>
                      will ensure that the entity complies with the Australian Privacy Principles
                      and a registered APP code (if any) that binds the entity; and
                    </li>
                    <li>
                      will enable the entity to deal with inquiries or complaints from individuals
                      about the entity’s compliance with the Australian Privacy Principles or such a
                      code.
                    </li>
                  </ol>
                </li>
                <p>
                  <b>APP Privacy policy</b>
                </p>
                <li>
                  An APP entity must have a clearly expressed and up to date policy (the APP privacy
                  policy) about the management of personal information by the entity.
                </li>
                <li>
                  Without limiting sub-clause 1.3, the APP privacy policy of the APP entity must
                  contain the following information:
                  <ol className={css.alphabetListing}>
                    <li>
                      the kinds of personal information that the entity collects and holds;
                    </li>
                    <li>
                      how the entity collects and holds personal information;
                    </li>
                    <li>
                      the purposes for which the entity collects, holds, uses and discloses personal
                      information;
                    </li>
                    <li>
                      how an individual may access personal information about the individual that is
                      held by the entity and seek the correction of such information;
                    </li>
                    <li>
                      how an individual may complain about a breach of the Australian Privacy
                      Principles, or a registered APP code (if any) that binds the entity, and how
                      the entity will deal with such a complaint;
                    </li>
                    <li>
                      whether the entity is likely to disclose personal information to overseas
                      recipients;
                    </li>
                    <li>
                      if the entity is likely to disclose personal information to overseas
                      recipients—the countries in which such recipients are likely to be located if
                      it is practicable to specify those countries in the policy.
                    </li>
                  </ol>
                </li>
                <p>
                  <b>Availability of APP privacy policy etc.</b>
                </p>
                <li>
                  <p>
                    An APP entity must take such steps as are reasonable in the circumstances to
                    make its APP privacy policy available:
                    <ol className={css.alphabetListing}>
                      <li>free of charge; and</li>
                      <li>in such form as is appropriate.</li>
                    </ol>
                  </p>
                </li>
                <p>
                  <b>Note: </b> An APP entity will usually make its APP privacy policy available on
                  theentity’s website.
                </p>
                <li>
                  If a person or body requests a copy of the APP privacy policy of an APP entity in
                  a particular form, the entity must take such steps as are reasonable in the
                  circumstances to give the person or body a copy in that form.
                </li>
              </ol>
            </li>
          </ol>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            2. Australian Privacy Principle 2 – Anonymity and Pseudonymity
          </h2>
        </div>
        <div>
          <ol style={{
            marginLeft: '20px',
          }}>
            <li>
              Individuals must have the option of not identifying themselves, or of using a
              pseudonym, when dealing with an APP entity in relation to a particular matter.
            </li>
            <li>
              Sub-clause 2.1 does not apply if, in relation to that matter:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity is required or authorised by or under an Australian law, or a
                  court/ tribunal order, to deal with individuals who have identified themselves; or
                </li>
                <li>
                  it is impracticable for the APP entity to deal with individuals who have not
                  identified themselves or who have used a pseudonym.
                </li>
              </ol>
            </li>
          </ol>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>Part 2 – Collection of Personal Information</h2>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            3. Australian Privacy Principle 3 – Collection of Solicited Personal Information
          </h2>
        </div>
        <div>
          <ol
            className={css.romanListing}
            style={{
              marginLeft: '20px',
            }}>
            <p>
              <b>Personal information other than sensitive information</b>
            </p>
            <li>
              If an APP entity is an agency, the entity must not collect personal information (other
              than sensitive information) unless the information is reasonably necessary for, or
              directly related to, one or more of the entity’s functions or activities.
            </li>
            <li>
              If an APP entity is an organisation, the entity must not collect personal information
              (other than sensitive information) unless the information is reasonably necessary for
              one or more of the entity’s functions or activities.
            </li>
            <br />
            <p><b>Sensitive information</b></p>
            <li>
              An APP entity must not collect sensitive information about an individual unless:
              <ol className={css.alphabetListing}>
                <li>
                  the individual consents to the collection of the information and:
                  <ol className={css.romanListing}>
                    <li>
                      if the entity is an agency—the information is reasonably necessary for, or
                      directly related to, one or more of the entity’s functions or activities; or
                    </li>
                    <li>
                      if the entity is an organisation—the information is reasonably necessary for
                      one or more of the entity’s functions or activities; or
                    </li>
                  </ol>
                </li>
                <li>
                  sub-clause 3.4 applies in relation to the information.
                </li>
              </ol>
            </li>
            <li>
              This sub-clause applies in relation to sensitive information about an individual if:
              <ol className={css.alphabetListing}>
                <li>
                  the collection of the information is required or authorised by or under an
                  Australian law or a court/tribunal order; or
                </li>
                <li>
                  a permitted general situation exists in relation to the collection of the
                  information bythe APP entity; or
                </li>
                <li>
                  the APP entity is an organisation and a permitted health situation exists in
                  relationto the collection of the information by the entity; or
                </li>
                <li>
                  the APP entity is an enforcement body and the entity reasonably believes that:
                  <ol className={css.romanListing}>
                    <li>
                      if the entity is the Immigration Department—the collection of the information
                      is reasonably necessary for, or directly related to, one or more enforcement
                      related activities conducted by, or on behalf of, the entity; or
                    </li>
                    <li>
                      otherwise—the collection of the information is reasonably necessary for, or
                      directly related to, one or more of the entity’s functions or activities; or
                    </li>
                  </ol>
                </li>
                <li>
                  the APP entity is a non-profit organisation and both of the following apply:
                  <ol className={css.romanListing}>
                    <li>
                      the information relates to the activities of the organisation;
                    </li>
                    <li>
                      the information relates solely to the members of the organisation, or to
                      individuals who have regular contact with the organisation in connection with
                      its activities. Note: For permitted general situation, see section 16A. For
                      permitted health situation, see section 16B.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <p>
              <b>
                Means of collection
              </b>
            </p>
            <li>
              An APP entity must collect personal information only by lawful and fair means.
            </li>
            <li>
              An APP entity must collect personal information about an individual only from the
              individual unless:
              <ol className={css.alphabetListing}>
                <li>
                  if the entity is an agency:
                  <ol className={css.romanListing}>
                    <li>
                      the individual consents to the collection of the information from someone
                      other than the individual; or
                    </li>
                    <li>
                      the entity is required or authorised by or under an Australian law, or a
                      court/tribunal order, to collect the information from someone other than the
                      individual; or
                    </li>
                  </ol>
                </li>
                <li>
                  it is unreasonable or impracticable to do so.
                </li>
              </ol>
            </li>
            <p>
              <b>
                Solicited personal information
              </b>
            </p>
            <li>
              This principle applies to the collection of personal information that is solicited by
              an APP entity.
            </li>
          </ol>
        </div>


        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            4. Australian Privacy Principle 4 – Dealing with Unsolicited Personal Information
          </h2>
        </div>
        <div className={css.padLeft}>
          <ol>
            <li>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  an APP entity receives personal information; and
                </li>
                <li>
                  the entity did not solicit the information;
                  <br />
                  the entity must, within a reasonable period after receiving the information,
                  determine whether or not the entity could have collected the information under
                  Australian Privacy Principle 3 if the entity had solicited the information.
                </li>
              </ol>
            </li>
            <li>
              The APP entity may use or disclose the personal information for the purposes of making
              the determination under sub-clause 4.1.
            </li>
            <li>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity determines that the entity could not have collected the personal
                  information; and the information is not contained in a Commonwealth record; the
                  entity must, as soon as practicable but only if it is lawful and reasonable to do
                  so, destroy the information or ensure that the information is de-identified.
                </li>
              </ol>
            </li>
            <li>
              If sub-clause 4.3 does not apply in relation to the personal information, Australian
              Privacy Principles 5 to 13 apply in relation to the information as if the entity had
              collected the information under Australian Privacy Principle 3.
            </li>
          </ol>
        </div>


        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            5. Australian Privacy Principle 5 – Notification of the Collection of Personal
            Information
          </h2>
        </div>
        <div>
          <ul>
            <li>
              At or before the time or, if that is not practicable, as soon as practicable after, an
              APP entity collects personal information about an individual, the entity must take
              such
              steps (if any) as are reasonable in the circumstances:
              <ol className={css.alphabetListing}>
                <li>
                  to notify the individual of such matters referred to in sub-clause 5.2 as are
                  reasonable in the circumstances; or
                </li>
                <li>
                  to otherwise ensure that the individual is aware of any such matters.
                </li>
              </ol>
            </li>
            <li>
              The matters for the purposes of sub-clause 5.1 are as follows:
              <ol className={css.alphabetListing}>
                <li>
                  the identity and contact details of the APP entity;
                </li>
                <li>
                  if:
                  <ol className={css.romanListing}>
                    <li>
                      the APP entity collects the personal information from someone other than the
                      individual; or
                    </li>
                    <li>
                      the individual may not be aware that the APP entity has collected the personal
                      information;
                      <br />
                      the fact that the entity so collects, or has collected, the information and
                      the
                      circumstances of that collection;
                    </li>
                  </ol>
                </li>
                <li>
                  if the collection of the personal information is required or authorised by or
                  under
                  an Australian law or a court/tribunal order— the fact that the collection is so
                  required or authorised (including the name of the Australian law, or details of
                  the
                  court/ tribunal order, that requires or authorises the collection);
                </li>
                <li>
                  the purposes for which the APP entity collects the personal information;
                </li>
                <li>
                  the main consequences (if any) for the individual if all or some of the personal
                  information is not collected by the APP entity;
                </li>
                <li>
                  any other APP entity, body or person, or the types of any other APP entities,
                  bodies
                  or persons, to which the APP entity usually discloses personal information of the
                  kind collected by the entity;
                </li>
                <li>
                  that the APP privacy policy of the APP entity contains information about how the
                  individual may access the personal information about the individual that is held
                  by
                  the entity and seek the correction of such information;
                </li>
                <li>
                  that the APP privacy policy of the APP entity contains information about how the
                  individual may complain about a breach of the Australian Privacy Principles, or a
                  registered APP code (if any) that binds the entity, and how the entity will deal
                  with such a complaint;
                  <ol className={css.romanListing}>
                    <li>
                      whether the APP entity is likely to disclose the personal information to
                      overseas recipients;
                    </li>
                    <li>
                      if the APP entity is likely to disclose the personal information to overseas
                      recipients— the countries in which such recipients are likely to be located if
                      it is practicable to specify those countries in the notification or to
                      otherwise
                      make the individual aware of them.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ul>
        </div>


        <div>
          <h2>Part 3 – Dealing with Personal Information</h2>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            6. Australian Privacy Principle 6 – Use or Disclosure of Personal Information
          </h2>
        </div>
        <div>
          <h4>Use or disclosure</h4>
          <ol>
            <li style={{
              marginLeft: '20px',
            }}>
              If an APP entity holds personal information about an individual that was collected for
              a particular purpose (the primary purpose), the entity must not use or disclose the
              information for another purpose (the secondary purpose) unless:
              <ol className={css.alphabetListing}>
                <li>
                  the individual has consented to the use or disclosure of the information; or
                </li>
                <li>
                  sub-clause 6.2 or 6.3 applies in relation to the use or disclosure of the
                  information.
                </li>
              </ol>
            </li>
            <p>
              <b>Note: </b>Australian Privacy Principle 8 sets out requirements for the disclosure
              of personal information to a person who is not in Australia or an external Territory.
            </p>
            <li style={{
              marginLeft: '20px',
            }}>
              This sub-clause applies in relation to the use or disclosure of personal information
              about an individual if:
              <ol className={css.alphabetListing}>
                the individual would reasonably expect the APP entity to use or disclose the
                information for the secondary purpose and the secondary purpose is:
                <ol className={css.romanListing}>
                  <li>
                    if the information is sensitive information directly related to the primary
                    purpose; or
                  </li>
                  <li>
                    if the information is not sensitive information related to the primary purpose;
                    or
                  </li>
                  <li>
                    the use or disclosure of the information is required or authorised by or under
                    an
                    Australian law or a court/tribunal order; or
                  </li>
                  <li>
                    a permitted general situation exists in relation to the use or disclosure of the
                    information by the APP entity; or
                  </li>
                  <li>
                    he APP entity is an organisation and a permitted health situation exists in
                    relation
                    to the use or disclosure of the information by the entity; or
                  </li>
                  <li>
                    the APP entity reasonably believes that the use or disclosure of the information
                    is reasonably necessary for one or more enforcement related activities conducted
                    by, or
                    on behalf of, an enforcement body.
                  </li>
                </ol>
              </ol>
            </li>
            <p>
              <b>Note: </b>For permitted general situation, see section 16A. For permitted health
              situation, see section 16B.
            </p>
            <li style={{
              marginLeft: '20px',
            }}>
              This sub-clause applies in relation to the disclosure of personal information about an
              individual by an APP entity that is an agency if:
              <ol className={css.alphabetListing}>
                <li>
                  the agency is not an enforcement body; and
                </li>
                <li>
                  the information is biometric information or biometric templates; and the recipient
                  of the information is an enforcement body; and
                </li>
                <li>
                  the disclosure is conducted in accordance with the guidelines made by the
                  Commissioner for the purposes of this paragraph.
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity is an organisation; and
                </li>
                <li>
                  subsection 16B (2) applied in relation to the collection of the personal
                  information by the entity; the entity must take such steps as are reasonable in
                  the circumstances to ensure that the information is de-identified before the
                  entity discloses it in accordance with sub-clause 6.1 or 6.2.
                </li>
              </ol>
            </li>
            <br />
            <h4>
              Written note of use or disclosure
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If an APP entity uses or discloses personal information in accordance with paragraph
              6.2(e), the entity must make a written note of the use or disclosure.
            </li>
            <br />
            <h4>Related bodies corporate</h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  an APP entity is a body corporate; and
                </li>
                <li>
                  the entity collects personal information from a related body corporate; this
                  principle applies as if the entity’s primary purpose for the collection of the
                  information were the primary purpose for which the related body corporate
                  collected the information.
                </li>
              </ol>
            </li>
            <br />
            <h4>Exceptions</h4>
            <li style={{
              marginLeft: '20px',
            }}>
              This principle does not apply to the use or disclosure by an organisation of:
              <ol className={css.alphabetListing}>
                <li>
                  personal information for the purpose of direct marketing; or
                </li>
                <li>
                  government related identifiers.
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            7. Australian Privacy Principle 7 – Direct Marketing
          </h2>
        </div>
        <div>
          <ol>
            <h4>
              Direct Marketing
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If an organization holds personal information about an individual, the organization
              must not use or disclose the information for the purpose of direct marketing.
            </li>
            <p>
              <b>Note: </b>An act or practice of an agency may be treated as an act or practice of
              an organization, see section 7A.
            </p>
            <br />
            <h4>
              Exceptions – personal information other than sensitive information
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              Despite sub-clause 7.1, an organization may use or disclose personal information
              (other than sensitive information) about an individual for the purpose of direct
              marketing if:
              <ol className={css.alphabetListing}>
                <li>
                  the organization collected the information from the individual; and
                </li>
                <li>
                  the individual would reasonably expect the organization to use or disclose the
                  information for that purpose; and
                </li>
                <li>
                  the organization provides a simple means by which the individual may easily
                  request not to receive direct marketing communications from the organisation; and
                </li>
                <li>
                  the individual has not made such a request to the organisation.
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              Despite sub-clause 7.1, an organisation may use or disclose personal information
              (other than sensitive information) about an individual for the purpose of direct
              marketing if:

              <ol className={css.alphabetListing}>
                <li>
                  the organisation collected the information from:
                  <ol className={css.romanListing}>
                    <li>
                      the individual and the individual would not reasonably expect the organisation
                      to use or disclose the information for that purpose; or
                    </li>
                    <li>
                      someone other than the individual; and
                    </li>
                  </ol>
                </li>
                <li>
                  either:
                  <ol className={css.romanListing}>
                    <li>
                      the individual has consented to the use or disclosure of the information for
                      that purpose; or
                    </li>
                    <li>
                      it is impracticable to obtain that consent; and
                    </li>
                  </ol>
                </li>
                <li>
                  the organisation provides a simple means by which the individual may easily
                  request not to receive direct marketing communications from the organisation; and
                </li>
                <li>
                  in each direct marketing communication with the individual:
                  <ol className={css.romanListing}>
                    <li>
                      the organisation includes a prominent statement that the individual may make
                      such a request; or
                    </li>
                    <li>
                      the organisation otherwise draws the individual’s attention to the fact that
                      the individual may make such a request; and
                    </li>
                  </ol>
                </li>
                <li>
                  the individual has not made such a request to the organisation.
                </li>
              </ol>
            </li>
            <br />
            <h4>
              Exception – sensitive information
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              Despite sub-clause 7.1, an organisation may use or disclose sensitive information
              about an individual for the purpose of direct marketing if the individual has
              consented to the use or disclosure of the information for that purpose.
            </li>
            <br />
            <h4>
              Exception – contracted service providers
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              Despite sub-clause 7.1, an organisation may use or disclose personal information for
              the purpose of direct marketing if:
              <ol className={css.alphabetListing}>
                <li>
                  the organisation is a contracted service provider for a Commonwealth contract;
                  and the organisation collected the information for the purpose of meeting
                  (directly
                  or indirectly) an obligation under the contract; and
                </li>
                <li>
                  he use or disclosure is necessary to meet (directly or indirectly) such an
                  obligation.
                  <b>Individual may request not to receive direct marketing communications etc.</b>
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              If an organisation (the first organisation) uses or discloses personal information
              about an individual:
              <ol className={css.alphabetListing}>
                <li>
                  for the purpose of direct marketing by the first organisation; or
                </li>
                <li>
                  for the purpose of facilitating direct marketing by other organisations; the
                  individual may:
                </li>
                <li>
                  if paragraph (a) applies—request not to receive direct marketing communications
                  from the first organisation; and
                </li>
                <li>
                  if paragraph (b) applies—request the organisation not to use or disclose the
                  information for the purpose referred to in that paragraph; and
                </li>
                <li>
                  request the first organisation to provide its source of the information.
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              If an individual makes a request under sub-clause 7.6, the first organisation must not
              charge the individual for the making of, or to give effect to, the request and:
              <ol className={css.alphabetListing}>
                <li>
                  if the request is of a kind referred to in paragraph 7.6(c) or (d)—the first
                  organisation must give effect to the request within a reasonable period after the
                  request is made; and
                </li>
                <li>
                  if the request is of a kind referred to in paragraph 7.6(e)—the organisation must,
                  within a reasonable period after the request is made, notify the individual of its
                  source unless it is impracticable or unreasonable to do so.
                </li>
              </ol>
            </li>
            <br />
            <h4>
              Interaction with other legislation
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              This principle does not apply to the extent that any of the following apply:
              <ol className={css.alphabetListing}>
                <li>
                  the Do Not Call Register Act 2006;
                </li>
                <li>
                  the Spam Act 2003;
                </li>
                <li>
                  any other Act of the Commonwealth, or a Norfolk Island enactment, prescribed by
                  the regulations.
                </li>
              </ol>
            </li>

          </ol>
        </div>


        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            8. Australian Privacy Principle 8 – Cross-Border Disclosure of Personal Information
          </h2>
        </div>
        <div>
          <ol>
            <li style={{
              marginLeft: '20px',
            }}>
              Before an APP entity discloses personal information about an individual to a person
              (the overseas recipient):
              <ol className={css.alphabetListing}>
                <li>
                  who is not in Australia or an external Territory; and
                </li>
                <li>
                  who is not the entity or the individual;
                  <br />
                  the entity must take such steps as are reasonable in the circumstances to ensure
                  that the overseas recipient does not breach the Australian Privacy Principles
                  (other than Australian Privacy Principle 1) in relation to the information.
                </li>
              </ol>
            </li>
            <p>
              <b>Note: </b> In certain circumstances, an act done, or a practice engaged in, by the
              overseas recipient is taken, under section 16 C, to have been done, or engaged in, by
              the APP entity and to be a breach of the Australian Privacy Principles.
            </p>


            <li style={{
              marginLeft: '20px',
            }}>
              Sub-clause 8.1 does not apply to the disclosure of personal information about an
              individual by an APP entity to the overseas recipient if:
              <ol className={css.alphabetListing}>
                <li>
                  The entity reasonably believes that:
                  <ol className={css.romanListing}>
                    <li>
                      the recipient of the information is subject to a law, or binding scheme, that
                      has the effect of protecting the information in a way that, overall, is at
                      least substantially similar to the way in which the Australian Privacy
                      Principles protect the information; and
                    </li>
                    <li>
                      there are mechanisms that the individual can access to take action to enforce
                      that protection of the law or binding scheme; or
                    </li>
                  </ol>
                </li>
                <li>
                  Both of the following apply:
                  <ol className={css.romanListing}>
                    <li>
                      The entity expressly informs the individual that if he or she consents to the
                      disclosure of the information, sub-clause 8.1 will not apply to the
                      disclosure;
                    </li>
                    <li>
                      After being so informed, the individual consents to the disclosure; or
                    </li>
                  </ol>
                </li>
                <li>
                  the disclosure of the information is required or authorized by or under an
                  Australian law or a court/tribunal order; or
                </li>
                <li>
                  a permitted general situation (other than the situation referred to in item 4 or 5
                  of the table in subsection 16A (1) exists in relation to the disclosure of the
                  information by the APP entity; or
                </li>
                <li>
                  the entity is an agency and the disclosure of the information is required or
                  authorized by or under an international agreement relating to information sharing
                  to which Australia is a party; or
                </li>
                <li>
                  the entity is an agency and both of the following apply:
                  <ol className={css.romanListing}>
                    <li>
                      the entity reasonably believes that the disclosure of the information is
                      reasonably necessary for one or more enforcement related activities conducted
                      by, or on behalf of, an enforcement body;
                    </li>
                    <li>
                      the recipient is a body that performs functions, or exercises powers, that are
                      similar to those performed or exercised by an enforcement body.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <p>
              <b>Note: </b> For permitted general situation, see section 16A.
            </p>
          </ol>
        </div>


        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            9. Australian Privacy Principle 9 – Adoption, Use or Disclosure of Government Related
            Identifiers
          </h2>
        </div>
        <div>
          <ol>
            <h4>
              Adoption of government related identifiers
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              An organization must not adopt a government related identifier of an individual as its
              own identifier of the individual unless:
              <ol className={css.alphabetListing}>
                <li>
                  the adoption of the government related identifier is required or authorized by or
                  under an Australian law or a court/tribunal order; or
                </li>
                <li>
                  Sub-clause 9.3 applies in relation to the adoption.
                </li>
              </ol>
            </li>
            <p>
              <b>Note: </b> An act or practice of an agency may be treated as an act or practice of
              an organization, see section 7A.
            </p>


            <br />
            <h4>
              Use or disclosure of government related identifiers
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              An organization must not use or disclose a government related identifier of an
              individual unless:
              <ol className={css.alphabetListing}>
                <li>
                  the use or disclosure of the identifier is reasonably necessary for the
                  organization to verify the identity of the individual for the purposes of the
                  organization’s activities or functions; or
                </li>
                <li>
                  the use or disclosure of the identifier is reasonably necessary for the
                  organizationto fulfil its obligations to an agency or a State or Territory
                  authority; or
                </li>
                <li>
                  the use or disclosure of the identifier is required or authorized by or under an
                  Australian law or a court/tribunal order; or
                </li>
                <li>
                  a permitted general situation (other than the situation referred to in item 4 or 5
                  of the table in subsection 16A (1) exists in relation to the use or disclosure of
                  the identifier; or
                </li>
                <li>
                  the organization reasonably believes that the use or disclosure of the identifier
                  is reasonably necessary for one or more enforcement related activities conducted
                  by, or on behalf of, an enforcement body; or
                </li>
                <li>
                  Sub-clause 9.3 applies in relation to the use or disclosure.
                </li>
              </ol>
            </li>
            <p>
              <b>Note 1: </b> An act or practice of an agency may be treated as an act or practice
              of an organization, see section 7A.
            </p>
            <p>
              <b>Note 2: </b> For permitted general situation, see section 16A.
            </p>

            <br />
            <h4>
              <b>Regulations about adoption, use or disclosure</b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              This sub-clause applies in relation to the adoption, use or disclosure by an
              organization of a government related identifier of an individual if:
              <ol className={css.alphabetListing}>
                <li>
                  the identifier is prescribed by the regulations; and
                </li>
                <li>
                  the organization is prescribed by the regulations, or is included in a class of
                  organizations prescribed by the regulations; and
                </li>
                <li>
                  the adoption, use or disclosure occurs in the circumstances prescribed by the
                  regulations.
                </li>
              </ol>
            </li>
            <p>
              <b>Note: </b> There are prerequisites that must be satisfied before the matters
              mentioned in this sub-clause are prescribed, see subsections 100(2) and (3).
            </p>
          </ol>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            Part 4 – Integrity of Personal Information
          </h2>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            10. Australian Privacy Principle 10 – Quality of Personal Information
          </h2>
        </div>
        <div className={css.padLeft}>
          <ol>
            <li>
              An APP entity must take such steps (if any) as are reasonable in the circumstances to
              ensure that the personal information that the entity collects is accurate, up to date
              and complete.
            </li>
            <li>
              An APP entity must take such steps (if any) as are reasonable in the circumstances to
              ensure that the personal information that the entity uses or discloses is, having
              regard
              to the purpose of the use or disclosure, accurate, up to date, complete and relevant.
            </li>
          </ol>
        </div>


        <div>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            11. Australian Privacy Principle 11 – Security of Personal Information
          </h2>
        </div>
        <div>
          <ol style={{
            marginLeft: '20px',
          }}>
            <li>
              If an APP entity holds personal information, the entity must take such steps as are
              reasonable in the circumstances to protect the information:
              <ol className={css.alphabetListing}>
                <li>
                  From misuse, interference and loss; and
                </li>
                <li>
                  From unauthorized access, modification or disclosure.
                </li>
              </ol>
            </li>
            <li>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  an APP entity holds personal information about an individual; and
                </li>
                <li>
                  the entity no longer needs the information for any purpose for which the
                  information may be used or disclosed by the entity under this Schedule; and
                </li>
                <li>
                  the information is not contained in a Commonwealth record; and
                </li>
                <li>
                  the entity is not required by or under an Australian law, or a court/tribunal
                  order, to retain the information;
                </li>
              </ol>
            </li>
            <p>
              The entity must take such steps as are reasonable in the circumstances to destroy the
              information or to ensure that the information is de-identified.
            </p>
          </ol>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            Part 5 – Access To, And Correction Of, Personal Information
          </h2>
          <br />
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            12. Australian Privacy Principle 12 – Access to Personal Information
          </h2>
        </div>
        <div>
          <ol>
            <h4>
              Access
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If an APP entity holds personal information about an individual, the entity must, on
              request by the individual, give the individual access to the information.
            </li>

            <br />
            <h4>
              <b>
                Exception to access – agency
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity is an agency; and
                </li>
                <li>
                  the entity is required or authorized to refuse to give the individual access to
                  the personal information by or under:
                  <ol className={css.romanListing}>
                    <li>
                      the Freedom of Information Act; or
                    </li>
                    <li>
                      any other Act of the Commonwealth, or a Norfolk Island enactment, that
                      provides for access by persons to documents;
                      <br />
                      then, despite sub-clause 12.1, the entity is not required to give access to
                      the extent that the entity is required or authorized to refuse to give access.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Exception to access – organization
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity is an organization then, despite sub-clause 12.1, the entity is not
              required to give the individual access to the personal information to the extent that:
              <ol className={css.alphabetListing}>
                <li>
                  the entity reasonably believes that giving access would pose a serious threat to
                  the life, health or safety of any individual, or to public health or public
                  safety; or
                </li>
                <li>
                  giving access would have an unreasonable impact on the privacy of other
                  individuals; or
                </li>
                <li>
                  the request for access is frivolous or vexatious; or
                </li>
                <li>
                  the information relates to existing or anticipated legal proceedings between the
                  entity and the individual, and would not be accessible by the process of discovery
                  in those proceedings; or
                </li>
                <li>
                  giving access would reveal the intentions of the entity in relation to
                  negotiations with the individual in such a way as to prejudice those negotiations;
                  or
                </li>
                <li>
                  giving access would be unlawful; or
                </li>
                <li>
                  denying access is required or authorized by or under an Australian law or a court/
                  tribunal order; or
                </li>
                <li>
                  both of the following apply:
                  <ol className={css.romanListing}>
                    <li>
                      the entity has reason to suspect that unlawful activity, or misconduct of a
                      serious nature, that relates to the entity’s functions or activities has been,
                      is being or may be engaged in;
                    </li>
                    <li>
                      giving access would be likely to prejudice the taking of appropriate action in
                      relation to the matter; or
                    </li>
                    <li>
                      giving access would be likely to prejudice one or more enforcement related
                      activities conducted by, or on behalf of, an enforcement body; or
                    </li>
                    <li>
                      giving access would reveal evaluative information generated within the entity
                      in connection with a commercially sensitive decision-making process.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>

            <br />
            <h4>
              <b>
                Dealing with requests for access
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              The APP entity must:
              <ol className={css.alphabetListing}>
                <li>
                  Respond to the request for access to the personal information:
                  <ol className={css.romanListing}>
                    <li>
                      if the entity is an agency—within 30 days after the request is made; or
                    </li>
                    <li>
                      if the entity is an organization—within a reasonable period after the request
                      is made; and
                    </li>
                  </ol>
                </li>
                <li>
                  Give access to the information in the manner requested by the individual, if it is
                  reasonable and practicable to do so.
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Other means of access
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity refuses:
              <ol className={css.alphabetListing}>
                <li>
                  to give access to the personal information because of sub-clause 12.2 or 12.3; or
                </li>
                <li>
                  to give access in the manner requested by the individual;
                  <br />
                  The entity must take such steps (if any) as are reasonable in the circumstances to
                  give access in a way that meets the needs of the entity and the individual.
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              Without limiting sub-clause 12.5, access may be given through the use of a mutually
              agreed intermediary.
            </li>

            <br />
            <h4>
              <b>
                Access charges
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity is an agency, the entity must not charge the individual for the
              making of the request or for giving access to the personal information.
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity is an organization; and
                </li>
                <li>
                  the entity charges the individual for giving access to the personal information;
                  <br />
                  The charge must not be excessive and must not apply to the making of the request.
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Refusal to give access
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity refuses to give access to the personal information because of
              sub-clause 12.2 or 12.3, or to give access in the manner requested by the individual,
              the entity must give the individual a written notice that sets out:
              <ol className={css.alphabetListing}>
                <li>
                  the reasons for the refusal except to the extent that, having regard to the
                  grounds for the refusal, it would be unreasonable to do so; and
                </li>
                <li>
                  the mechanisms available to complain about the refusal; and
                </li>
                <li>
                  Any other matter prescribed by the regulations.
                </li>
              </ol>
            </li>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity refuses to give access to the personal information because of
              paragraph 12.3(j), the reasons for the refusal may include an explanation for the
              commercially sensitive decision.
            </li>
          </ol>
        </div>


        <div>
          <h2 style={{
            color: 'rgb(0, 163, 173)',
          }}>
            13. Australian Privacy Principle 13 – Correction of Personal Information
          </h2>
        </div>
        <div>
          <ol>
            <h4>
              <b>
                Correction
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  an APP entity holds personal information about an individual; and
                </li>
                <li>
                  either:
                  <ol className={css.romanListing}>
                    <li>
                      the entity is satisfied that, having regard to a purpose for which the
                      information is held, the information is inaccurate, out of date, incomplete,
                      irrelevant or misleading; or
                    </li>
                    <li>
                      the individual requests the entity to correct the information;
                      <br />
                      the entity must take such steps (if any) as are reasonable in the
                      circumstances to correct that information to ensure that, having regard to the
                      purpose for which it is held, the information is accurate, up to date,
                      complete, relevant and not misleading.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>


            <h4>
              <b>
                Notification of correction to third parties
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity corrects personal information about an individual that the entity
                  previously disclosed to another APP entity; and
                </li>
                <li>
                  the individual requests the entity to notify the other APP entity of the
                  correction;The entity must take such steps (if any) as are reasonable in the
                  circumstances to give that notification unless it is impracticable or unlawful to
                  do so.
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Refusal to correct information
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If the APP entity refuses to correct the personal information as requested by the
              individual, the entity must give the individual a written notice that sets out:
              <ol className={css.alphabetListing}>
                <li>
                  the reasons for the refusal except to the extent that it would be unreasonable to
                  do so; and
                </li>
                <li>
                  the mechanisms available to complain about the refusal; and
                </li>
                <li>
                  Any other matter prescribed by the regulations.
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Request to associate a statement
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              If:
              <ol className={css.alphabetListing}>
                <li>
                  the APP entity refuses to correct the personal information as requested by the
                  individual; and
                </li>
                <li>
                  the individual requests the entity to associate with the information a statement
                  that the information is inaccurate, out of date, incomplete, irrelevant or
                  misleading; the entity must take such steps as are reasonable in the circumstances
                  to associate the statement in such a way that will make the statement apparent to
                  users of the information.
                </li>
              </ol>
            </li>


            <br />
            <h4>
              <b>
                Dealing with requests
              </b>
            </h4>
            <li style={{
              marginLeft: '20px',
            }}>
              Dealing with requests
              <ol className={css.alphabetListing}>
                <li>
                  Must respond to the request:
                  <ol className={css.romanListing}>
                    <li>
                      if the entity is an agency—within 30 days after the request is made; or
                    </li>
                    <li>
                      If the entity is an organization—within a reasonable period after the request
                      is made; and
                    </li>
                  </ol>
                </li>
                <li>
                  Must not charge the individual for the making of the request, for correcting the
                  personal information or for associating the statement with the personal
                  information (as the case may be).
                </li>
              </ol>
            </li>
            <p>
              <b style={{
                fontStyle: 'italic',
              }}>
                The information provided in this fact sheet is of a general nature. It is not a
                substitute for legal advice.
              </b>
            </p>
          </ol>
        </div>

        <br />
        <br />
        <p>
          <span style={{ color: 'rgb(0, 163, 173)', fontWeight: 700 }}>
            Our privacy policy was last updated on 22nd September 2023.{' '}
          </span>
        </p>
      </div>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
