import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, getRouteName } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  ExternalLink,
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
  StripeOnboardingAlert,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import { initiateListACarEventFromTab, pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_ACCOUNTSETTING,
  EVENT_BROWSE_DASHBOARD,
  EVENT_BROWSE_DRIVELAHGO,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
  EVENT_BROWSE_TRUSTANDSAFE,
  EVENT_LISTACAR_CLICKED_LIST_A_CAR,
  EVENT_BROWSE_ABOUTUS,
} from '../../util/gtm/gtmConstants';

import css from './TopbarDesktop.css';
import { createRawPropertiesForGTM } from '../../util/gtm/gtmCreateProperties';
import { createConversionEvents } from '../../util/conversions/conversionsHelpers';
import { EVENT_CONVERSION_STARTED_LISTING } from '../../util/conversions/conversionsConstants';
import HamburgerIcon from '../../assets/hamburger.svg';
import moment from 'moment';
import { stringify } from '../../util/urlHelpers';

const MIN_TABLET_LAYOUT = 768;
const MAX_TABLET_LAYOUT = 1024;
const DOMAIN_URL = process.env.REACT_APP_DOMAIN_URL;
const DOMAIN_HELP_URL = process.env.REACT_APP_DOMAIN_HELP_URL;
const TopbarDesktop = props => {
  const {
    className,
    notApplySearch,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    viewport,
    onLoginOrSignupClick,
    history,
    location,
    setIsSearchedFocused
  } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const isTabletSmall = viewport.width >= MIN_TABLET_LAYOUT && viewport.width < MAX_TABLET_LAYOUT;

  const classes = classNames(rootClassName || css.root, className);

  const pushGTMBrowse = event => {
    pushGTMBrowseEvent({
      props,
      event,
    });
  };
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const noSearchBox = <div className={css.noSearchBox}></div>;

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <div className={css.inboxContainer}>
      <div className={css.inboxLabel}>
        <span className={css.inbox}>
          <FormattedMessage id="TopbarDesktop.inbox" />
          {notificationDot}
        </span>
      </div>
      <div className={css.inboxMenuContent}>
        <MenuItem key="inboxOrders">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="InboxPage"
            params={{ tab: 'orders' }}
            onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.renting" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="inboxSales">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="InboxPage"
            params={{ tab: 'sales' }}
            onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.createListing" />
          </NamedLink>
        </MenuItem>
      </div>
    </div>
  ) : null;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  // const profileMenu = authenticatedOnClientSide ? (
  //   <Menu>
  //     <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
  //       <img src={HamburgerIcon} alt="Menu" className={css.hamburgerIcon} />
  //       <Avatar className={css.avatar} user={currentUser} disableProfileLink />
  //     </MenuLabel>
  //     <MenuContent className={css.profileMenuContent}>
  //       <MenuItem key="InboxPage">
  //         {isTabletSmall && (
  //           <NamedLink
  //             className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
  //             name="InboxPage"
  //             params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
  //             onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
  //           >
  //             <span className={css.menuItemBorder} />
  //             <FormattedMessage id="TopbarDesktop.inbox" />
  //           </NamedLink>
  //         )}
  //       </MenuItem>
  //       <MenuItem key="ManageListingsPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
  //           name="ManageListingsPage"
  //         >
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.yourListingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="ProfileSettingsPage">
  //         <NamedLink
  //           className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
  //           name="ProfileSettingsPage"
  //         >
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="AccountSettingsPage">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
  //           name="AccountSettingsPage"
  //           onClick={() => pushGTMBrowse(EVENT_BROWSE_ACCOUNTSETTING)}
  //         >
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
  //         </NamedLink>
  //       </MenuItem>
  //       {/* <MenuItem key="referralPage">
  //         <NamedLink
  //           className={classNames(css.referralPageLink, currentPageClass('ReferralPage'))}
  //           name="ReferralPage"
  //         >
  //           <span className={css.menuItemBorder}/>
  //           <FormattedMessage id="TopbarDesktop.referralPageLink"/>
  //         </NamedLink>
  //       </MenuItem> */}
  //       <MenuItem key="logout">
  //         <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.logout" />
  //         </InlineTextButton>
  //       </MenuItem>
  //     </MenuContent>
  //   </Menu>
  // ) : (
  //   <Menu>
  //     <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
  //       <img src={HamburgerIcon} alt="Menu" className={css.hamburgerIcon} />
  //       <Avatar className={css.avatar} user={currentUser} disableProfileLink />
  //     </MenuLabel>
  //     <MenuContent className={css.profileMenuLogoutContent}>
  //       <MenuItem key="login">
  //         <NamedLink
  //           className={classNames(css.yourListingsLink, css.LMHowItWork)}
  //           name="LoginPage"
  //           onClick={onLoginOrSignupClick}
  //         >
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.login" />
  //         </NamedLink>
  //       </MenuItem>
  //       <MenuItem key="signup">
  //         <NamedLink
  //           name="SignupPage"
  //           className={classNames(css.yourListingsLink, css.LMHowItWork)}
  //           onClick={onLoginOrSignupClick}
  //         >
  //           <span className={css.menuItemBorder} />
  //           <FormattedMessage id="TopbarDesktop.signup" />
  //         </NamedLink>
  //       </MenuItem>
  //     </MenuContent>
  //   </Menu>
  // );

  const profileMenu = authenticatedOnClientSide ? (
    <div className={css.profileMenuContainer}>
      <div className={css.profileMenuLabel}>
        <img src={HamburgerIcon} alt="Menu" className={css.hamburgerIcon} />
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </div>
      <div className={css.profileMenuContent}>
        <MenuItem key="InboxPage">
          {isTabletSmall && (
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('InboxPage'))}
              name="InboxPage"
              params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
              onClick={() => pushGTMBrowse(EVENT_BROWSE_DASHBOARD)}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.inbox" />
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_ACCOUNTSETTING)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </div>
    </div>
  ) : (
    <div className={css.profileMenuContainer}>
      <div className={css.profileMenuLabel}>
        <img src={HamburgerIcon} alt="Menu" className={css.hamburgerIcon} />
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </div>
      <div className={css.profileMenuContent}>
        <MenuItem key="login">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="LoginPage"
            onClick={onLoginOrSignupClick}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.login" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="signup">
          <NamedLink
            name="SignupPage"
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            onClick={onLoginOrSignupClick}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.signup" />
          </NamedLink>
        </MenuItem>
      </div>
    </div>
  );
  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink} onClick={onLoginOrSignupClick}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink} onClick={onLoginOrSignupClick}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );
  
  
  
  const aboutTab = (
    <div className={css.aboutTab}>
          <div className={`${css.learnMoreLink}`}>
      <FormattedMessage id="TopbarDesktop.aboutTab" />
    </div>
    {/* <Menu> */}
      {/* <MenuLabel className={css.learnMoreLink}>
        <FormattedMessage id="TopbarDesktop.aboutTab" />
      </MenuLabel> */}
      {/* <MenuContent className={css.aboutContent}> */}
      {/* <div className={css.aboutContent}> */}
      <div className={classNames(css.aboutContent, css.now)}>
      
         <MenuItem key="LMHowItWork">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="HowItWorkPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_HOWITWORKS)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.linkHowItWorks" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="LMTrustAndSafety">
          <NamedLink
            className={classNames(css.yourListingsLink, css.LMHowItWork)}
            name="TrustAndSafetyPage"
            onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.insuranceProtection" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="HelpCenter">
          <ExternalLink
            className={classNames(css.yourListingsLink)}
            href={`https://help.${DOMAIN_HELP_URL}/`}
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.helpCenter" />
          </ExternalLink>
        </MenuItem>
        <MenuItem key="BlogPage">
          <ExternalLink className={classNames(css.yourListingsLink, css.LMTrustAndSafety)} href="https://drivemate.au/blog">
             <span className={css.menuItemBorder} />
             <FormattedMessage id="Footer.blog" />
            </ExternalLink>
        </MenuItem>
        <MenuItem key="referralPage">
          {authenticatedOnClientSide && (
            <NamedLink
              className={classNames(css.referralPageLink, currentPageClass('ReferralPage'))}
              name="ReferralPage"
            >
              <span className={css.menuItemBorder}/>
              <FormattedMessage id="TopbarDesktop.referralPageLink"/>
            </NamedLink>
          )}
        </MenuItem>
        <MenuItem key="DriveLahGo">
          <NamedLink
                className={classNames(css.referralPageLink, currentPageClass('ContactUsPage'))}
                name="ContactUsPage"
              >
                <span className={css.menuItemBorder}/>
                <FormattedMessage id="Footer.contactUs"/>
          </NamedLink>
        </MenuItem>
        </div>
      {/* </MenuContent> */}
    {/* </Menu> */}
    </div>
  );
  
  const getSearchParams = () => {
    const today = moment().tz('Australia/Sydney');
    const cutoffTime = today.clone().startOf('day').add(9, 'hours'); 
    
    
    const startDate = today.isAfter(cutoffTime) 
      ? today.clone().add(1, 'day').startOf('day').add(9, 'hours')  
      : today.clone().startOf('day').add(9, 'hours'); 

    const endDate = startDate.clone().add(3, 'days');

    const dateString = `${startDate.format('YYYY-MM-DD')},${endDate.format('YYYY-MM-DD')}`;
    const timeString = '09:00,09:00';

    const getAddressFromCoordinates = (latitude, longitude) => {
      return fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      )
        .then(response => response.json())
        .then(data => {
          if (data.results && data.results.length > 0) {
            return data.results[0].formatted_address;
          }
          throw new Error('No address found');
        });
    };

    const getCurrentLocation = () => {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error('Geolocation is not supported'));
          return;
        }
  
        navigator.geolocation.getCurrentPosition(
          async position => {
            const { latitude, longitude } = position.coords;
            const bounds = {
              ne_lat: latitude - 0.013,
              ne_lng: longitude + 0.013,
              sw_lat: latitude + 0.013,
              sw_lng: longitude - 0.013,
            };
  
            try {
              const address = await getAddressFromCoordinates(latitude, longitude);
              
              resolve({
                bounds,
                origin: `${latitude},${longitude}`,
                address
              });
            } catch (error) {
              resolve({
                bounds,
                origin: `${latitude},${longitude}`
              });
            }
          },
          error => {
            reject(error);
          },
          { timeout: 5000 }
        );
      });
    };

    return getCurrentLocation()
    .then(({ bounds, origin, address }) => {
      return {
        bounds: `${bounds.ne_lat},${bounds.ne_lng},${bounds.sw_lat},${bounds.sw_lng}`,
        origin,
        address, 
        dates: dateString,
        hours: timeString,
        mapSearch: false,
        searchStep: 'preview',
        searchType: 'daily',
        timezone: 'Australia/Sydney'
      };
    })
      .catch(error => {
        console.error('Error getting location:', error);
        return {
          address: 'Sydney, NSW, Australia',  
          bounds: '-37.79226005394196,144.9757155642455,-37.80792281697258,144.9584709976923',
          address:'Carlton VIC 3053, Australia',
          dates: dateString,
          hours: timeString,
          mapSearch: false,
          searchStep: 'preview',
          searchType: 'daily',
          timezone: 'Australia/Melbourne'
        };
      });
  };

  const handleLearnMoreClick = useCallback((e) => {
    e.preventDefault();
    const searchAction = async () => {
      const currentPath = location.pathname;
      if (currentPath === '/') {
        setIsSearchedFocused(true);
      } else {
        history.push(`/`);       
      }
    };
    searchAction();
  }, [location.pathname, history, setIsSearchedFocused]);


  const learnMoreMenu = (
    <NamedLink
      name="SearchPage"
      onClick={handleLearnMoreClick}
      className={classNames(css.createListingLink)}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.rentACar" />
      </span>
    </NamedLink>
  );
  
  const createListingMenu = isAuthenticatedOrJustHydrated ? (
    <NamedLink
      name="NewListingPage"
      className={classNames(css.createListingLink)}
      onClick={() => {
        initiateListACarEventFromTab({
          props,
          eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR]
        });
        const properties = createRawPropertiesForGTM({
          props,
        });
        createConversionEvents(properties, EVENT_CONVERSION_STARTED_LISTING, 'click');
      }}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.hostCar" />
      </span>
    </NamedLink>
  ) : (
    <NamedLink
      name="LoginPage"
      className={classNames(css.createListingLink)}
      onClick={onLoginOrSignupClick}
    >
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.hostCar" />
      </span>
    </NamedLink>
  );
 
  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name={`${getRouteName(history)}`}>
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {!notApplySearch ? search : noSearchBox}
      {learnMoreMenu}
      {createListingMenu}
      {/* <NamedLink
        className={css.createListingLink}
        name="NewListingPage"
        onClick={() => {
          initiateListACarEventFromTab({
            props,
            eventButtonParam: [EVENT_LISTACAR_CLICKED_LIST_A_CAR]
          })
          const properties = createRawPropertiesForGTM({
            props,
          });
          createConversionEvents(properties, EVENT_CONVERSION_STARTED_LISTING, 'click');
        }}
        >
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink> */}
      {!isTabletSmall && inboxLink}
      {aboutTab}
      {/* <img src={HamburgerIcon} alt="Menu" className={css.hamburgerIcon} /> */}
      {profileMenu}
      {/* {signupLink}
      {loginLink} */}
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  history: object.isRequired,
  location: object.isRequired,  
  onLoginOrSignupClick: func.isRequired,
  setIsSearchedFocused: func.isRequired,
};

export default withViewport(TopbarDesktop);