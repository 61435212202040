// For distance charging
export const bookingProcessAliasDistanceCharging = 'distance-charging/dm-2.0.0';
export const TRANSITION_DISTANCE_CHARGING_REQUEST_PAYMENT = 'transition/distance-charging-request-payment';
export const TRANSITION_DISTANCE_CHARGING_CONFIRM_PAYMENT = 'transition/distance-charging-confirm-payment';
export const TRANSITION_DISTANCE_CHARGING_DECLINE = 'transition/distance-charging-decline';
export const LINE_ITEM_DISTANCE_CHARGING_PROVIDER_COMMISSIONS = 'line-item/distance-charging-provider-commissions';
export const LINE_ITEM_DISTANCE_CHARGING_DRIVELAH_COMMISSIONS = 'line-item/distance-charging-drivelah-commissions';
export const TRANSITION_REQUEST_DROP_OFF_DLGO_DISTANCE_CHARGE_FAILED = 'transition/request-drop-off-dlgo-distance-charge-failed';
export const TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU_DISTANCE_CHARGE_FAILED = 'transition/drop-off-confirmed-after-admin-access-for-shu-distance-charge-failed';
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE_DISTANCE_CHARGE_FAILED = 'transition/trip-completed-by-admin-after-accepted-non-refundable-distance-charge-failed';
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE_DISTANCE_CHARGE_FAILED = 'transition/trip-completed-by-admin-after-pick-up-requested-non-refundable-distance-charge-failed';
export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED_DISTANCE_CHARGE_FAILED = 'transition/trip-completed-by-admin-after-pick-up-confirmed-distance-charge-failed';
export const TRANSITION_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED = 'transition/confirm-drop-off-distance-charge-failed';
export const TRANSITION_ADMIN_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED = 'transition/admin-confirm-drop-off-distance-charge-failed';

export const TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/drop-off-confirmed-after-admin-access-for-shu-distance-charge-failed-invoice-paid';

export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/trip-completed-by-admin-after-accepted-non-refundable-distance-charge-failed-invoice-paid';

export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE_CONFIRMED_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/trip-completed-by-admin-after-pick-up-requested-non-refundable-confirmed-distance-charge-failed-invoice-paid';

export const TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/trip-completed-by-admin-after-pick-up-confirmed-distance-charge-failed-invoice-paid';

export const TRANSITION_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/confirm-drop-off-distance-charge-failed-invoice-paid';

export const TRANSITION_ADMIN_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED_INVOICE_PAID =
  'transition/admin-confirm-drop-off-distance-charge-failed-invoice-paid';


// Failed distance transitions combined
export const failedDistanceTransitions = [
  TRANSITION_REQUEST_DROP_OFF_DLGO_DISTANCE_CHARGE_FAILED,
  TRANSITION_DROP_OFF_CONFIRMED_AFTER_ADMIN_ACCESS_FOR_SHU_DISTANCE_CHARGE_FAILED,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_ACCEPTED_NON_REFUNDABLE_DISTANCE_CHARGE_FAILED,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_REQUESTED_NON_REFUNDABLE_DISTANCE_CHARGE_FAILED,
  TRANSITION_TRIP_COMPLETED_BY_ADMIN_AFTER_PICK_UP_CONFIRMED_DISTANCE_CHARGE_FAILED,
  TRANSITION_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED,
  TRANSITION_ADMIN_CONFIRM_DROP_OFF_DISTANCE_CHARGE_FAILED
];

