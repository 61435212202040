import axios from "axios";

const CACHE_EXPIRY_MS = 24 * 60 * 60 * 1000; // 24 hours

// 🔹 In-memory cache for location-based data
const placePredictionCache = new Map();
const placeDetailsCache = new Map();
const timeZoneCache = new Map();

/**
 * ✅ Save to Local Storage with Expiry
 */
export const saveToLocalStorage = (key, value) => {
    const cacheData = { value, expiry: Date.now() + CACHE_EXPIRY_MS };
    localStorage.setItem(key, JSON.stringify(cacheData));
};

/**
 * ✅ Get from Local Storage (Removes Expired Entries)
 */
export const getFromLocalStorage = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) return null;

    const { value, expiry } = JSON.parse(cachedData);
    if (Date.now() > expiry) {
        localStorage.removeItem(key); // Remove expired cache
        return null;
    }
    return value;
};

/**
 * ✅ Fetch Cached Location from Backend (Redis/MySQL)
 */
export const fetchFromBackendCache = async (key, type) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/cache`, { params: { type, key } });
        return response.data || null;
    } catch (error) {
        console.error(`❌ Backend cache fetch error for ${type}:`, error);
        return null;
    }
};

/**
 * ✅ Fetch Cached Location from Backend (Redis/MySQL)
 */
export const fetchAddressFromLatLongBackendCache = async (latitude, longitude, language = 'en') => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/cache/addressDetails`, {
          params: { latitude, longitude, language },
      });

      if (response.status === 200 && response.data && !response.data.error) {
          return response.data;
      }

      console.warn(`⚠️ Backend cache miss for lat: ${latitude}, lng: ${longitude}`);
      return null;
  } catch (error) {
      console.error(`❌ Backend cache fetch error for lat: ${latitude}, lng: ${longitude}`, error);
      return null;
  }
};

/**
 * ✅ Cache Place Details (Google Places API)
 */
export async function getCachedPlaceDetails(placeId) {
    // ✅ Check Backend Cache (Redis/MySQL)
    const backendCache = await fetchFromBackendCache(placeId, "placeDetails");
    if (backendCache) return backendCache;

    return null;
}

/**
 * ✅ Cache TimeZone Data (Google Timezone API)
 */
export async function getCachedTimeZone(location) {
    const key = `${location.lat}-${location.lng}`;

    // ✅ Check Backend Cache (Redis/MySQL)
    const backendCache = await fetchFromBackendCache(key, "timeZone");
    if (backendCache) return backendCache;

    return null;
}

/**
 * ✅ Clears cache manually (for testing or periodic cleanup)
 */
// export function clearLocationCache() {
//     console.log("🧹 Clearing all cached location data...");
//     placePredictionCache.clear();
//     placeDetailsCache.clear();
//     timeZoneCache.clear();
//     localStorage.clear();
// }
