import React, { useEffect } from 'react';
import classNames from 'classnames';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import directionsIcon from '../../assets/tripPage/directions-solid.svg';

import css from './TripPanel.css';

// Functional component as a helper to build AddressLinkMaybe
const AddressLinkMaybe = props => {
  const { className, rootClassName, location, geolocation, showAddress, isProvider, customAddress, needDirection = true, showExactShuLocation, showGPSLocation } = props;

  const { address, building, floorUnit, location: listingLocationObj } = (typeof location === 'object' && location !== null) ? location : { address: location || "" };

  const partialAddress = `${listingLocationObj?.suburb || ''} ${listingLocationObj?.state || ''} ${listingLocationObj?.postalCode || ''}`;

  const listingAddress = `${listingLocationObj?.address || ''}`;

  const { lat, lng } = geolocation || {};
  const hrefToGoogleMaps = geolocation
    ? `https://maps.google.com/?q=${lat},${lng}`
    : address
    ? `https://maps.google.com/?q=${encodeURIComponent(address)}`
    : null;

  const fullAddress =
    typeof building === 'string' && building.length > 0 ? `${building}, ${address}` : address;

  const buildingAndFloorUnit = (building && floorUnit) ? (
    <span>{building} - {floorUnit}</span>
  ) : null

  const classes = classNames(rootClassName || css.address, className);

  const onRefreshLocationClick = () => window.location.reload();

  return (
    <div className={classes}>
      {/* When we do not need to show address */}
      {!showAddress && (
        <p className={css.deliveryText}>
          <FormattedMessage id="TransactionPanel.locationText" />
        </p>
      )}
      {/* When we need to show GPS location and we do not have exact location */}
      {showAddress && showGPSLocation && !hrefToGoogleMaps && (
        <p className={css.deliveryText} onClick={onRefreshLocationClick}>
          <a>Click to fetch the live location of the car</a>
        </p>
      )}

      {/* When we need to show GPS location and we have exact location */}
      {showAddress && showGPSLocation && hrefToGoogleMaps && (
        <div className={css.addressContainer}>
          <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
            href={hrefToGoogleMaps}>
            <span>{address}</span>
          </ExternalLink>

          {needDirection && <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
            <img src={directionsIcon} alt="direction" className={css.mapImageDirection} />
          </ExternalLink>}
        </div>
      )}

      {/* When we do NOT need to show GPS location and we have location */}
      {showAddress && !showGPSLocation && hrefToGoogleMaps && (
        <div className={css.addressContainer}>
          {showExactShuLocation ? (
            <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
            href={hrefToGoogleMaps}>
            <span>{listingAddress}</span>
          </ExternalLink>
          ) : (
            <span>{partialAddress}</span>
          )}
        </div>
      )}
    </div>
  )

  return showAddress && hrefToGoogleMaps ? (
    <div className={classes}>
      {/* {isProvider ? (
        <p className={css.deliveryText}>
          <FormattedMessage id="TransactionPanel.deliveryText" />
        </p>
      ) : null} */}
      {showExactShuLocation ?
        (
        <div className={css.addressContainer}>

          {/* {customAddress ? customAddress : ( */}
            <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
                 href={hrefToGoogleMaps}>
              <span>{address}</span>
            </ExternalLink>
          {/* )} */}

          {needDirection && <ExternalLink className={css.mapImgContainer} href={hrefToGoogleMaps}>
            <img src={directionsIcon} alt="direction" className={css.mapImageDirection}/>
          </ExternalLink>}
        </div>

      ) :
      <div className={css.addressContainer}>

          {/* {customAddress ? customAddress : ( */}
            <ExternalLink className={classNames(css.linkAddressColor, css.mapLocationLink)}
                 href={hrefToGoogleMaps}>
              <span>{partialAddress}</span>
            </ExternalLink>
          {/* )} */}

          {/* {needDirection &&
          <div className={css.mapImgContainer}>
          <img
            src={directionsIcon}
            alt="direction"
            className={`${css.mapImageDirection} ${css.disabled} ${css.cursorDisabled}`}
          />
        </div>

          } */}
        </div>
      }

    </div>
  ) : (
    <div className={classes}>
      <p className={css.deliveryText}>
        <FormattedMessage id="TransactionPanel.locationText" />
      </p>
    </div>
  );
};

export default AddressLinkMaybe;
