import React, { useState, useRef } from 'react';
import SingleMonthCalender from '../../components/SingleMonthCalender/SingleMonthCalender';
import moment from 'moment-timezone';
import { isSameDay, isWithinInterval, startOfDay } from 'date-fns';
import { get } from 'lodash';
import { isDayMomentInsideRange } from '../../util/dates';
import { utcToZonedTime } from 'date-fns-tz';

// Constants for calculating day width (aka table cell dimensions)
const TABLE_BORDER = 2;
const TABLE_COLUMNS = 7;
const MIN_CONTENT_WIDTH = 272;
const MIN_CELL_WIDTH = Math.floor(MIN_CONTENT_WIDTH / TABLE_COLUMNS); // 38
const MAX_CONTENT_WIDTH_DESKTOP = 603;
const MAX_CELL_WIDTH_DESKTOP = Math.floor(MAX_CONTENT_WIDTH_DESKTOP / TABLE_COLUMNS); // 108
const VIEWPORT_LARGE = 1024;

const dayWidth = (wrapperWidth, windowWith) => {
  if (windowWith >= VIEWPORT_LARGE) {
    // NOTE: viewportLarge has a layout with sidebar.
    // In that layout 30% is reserved for paddings and 282 px goes to sidebar and gutter.
    const width = windowWith * 0.7 - 282;
    return width > MAX_CONTENT_WIDTH_DESKTOP
      ? MAX_CELL_WIDTH_DESKTOP
      : Math.floor((width - TABLE_BORDER) / TABLE_COLUMNS);
  } else {
    // return wrapperWidth > MIN_CONTENT_WIDTH
    //   ? Math.floor((wrapperWidth - TABLE_BORDER) / TABLE_COLUMNS)
    //   : MIN_CELL_WIDTH;
    return (window.innerWidth - 60) / 7;
  }
};
const TripExtensionDateSelector = ({ onTripExtensionDateChange, selectedTripEndDate, validTimeSlots, latestAvailableDateTime, transaction, timeZone }) => {
  const extensionCalendarRef = useRef();
  const { clientWidth: width } = extensionCalendarRef || { clientWidth: 0 };
  const hasWindow = typeof window !== 'undefined';
  const windowWidth = hasWindow ? window.innerWidth : 0;
  const daySize = dayWidth(width, windowWidth);

  const bookingEnd = get(transaction, 'booking.attributes.displayEnd');
  const bookingStart = get(transaction, 'booking.attributes.displayStart');

  const extensionCalendarProps = {
    minimumNights: 1,
    numberOfMonths: 12,
    maxDate: moment()
      .add(360, 'days')
      .toDate(),
  };

  console.log('validTimeSlots in this', validTimeSlots)

  const isDayBlocked = (day) => {
    const timestamp = day.valueOf();
    const dayTimestamp = startOfDay(new Date(timestamp));

    if (!validTimeSlots || validTimeSlots.length === 0) return false;

    const is1130PM = (date) =>
      date.getHours() === 23 && date.getMinutes() === 30;

    if (
      latestAvailableDateTime &&
      isWithinInterval(dayTimestamp, {
        start: bookingEnd,
        end: latestAvailableDateTime,
      })
    ) {
      return false;
    }
    // Unblock bookingEnd date if:
    // 1. latestAvailableDateTime is on a different day
    // 2. bookingEnd time is not 11:30 PM
    if (
      isSameDay(dayTimestamp, bookingEnd) &&
      latestAvailableDateTime &&
      !is1130PM(bookingEnd)
    ) {
      return false;
    }

    return true;
  }


  return (
    <div>
      <SingleMonthCalender
        selectedDates={selectedTripEndDate}
        isPickupDate={false}
        onDatesChange={onTripExtensionDateChange}
        ref={extensionCalendarRef}
        daySize={daySize}
        isDayBlocked={isDayBlocked}
        maxDate={moment().add(6, 'months')}
        {...extensionCalendarProps}
      />
    </div>
  );
};

export default TripExtensionDateSelector;
